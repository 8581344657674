import axios from "axios";
import React, { Component } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Col,
  Form,
  Row,
  InputGroup,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ContractModal from "./ContractModal";
import swal from "sweetalert";
import { toast } from "react-toastify";
import DataTables from "../datatable/DataTables";
import * as XLSX from "xlsx-js-style";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from "react-select";
import RegisterBlockFormModal from "../registerBlock/RegisterBlockFormModal";
import html2pdf from "html2pdf.js";

class Contract extends Component {
  constructor(props) {
    super(props);
    this.updateItem = this.updateItem.bind(this);
    this.state = {
      listclass: [],
      showDetailContract: false,
      detailContractItem: {},
      listuser: [],
      _class: [],
      show: false,
      type: "",
      alertShow: false,
      selectedRows: [],
      listclassID: [],
      showDetail: false,
      countclassid: [],
      listclasssearch: [],
      checkload: true,
      listEthnic: [],
      listcontract: [],
      showRes: false,
      itemShedule: {},
      contractitem: {
        id: 0,
        contractCode: "",
        createdDate: new Date() / 1000,
        effectiveDate: new Date() / 1000,
        expirationDate: new Date() / 1000,
        companyId: 1,
        percentDiscount: 0,
        contractTypeId: 0,
        contractValue: 0,
        paymentType: 1,
        note: "",
        customer: {
          id: 0,
          shortName: "",
          name: "",
          address: "",
          phoneNumber: "",
          faxNumber: "",
          taxCode: "",
          customerTypeId: null,
        },
        bankCustomer: {
          id: 0,
          bankName: "",
          bankNumber: "",
          cardOwner: "",
          address: "",
          customerId: 1,
          note: "",
        },
        bankCompany: {},
        representativeCustomer: {
          id: 0,
          name: "",
          role: "",
          phoneNumber: "",
          email: "",
          customerId: 0,
        },
        representativeCompany: {
          id: 0,
          name: "",
          role: "",
          phoneNumber: "",
          email: "",
          companyId: 1,
        },
      },
      listCustomer: [],
      CustomerItem: { value: 0, label: "Chọn khách hàng" },
      listContractType: [],
      ContractTypeItem: { value: 0, label: "Chọn loại hợp đồng" },
      startDate: "",
      endDate: "",
      listRevenue: [],
      dataExport: [],
      selectedRows: [],
      certificationMonth: null,
      broadcastCertification: null,
      listDay: [],
      listDate: [],
      blockListForCertification: [],
      monthCheck: 0,
      listConttractAd: [],
      showMenu: false,
    };
  }

  async componentDidMount() {
    document.title = "Quản lý hợp đồng";

    this.setState(
      {
        CustomerItem: { value: 0, label: "Chọn khách hàng" },
        ContractTypeItem: { value: 0, label: "Chọn loại hợp đồng" },
        checkload: true,
        startDate: this.changeStartMonth(new Date()),
        endDate: this.changeEndMonth(new Date()),
        selectedRows: [],
        dataExport: [],
      },
      () => {
        this.getlistclass();
        this.getAllContarctType();
        this.getAllCustomer();
        this.getRevenue();
        this.hideCellBasedOnButtonState();
      }
    );
  }
  hideCellBasedOnButtonState() {
    const rows = document.querySelectorAll('[role="row"]');

    rows.forEach((row) => {
      const button = row.querySelector("button");
      const cell = row.querySelector('[role="cell"][data-column-id="1"]');

      if (button && button.hasAttribute("disabled")) {
        cell.classList.add("hidden-cell");
      } else {
        cell.classList.remove("hidden-cell");
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this.setState(
        {
          CustomerItem: { value: 0, label: "Chọn khách hàng" },
          checkload: true,
          startDate: this.changeStartMonth(new Date()),
          endDate: this.changeEndMonth(new Date()),
          selectedRows: [],
          dataExport: [],
        },
        () => {
          this.getlistclass();
          this.getRevenue();
          this.hideCellBasedOnButtonState();
        }
      );
    }
  }
  mergeBroadcastDate = (input) => {
    let outputArray = [];
    if (input) {
      // console.log(input)
      // console.log(input?.forEach)

      input?.forEach((item) => {
        let itemFind = outputArray?.find(
          (e) =>
            e.adPriceId == item.adPriceId &&
            e.content?.trim() == item.content?.trim()
        );
        if (itemFind) {
          outputArray = outputArray?.map((out) => {
            if (
              out.adPriceId == itemFind.adPriceId &&
              out.content?.trim() == itemFind.content?.trim()
            )
              return {
                ...out,
                broadcastDateList: [
                  ...item.broadcastDateList,
                  ...itemFind.broadcastDateList,
                ],
              };
            else return out;
          });
        } else {
          outputArray?.push(item);
        }
      });
    }
    return outputArray;
  };

  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };

  formatNumber(num) {
    if (num != null && num != "" && num != undefined)
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    else return 0;
  }
  getDetailContractbyId = (item) => {
    if (item?.id !== undefined)
      axios
        .get(`contract/${item?.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({ dataExport: res.data.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
  getRes = (item) => {
    axios
      .get(
        `/schedule/by-contract/${item.id}?month=${parseInt(new Date() / 1000)}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            itemShedule: res.data.data,
            showRes: true,
            // dataExport: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  sumValue = () => {
    let sum = 0;
    this.state.listRevenue?.map((item) => {
      return (sum += item.totalPrice);
    });
    return sum;
  };
  getAllCustomer = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn khách hàng" });
    axios
      .get(`/customer?page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listCustomer: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContarctType = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn loại hợp đồng" });
    axios
      .get(`/contract-type?page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listContractType: arr?.filter((e) => e.value != 1),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };
  getRevenue = () => {
    var start =
      this.state.startDate == null ||
      this.state.startDate == "" ||
      this.state.startDate == undefined
        ? ""
        : parseInt(this.state.startDate / 1000);
    var end =
      this.state.endDate == null ||
      this.state.endDate == "" ||
      this.state.endDate == undefined
        ? ""
        : parseInt(new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000);

    var contractView = this.props.page == "notification" ? 1 : 2;

    axios
      .get(
        `/contract/revenue-book/?startDay=${start}&endDay=${end}&contractView=${contractView}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            listRevenue: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getlistclass = () => {
    var start =
      this.state.startDate == null ||
      this.state.startDate == "" ||
      this.state.startDate == undefined
        ? ""
        : parseInt(this.state.startDate / 1000);
    var end =
      this.state.endDate == null ||
      this.state.endDate == "" ||
      this.state.endDate == undefined
        ? ""
        : parseInt(new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000);

    var contractView = this.props.page == "notification" ? 1 : 2;
    this.setState({ checkload: true });
    axios
      .get(
        `/contract/?effectiveDate=${start}&expirationDate=${end}&customerId=${this.state.CustomerItem?.value}&contractView=${contractView}&contractTypeId=${this.state.ContractTypeItem?.value}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ checkload: false });
          if (this.state.detailContractItem?.id != undefined)
            this.setState({
              detailContractItem: res.data.data?.filter(
                (e) => e.id == this.state.detailContractItem?.id
              )[0],
            });
          this.setState({
            listcontract: res.data.data?.sort((a, b) => {
              return a.contractTypeId - b.contractTypeId;
            }),
          });
          let arr = res.data.data?.sort((a, b) => {
            return a.contractTypeId - b.contractTypeId;
          });
          let arrType = this.removeDuplicates(
            res.data.data?.map((e) => e.contractType),
            "id"
          );
          if (
            res.data.data?.filter((item) => item.contractTypeId == null)
              ?.length > 0
          )
            arrType.push({ id: null, name: null });
          //  console.log()
          this.setState({
            listConttractAd: this.sortContractList(arrType, res.data.data),
          });
          //  here
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  sortContractList(contractTypeList, contractList) {
    const contractTypeMap = new Map();
    contractTypeList.forEach((contractType, index) => {
      contractTypeMap.set(contractType.id, { index, data: contractType });
    });

    const sortedList = [];

    contractTypeList.forEach((contractType) => {
      const contractTypeData =
        contractTypeMap.get(contractType.id)?.data ?? null;

      if (contractTypeData) {
        sortedList.push({
          id: contractTypeData.id,
          name: contractTypeData.name,
          contractTypeId: null,
        });

        const contractsOfType = contractList.filter(
          (item) => item.contractTypeId === contractTypeData.id
        );
        contractsOfType.forEach((contract) => {
          sortedList.push(contract);
        });
      } else {
        sortedList.push({
          id: contractType.id,
          name: null,
          contractTypeId: null,
        });
      }
    });

    return sortedList;
  }
  removeDuplicates(array, property) {
    return array?.filter(
      (value, index, self) =>
        index ==
        self.findIndex((obj) =>
          obj && value ? obj[property] == value[property] : null
        )
    );
  }
  deleteclass = (item, event) => {
    swal({
      title: "Xác nhận xóa hợp đồng",
      text: `${item.contractCode}`,
      icon: "error",
      buttons: true,
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/contract/${item.id}/delete`)
          .then((res) => {
          
            if (res.data.status == 200) {
              this.getlistclass();
              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast(`${res.data.errorMessage}`, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast(`Hệ thống xảy ra lỗi`, {
              type: "error",
              autoClose: 1000,
            });
          });
      }
    });
  };

  formclose = () => {
    this.setState({ show: false });
  };
  submit = () => {
    this.setState({ show: false, selectedRows: [] });
    this.getlistclass();
  };

  dataTable() {
    const columns = [
      {
        // name: "Loại HĐ",
        selector: (row) => row.contractType,
        // right: true,
        // sortable: true,
        cell: (row) => (
          <div style={{ width: "50px" }} className="text-nowrap ">
            {/* {row.contractType=='Loại HĐ: Chưa phân loại'? '': row.contractType} */}
            {row.contractCode ? "" : row.contractType}
          </div>
        ),
        style: (row) => ({
          padding: "0 5px",
          width: "10px",
          marginLeft: "-100px",
          marginRight: "100px",
          zIndex: 0,
        }),
      },
      {
        name: "Số HĐ",
        selector: (row) => row.contractCode,
        // sortable: true,
        sortable: this.props.page == "notification",

        cell: (row) => (
          <div
            // style={{ width: "220px" }}
            className="text-wrap"
          >
            {row.contractCode}
          </div>
        ),
        width: "130px",
        style: (row) => ({
          padding: "0 5px",
          marginLeft: this.props.page != "notification" ? "-14%" : 0,
        }),
      },
      {
        name: "Khách hàng",
        selector: (row) => row.customername,
        cell: (row) => (
          <div
            // style={{ width: "100px" }}
            className="text-wrap"
          >
            {row.customername}
          </div>
        ),
        minWidth: "240px",
        style: (row) => ({
          padding: "0 5px",
          // , marginLeft:'-20px', marginRight:'20px'
        }),
      },

      {
        name: "Từ ngày",
        selector: (row) => row.effectiveDate,
        sortable: this.props.page == "notification",
        right: true,
        cell: (row) => (
          <div
            style={{ width: "fit-content%", margin: "0 0px 0 0" }}
            className="text-wrap text-end"
          >
            {this.getDate(row.effectiveDate)}
          </div>
        ),
        maxWidth: "150px",
        style: (row) => ({
          padding: "0 5px",
          marginLeft: this.props.page != "notification" ? "1.5%" : 0,
        }),
      },
      {
        name: "Đến ngày",
        selector: (row) => row.expirationDate,
        right: true,
        sortable: this.props.page == "notification",
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap text-end">
            {this.getDate(row.expirationDate)}
          </div>
        ),
        style: (row) => ({ padding: "0 5px" }),
      },
      {
        name: "Giá trị",
        selector: (row) => row.contractValue,
        // sortable: true,
        right: true,
        // center: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap text-end">
            {row.contractValue
              ? this.formatNumber(parseInt(row.contractValue))
              : ""}
          </div>
        ),
        style: (row) => ({ padding: "0 5px" }),
      },

      {
        name: "Giá trị QC",
        selector: (row) => row.QCValue,
        // sortable: true,
        right: true,

        // center: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap text-end">
            {row.QCValue ? this.formatNumber(parseInt(row.QCValue)) : ""}
          </div>
        ),
        style: (row) => ({ padding: "0 5px" }),
      },
      {
        name: "Còn lại",
        selector: (row) =>
          row.contractValue == undefined || row.QCValue == undefined
            ? ""
            : row.contractValue - row.QCValue,
        // sortable: true,
        right: true,
        style: (row) => ({ padding: "0 5px" }),

        // center: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap text-end">
            {row.contractCode || !row.contractType
              ? this.formatNumber(parseInt(row.contractValue - row.QCValue))
              : ""}
          </div>
        ),
      },
    ];

    const data = [];
    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };
    let arrRender =
      this.props.page == "notification"
        ? this.state.listcontract
        : this.state.listConttractAd;

    if (arrRender.length > 0) {
      arrRender.map((item, i) => {
        var sumValue = 0;
        item?.scheduleList?.map((e) => (sumValue += e.revenues));

        data.push({
          id: item.id,
          contractType: item.name
            ? "Loại HĐ: " + item.name
            : "Loại HĐ: Chưa phân loại",
          contractCode: item.contractCode || undefined,
          customername: item.customer?.name || undefined,
          note: item.note,
          contractValue: item.contractValue
            ? item.contractValue * (1 - Number(item.percentDiscount) / 100)
            : "",
          expirationDate: item.expirationDate,
          effectiveDate: item.effectiveDate,
          QCValue: item.contractCode ? sumValue : 0,
          scheduleList: item.scheduleList,
          item: item,
          setting: (
            <>
              <Button
                variant="primary"
                title="Xem 
                "
                size="sm"
                onClick={() => {
                  this.getDetailContractbyId(item);
                }}
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>{" "}
              <Button
                variant="warning"
                title="Cập nhật"
                size="sm"
                onClick={() => {
                  this.setState({
                    type: "CN",
                    show: true,
                  });

                  this.setState({
                    contractitem: item,

                    _class: {
                      id: item.id,
                      id_group: item.id_group,
                      id_user: item.id_user,
                      class_name: item.class_name,
                    },
                  });
                }}
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>{" "}
              <Button
                variant="danger"
                title="Xóa"
                size="sm"
                onClick={() => {
                  this.deleteclass(item);
                }}
                // disabled
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });

        return item;
      });
    }
    const totalQCValue = data.reduce(
      (total, record) => total + (record.QCValue ? record.QCValue : 0),
      0
    );
    const totalValue = data.reduce(
      (total, record) =>
        total + (record.contractValue ? record.contractValue : 0),
      0
    );
    // here
    const totalRow = {
      id: "total-student",
      // contractType:'Loại HĐ: Chưa phân loại',
      customername: "Tổng",
      // effectiveDate: "",
      contractValue: totalValue || undefined,
      QCValue: totalQCValue || undefined,
    };

    const temporaryData = [...data, totalRow];
    const handleSelectedRowsChange = ({ selectedRows }) => {
      const isLastRow =
        this.state.selectedRow?.length > 0 &&
        this.state.selectedRow[0]?.index == temporaryData.length - 1;
      if (isLastRow) {
        return;
      }
      this.getDetailContractbyId(selectedRows[0]);

      this.setState({ selectedRows: selectedRows });
    };
    const conditionalRowStyles = [
      {
        when: (row) =>
          this.state.selectedRows?.some(
            (selectedRow) =>
              selectedRow.id === row.id && selectedRow.id != "total-student"
          ),
        style: {
          backgroundColor: "rgba(0, 123, 255, 0.2)",
        },
      },
    ];
    const selectableRowDisabled = (row) => {
      // Ngăn chặn chọn dòng cuối cùng

      return row.id == "total-student" || row.contractCode == undefined;
    };
    const ExpandedComponent = (listday) => {
      const columns = [
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "Tháng",
          selector: (row) => row.month,
        },
        {
          name: "Giá trị",
          selector: (row) => row.value,
          cell: (row) => (
            <div style={{ width: "100%" }} className="text-wrap text-end">
              {this.formatNumber(parseInt(row.value))}
            </div>
          ),
          right: "true",
        },
        this.props.page == "notification"
          ? {
              name: "Xuất",
              selector: (row) => row.export,
            }
          : {},
      ];
      const data = [];
      listday?.listday?.scheduleList?.map((item, index) => {
        data.push({
          id: index,
          month: item.month,
          value: item.revenues,
          export: (
            <>
              <Form.Check
                // type="switch"
                id="custom-switch-cash1"
                style={{ cursor: "pointer" }}
                // className="ms-2 "
                value={index}
                checked={
                  this.state.certificationMonth?.index === index &&
                  this.state.certificationMonth?.contractId ===
                    (listday?.listday?.id || "")
                }
                onChange={async () => {
                  this.setState({
                    certificationMonth: {
                      index,
                      contractId: listday?.listday?.id,
                      ...listday?.listday?.scheduleList[index],
                    },
                    monthCheck: item.month,
                  });
                }}
              />
              {/* <Button variant="success" size="sm"  
      
          className="btn-setting" title="Xuất chứng nhận phát sóng">
          <i className="fa-solid fa-file-export"></i>
            </Button> */}
            </>
          ),
        });
        return item;
      });
      // }
      const totalStudent = data.reduce(
        (total, record) => total + record.value,
        0
      );
      const totalRow = {
        id: "total-student",
        month: "Tổng",
        contractType: 11,
        value: totalStudent,
      };

      const temporaryData = [...data, totalRow];
      return (
        <DataTables
          pagination={false}
          data={temporaryData}
          columns={columns}
          selectedRows={selectedRows}
        />
      );
    };

    const selectedRows_ = (items) => {
      this.getDetailContractbyId(items);

      this.setState({ selectedRows: [items] });
      return items;
    };
    const rowSelectCritera = (row) => {
      // row?.id == this.state.selectedRows[0]?.id;
      // console.log(row)
    };
    return (
      <DataTables
        className={
          this.props.page == "notification"
            ? "striped-rows"
            : "contractAd striped-rows"
        }
        data={temporaryData}
        columns={
          this.props.page == "notification"
            ? columns?.slice(1, columns?.length)
            : columns
        }
        selectableRows
        // onRowClicked={selectedRows_}
        selectableRowsSingle
        onSelectedRowsChange={handleSelectedRowsChange}
        conditionalRowStyles={conditionalRowStyles}
        selectableRowDisabled={selectableRowDisabled}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandedComponent listday={data} />
        )}
        expandableRowDisabled={selectableRowDisabled}
        // defaultSortFieldId={this.props.page == "notification" ? 0 : 1}
      />
    );
  }

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }
  customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "48px",
      color: state.isFocused ? "yellow" : "red",
      boxShadow: state.isFocused ? "0 0 0 4px rgba(199, 246, 255, 1)" : null,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "20px",
    }),
  };

  renderSearchFilter = () => {
    return (
      <Row>
        <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 6 }}>
          <Select
            defaultValue={this.state.listCustomer[0]}
            options={this.state.listCustomer}
            placeholder="Chọn khách hàng"
            styles={this.customStyles}
            value={this.state.CustomerItem}
            onChange={(choice) => {
              this.setState({ CustomerItem: choice });
            }}
          />
          <div className="placeholder-select">
            Chọn khách hàng<i className="text-danger"></i>
          </div>
        </Col>
        {this.props.page == "notification" ? null : (
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 5 }}>
            <Select
              defaultValue={this.state.listContractType[0]}
              options={this.state.listContractType}
              placeholder="Chọn loại hợp đồng"
              styles={this.customStyles}
              value={this.state.ContractTypeItem}
              onChange={(choice) => {
                this.setState({ ContractTypeItem: choice });
              }}
            />
            <div className="placeholder-select">
              Chọn loại hợp đồng<i className="text-danger"></i>
            </div>
          </Col>
        )}
        <Col
          className="mb-2 d-flex align-items-center mb-2 col-md-5 col-10 col-sm-10"
          style={{ zIndex: 4 }}
        >
          {/* Từ ngày */}
          <DatePicker
            selected={this.state.startDate}
            onChange={(date) =>
              this.setState({ startDate: date }, () => this.getRevenue())
            }
            isClearable
            className="form-control"
            dateFormat={"dd/MM/yyyy"}
            placeholderText="dd/mm/yyyy"
          />
          -{" "}
          <DatePicker
            selected={this.state.endDate}
            onChange={(date) =>
              this.setState({ endDate: date }, () => this.getRevenue())
            }
            isClearable
            className="form-control"
            dateFormat={"dd/MM/yyyy"}
            placeholderText="dd/mm/yyyy"
          />
        </Col>
        <Col className="mb-2 col-md-1 col-1 col-sm-1">
          <Button
            variant="outline-secondary"
            className="mt-1"
            size="lg"
            onClick={() => this.getlistclass()}
          >
            <i className="fa-solid fa-search"></i>
          </Button>
        </Col>
      </Row>
    );
  };
  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };
  ConvertBlockList(data) {
    let chuoi = "";

    data?.map((item, indexP) => {
      if (item.broadcastDateList?.length > 0) {
        chuoi +=
          item.adPrice?.timeStart?.replace(":", "h") +
          " Ngày " +
          item.broadcastDateList?.map((e, index) => {
            if (index + 1 === item.broadcastDateList?.length) {
              return data.length - 1 != indexP
                ? " " + this.getDate(e.date) + "; "
                : " " + this.getDate(e.date);
            } else {
              return new Date(e.date * 1000).getDate() >= 10
                ? " " + new Date(e.date * 1000).getDate()
                : " 0" + new Date(e.date * 1000).getDate();
            }
          });
      }
    });

    return chuoi;
  }

  soThanhChu(so) {
    const donVi = [
      "",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];
    const hang = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

    if (so === 0) return "Không";

    let chuoi = "";
    let i = 0;

    while (so > 0) {
      let donViHangNghin = so % 1000;
      if (donViHangNghin !== 0) {
        chuoi =
          this.soThanhChuHangNghin(donViHangNghin) +
          " " +
          hang[i] +
          " " +
          chuoi;
      }
      so = Math.floor(so / 1000);
      i++;
    }

    return chuoi.trim() + " đồng";
  }

  soThanhChuHangNghin(so) {
    const donVi = [
      "",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];

    let chuoi = "";

    if (so < 10) {
      chuoi = donVi[so];
    } else if (so < 100) {
      chuoi =
        chuc[Math.floor(so / 10)] + (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    } else if (so < 1000) {
      chuoi =
        donVi[Math.floor(so / 100)] +
        " trăm " +
        chuc[Math.floor((so % 100) / 10)] +
        (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    }

    return chuoi.trim();
  }
  xportContractbyId = () => {
    // swal({
    //   title: "Xác nhận xuất hợp đồng thông báo",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      this.listContractbyId(),
      "Hợp đồng thông báo"
    );

    XLSX.writeFile(
      wb,
      `HDTB_${this.state.dataExport?.contractCode?.replaceAll("/", ".")}.xlsx`
    );
    // }
    // });
  };
  listContractbyId = () => {
    const table = document.getElementById("exportContractbyId");
    var ws = XLSX.utils.table_to_sheet(table);
    ws["!printoptions"] = {
      scale: 50, // Tùy chỉnh tỉ lệ in ấn
    };
    ws["!page"] = {
      pageSize: { width: 210, height: 297 }, // Kích thước trang A4
      orientation: "landscape",
    };
    const colAlpha = ["A", "B", "C", "D", "E", "F", "G"];

    ws[`A4`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 16,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "center",
      },
    };

    ws[`B5`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "justify",
      },
    };
    ws[`B6`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "left",
      },
    };
    ws[`B7`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
        italic: true,
      },
      alignment: {
        wrapText: true,

        vertical: "top",
        horizontal: "left",
      },
    };
    ws[`B8`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B13`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B17`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B20`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B21`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
      },
      alignment: {
        wrapText: false,
        vertical: "center",
        horizontal: "left",
        // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[
      `B${
        26 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };

    for (let i = 9; i <= 12; i++) {
      ws[`B${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        },
      };
      ws[`D${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        },
      };
    }
    for (let i = 14; i <= 16; i++) {
      ws[`B${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        },
      };
      ws[`D${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        },
      };
    }
    for (let i = 18; i <= 19; i++) {
      ws[`B${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        },
      };
    }
    for (
      let i =
        27 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length;
      i <=
      25 +
        this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length +
        4;
      i++
    ) {
      ws[`B${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "justify",
        },
      };
      ws[`E${i}`].s = {
        font: {
          name: "Times New Roman",
          // bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "center",
        },
      };
    }
    for (
      let i =
        26 +
        this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length +
        4;
      i <=
      25 +
        this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length +
        6;
      i++
    ) {
      ws[`B${i}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: true,
          vertical: "top",
          horizontal: "center",
        },
      };
      ws[`E${i}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // underline: true,
          sz: 12.5,
        },
        alignment: {
          wrapText: false,
          vertical: "top",
          horizontal: "center",
        },
      };
    }
    ws[
      `B${
        23 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        italic: true,
        sz: 12.5,
      },
    };
    ws[
      `B${
        24 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        // italic: true,
        sz: 12.5,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "left",
      },
    };
    ws[
      `B${
        25 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        // italic: true,
        sz: 12.5,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "justify",
      },
    };

    // for (let i = 23+this.state.dataExport?.blockList?.length; i <=  23+this.state.dataExport?.blockList?.length+2; i++) {
    //   ws[`B${i}`].s = {
    //     font: {
    //       name: "Times New Roman",

    //       sz: 12.5,
    //   }
    // }}

    for (let i = 1; i <= 3; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`]?.v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}2`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
            underline: true,
            sz: 11.5,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };

        ws[`${alpha}1`].s = {
          font: {
            name: "Times New Roman",
            bold: true,

            sz: 11.5,
          },
          alignment: {
            wrapText: alpha == "D" ? false : true,
            vertical: "center",
            horizontal: "center",
          },
        };
        ws[`${alpha}3`].s = {
          font: {
            name: "Times New Roman",
            bold: true,

            sz: 11.5,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };

        return alpha;
      });
    }

    for (
      let i = 0;
      i < this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length + 1;
      i++
    ) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${22 + i}`].v == "empty") {
          ws[`${alpha}${22 + i}`].v = " ";
        }

        ws[`${alpha}${21}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
            sz: 12,
          },
          alignment: {
            wrapText: false,
            vertical: "center",
            horizontal: "center",
            // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        ws[`${alpha}${22 + i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 12,
            bold:
              i ==
              this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length
                ? true
                : false,
          },
          alignment: {
            wrapText: alpha == "F" ? false : true,
            vertical: "center",
            // horizontal: "left",
            horizontal:
              i ==
                this.mergeBroadcastDate(this.state.dataExport?.blockList)
                  ?.length && alpha === "A"
                ? "center"
                : alpha === "D" ||
                  alpha === "E" ||
                  alpha === "F" ||
                  alpha === "G"
                ? "right"
                : "left",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        ws[`${alpha}${i + 22}`].z = "#,###,###,###";
        delete ws[`${alpha}${i + 22}`].w;
        XLSX.utils.format_cell(ws[`${alpha}${i + 22}`]);

        return alpha;
      });
    }

    for (
      let i = 1;
      i <
      this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length +
        3 +
        21 +
        10;
      i++
    ) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v == "empty") {
          ws[`${alpha}${i}`].v = " ";
        }
        ws[
          `B${
            this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length +
            3 +
            21 +
            9 +
            2 +
            1 +
            1
          }`
        ].s = {
          font: {
            name: "Times New Roman",
            sz: 12.5,
            bold: true,
          },
          alignment: {
            wrapText: false,
            vertical: "center",
            horizontal: "center",
          },
        };
        ws[
          `E${
            this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length +
            3 +
            21 +
            9 +
            2 +
            1 +
            1
          }`
        ].s = {
          font: {
            name: "Times New Roman",
            sz: 12.5,
            bold: true,
          },
          alignment: {
            wrapText: false,
            vertical: "center",
            horizontal: "center",
          },
        };
        return alpha;
      });
    }
    ws["!cols"] = [
      { wch: 1 },
      { wch: 20 },
      { wch: 14 },
      { wch: 21.44 },
      { wch: 5.5 },
      { wch: 12 },
      { wch: 13 },
    ];

    ws["!rows"] = [
      { hpt: 15 },
      { hpt: 15 },
      { hpt: 13 },
      { hpt: 28 },
      { hpt: 34 },
    ];

    for (let i = 1; i <= 12; i++) ws["!rows"].push({ hpt: 16.8 });
    ws["!rows"].push({ hpt: 34 });
    ws["!rows"].push({ hpt: 34 });
    ws["!rows"].push({ hpt: 16.8 });
    ws["!rows"].push({ hpt: 16.8 });
    let count =
      ((16 +
        this.ConvertBlockList(
          this.mergeBroadcastDate(this.state.dataExport?.blockList)
        )?.length) *
        16.8) /
        91 +
        1 >
      16.8
        ? Math.ceil(
            (16 +
              this.ConvertBlockList(
                this.mergeBroadcastDate(this.state.dataExport?.blockList)
              )?.length) /
              91
          ) * 16.8
        : 16.8;

    this.mergeBroadcastDate(this.state.dataExport?.blockList)?.map((it) => {
      let arrT =
        it?.content?.length > it.adPrice?.name?.length
          ? it?.content?.length
          : it?.adPrice?.name?.length;
      let count1 =
        (arrT * 16.8) / 25 + 1 > 16.8 ? Math.ceil(arrT / 25) * 16 : 16.8;

      ws["!rows"].push({ hpt: `${count1}` });
    });
    ws["!rows"].push({ hpt: "" });
    ws["!rows"].push({ hpt: "" });

    ws["!rows"].push({ hpt: `${count}` });
    ws["!rows"].push({ hpt: 17 });
    ws["!rows"].push({ hpt: "" });

    ws["!rows"].push({ hpt: 51 });
    ws["!rows"].push({ hpt: 34 });
    ws["!rows"].push({ hpt: 17 });

    let countAddress =
      ((12 + this.state.dataExport?.customerAddress?.length) * 16.8) / 88 + 1 >
      16.8
        ? Math.ceil(
            (12 + this.state.dataExport?.customerAddress?.length) / 88
          ) * 16.8
        : 16.8;
    let countCustomer =
      ((7 + this.state.dataExport?.customer?.name?.length) * 16.8) / 63 + 1 >
      16.8
        ? Math.ceil((7 + this.state.dataExport?.customer?.name?.length) / 63) *
          16.8
        : 16.8;
    ws["!rows"][14] = { hpt: countAddress };
    ws["!rows"][12] = { hpt: countCustomer };
    ws["!rows"][
      29 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length
    ] = { hpt: 13 };

    const mergePositions = [
      { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } },
      { s: { c: 3, r: 0 }, e: { c: 6, r: 0 } },
      { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } },
      { s: { c: 3, r: 1 }, e: { c: 6, r: 1 } },
      { s: { c: 0, r: 2 }, e: { c: 2, r: 2 } },
      { s: { c: 3, r: 2 }, e: { c: 6, r: 2 } },

      { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } },
      { s: { c: 1, r: 4 }, e: { c: 6, r: 4 } },
      { s: { c: 1, r: 5 }, e: { c: 6, r: 5 } },
      { s: { c: 1, r: 6 }, e: { c: 6, r: 6 } },
      { s: { c: 1, r: 7 }, e: { c: 6, r: 7 } },

      { s: { c: 1, r: 8 }, e: { c: 2, r: 8 } },
      { s: { c: 3, r: 8 }, e: { c: 6, r: 8 } },

      { s: { c: 1, r: 9 }, e: { c: 6, r: 9 } },
      { s: { c: 1, r: 10 }, e: { c: 2, r: 10 } },
      { s: { c: 3, r: 10 }, e: { c: 6, r: 10 } },
      { s: { c: 1, r: 10 }, e: { c: 6, r: 10 } },
      { s: { c: 1, r: 11 }, e: { c: 6, r: 11 } },
      { s: { c: 1, r: 12 }, e: { c: 6, r: 12 } },

      { s: { c: 1, r: 13 }, e: { c: 2, r: 13 } },
      { s: { c: 3, r: 13 }, e: { c: 6, r: 13 } },
      { s: { c: 1, r: 14 }, e: { c: 6, r: 14 } },
      { s: { c: 1, r: 15 }, e: { c: 2, r: 15 } },
      { s: { c: 3, r: 15 }, e: { c: 6, r: 15 } },
      { s: { c: 1, r: 16 }, e: { c: 6, r: 16 } },
      { s: { c: 1, r: 17 }, e: { c: 6, r: 17 } },
      { s: { c: 1, r: 18 }, e: { c: 6, r: 18 } },
      { s: { c: 1, r: 19 }, e: { c: 6, r: 19 } },
      { s: { c: 0, r: 20 }, e: { c: 1, r: 20 } },
      { s: { c: 2, r: 20 }, e: { c: 3, r: 20 } },

      {
        s: { c: 0, r: 21 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 3, r: 21 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      // {
      //   s: { c: 2, r: 21 + this.state.dataExport?.blockList?.length },
      //   e: { c: 3, r: 21 + this.state.dataExport?.blockList?.length },
      // },
      {
        s: { c: 1, r: 23 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 23 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 22 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 22 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 24 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 24 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 25 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 25 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 26 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 26 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 27 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 27 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 28 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 28 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 30 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 2, r: 30 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 1, r: 36 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 2, r: 36 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },

      {
        s: { c: 4, r: 30 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 30 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
      {
        s: { c: 4, r: 36 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
        e: { c: 6, r: 36 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length },
      },
    ];
    for (let i = 1; i <= this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length; i++) {
      mergePositions.push(
        {
          s: { c: 0, r: 21 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length - i },
          e: { c: 1, r: 21 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length - i },
        },
        {
          s: { c: 2, r: 21 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length - i },
          e: { c: 3, r: 21 + this.mergeBroadcastDate(this.state.dataExport?.blockList)?.length - i },
        }
      );
    }
    ws["!margins"] = {
      left: 1.5 / 2.54,
      right: 1.0 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };

    // Gộp các ô
    ws["!merges"] = mergePositions;
    return ws;
  };
  //Xuất CNPS
  exportCNPS = () => {
    // swal({
    //   title: "Xác nhận xuất CNPS",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      this.broadcastCertificationExport(),
      "Chứng nhận phát sóng"
    );
    XLSX.writeFile(
      wb,
      `CNPS_${this.state.broadcastCertification?.contractCode?.replaceAll(
        "/",
        "."
      )}.xlsx`
    );
    //   }
    // });
  };
  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (
        data.hasOwnProperty(cellRef) &&
        data[cellRef].t === "s" &&
        data[cellRef].v == targetValue
      ) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };

  extractNumber = (inputString) => {
    const numberPart = inputString.replace(/[^\d]/g, "");
    return parseInt(numberPart, 10);
  };

  numberToWords = (number) => {
    const units = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

    const convertGroup = (num) => {
      const ones = [
        "",
        "một",
        "hai",
        "ba",
        "bốn",
        "năm",
        "sáu",
        "bảy",
        "tám",
        "chín",
      ];
      const teens = [
        "",
        "mười",
        "mười một",
        "mười hai",
        "mười ba",
        "mười bốn",
        "mười lăm",
        "mười sáu",
        "mười bảy",
        "mười tám",
        "mười chín",
      ];

      let words = "";

      const hundred = Math.floor(num / 100);
      const remainder = num % 100;

      if (hundred > 0) {
        words += ones[hundred] + " trăm";
      }

      if (remainder > 0) {
        if (words !== "") {
          words += " ";
        }

        if (remainder < 10) {
          words += ones[remainder];
        } else if (remainder < 20) {
          words += teens[remainder - 10];
        } else {
          const tens = Math.floor(remainder / 10);
          const onesDigit = remainder % 10;
          words += tens > 0 ? `${ones[tens]} mươi` : "";
          words += onesDigit > 0 ? ` ${ones[onesDigit]}` : "";
        }
      }

      return words;
    };

    const numberStr = number.toString();

    if (numberStr === "0") {
      return "không đồng";
    }

    const groups = [];
    for (let i = numberStr.length; i > 0; i -= 3) {
      groups.push(Number(numberStr.slice(Math.max(0, i - 3), i)));
    }

    let result = "";
    for (let i = groups.length - 1; i >= 0; i--) {
      const groupWords = convertGroup(groups[i]);
      if (groupWords !== "") {
        if (result !== "") {
          result += " ";
        }
        result += `${groupWords} ${units[i]}`;
      }
    }

    return this.capitalizeFirstLetter(result + " đồng");
  };
  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  totalValueBroadcastCertification = (broadcastCertification) => {
    let sum = 0;
    broadcastCertification?.map((broadcastItem) => {
      sum +=
        broadcastItem.arrDate.reduce((sum, item) => {
          if (item.date != "") return sum + item.date;
          else return sum;
        }, 0) * broadcastItem?.realPrice;
    });

    return sum;
  };

  broadcastCertificationExport = () => {
    const table = document.getElementById("exportBroadcastCertification");
    var ws = XLSX.utils.table_to_sheet(table);

    const applyStyle = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: 12, bold: true },
          alignment: { vertical: "center", horizontal: "center" },
        },
        t: "s",
        v: value,
      };
    };

    function setCellStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "right",
          horizontal: "right",
        },
      };
    }

    function setCellBottomStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
      };
    }

    function setCellItalicStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          italic: true,
        },
      };
    }
    const applyStyleNormal = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: 12 },
        },
        v: value,
      };
    };

    const applyStyleNormalFont = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: 12 },
          alignment: { vertical: "center", horizontal: "center" },
        },
        t: "s",
        v: value,
      };
    };

    const applyStyleSmall = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          ...ws[`${col}${row}`].s,
          font: { name: "Times New Roman", sz: 11.25 },
        },
        v: value,
      };
    };

    applyStyleNormal("6", "C", ws["C6"].v);
    applyStyleNormal("6", "P", ws["P6"].v);

    // Merge A1 to d1 with styling
    ws["!merges"] = ws["!merges"] || [];
    ws["!merges"].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } });
    ws["A1"].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    // Merge A2 to d2 with styling
    ws["!merges"].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });
    ws["A2"].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    // Merge B3 to E3 with styling
    ws["!merges"].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 5 } });
    ws["A3"].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws["!merges"].push({ s: { r: 3, c: 0 }, e: { r: 3, c: 35 } });

    ws[`A4`] = {
      s: {
        font: { name: "Times New Roman", sz: 16, bold: true },
        alignment: { vertical: "center", horizontal: "center" },
      },
      t: "s",
      v: ws["A4"]?.v,
    };

    ws["!merges"].push({ s: { r: 6, c: 2 }, e: { r: 6, c: 4+this.soNgayTrongThang(this.state.monthCheck) } });
    ws[`C7`] = {
      s: {
        font: { name: "Times New Roman", sz: 12 },
        alignment: {
          wrapText: true,
        },
      },
      t: "s",
      v: ws["C7"]?.v,
    };

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const fontSize = 11.5;
    context.font = fontSize + "px Times New Roman";
    const textWidth = context.measureText(ws["C7"]?.v).width;
    let sizeCol=
    this.soNgayTrongThang(this.state.monthCheck)==31?576:
    this.soNgayTrongThang(this.state.monthCheck)==30?547:
    this.soNgayTrongThang(this.state.monthCheck)==29?547:
    555


    let countC7 =
      (textWidth * 17) / sizeCol > 17 ? Math.ceil(textWidth / sizeCol) * 17 : 17;

    ws["!rows"][6] = { hpt: countC7 };

    ws["!cols"] = ws["!cols"] || [];

    for (let col = 0; col <= 35; col++) {
      applyStyle(
        10,
        String.fromCharCode(65 + col),
        ws[String.fromCharCode(65 + col) + "10"]?.v
      );

      ws[`${String.fromCharCode(65 + col)}10`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
      };

      if (col >= 5) {
        ws["!cols"][col] = { wch: 2 };

        let colLabel = "";

        if (col <= 25) {
          colLabel = String.fromCharCode(65 + col); // A to Z
        } else {
          const firstLetter = String.fromCharCode(
            65 + Math.floor(col / 26) - 1
          );
          const secondLetter = String.fromCharCode(65 + (col % 26));
          colLabel = firstLetter + secondLetter; // AA to AL
        }

        applyStyle(10, colLabel, ws[colLabel + "10"]?.v);

        ws[`${colLabel}10`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
        };
      }
    }

    ws["!cols"][0] = { wch: 22 };
    ws["!cols"][4] = { wch: 11 };
    ws["!cols"][1] = { wch: 15 };
    ws["!cols"][2] = { wch: 3 };
    ws["!rows"][9] = { hpt: 22 };

    const colContractValueLabel = this.findCellByValue(ws, "TỔNG GIÁ TRỊ:");
    const discountValueLabel = this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:");
    const paymentValueLabel = this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:");

    setCellStyle(colContractValueLabel);
    setCellStyle(discountValueLabel);
    setCellStyle(paymentValueLabel);
    setCellStyle(
      `E` + this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))
    );
    setCellStyle(
      `E` + this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))
    );
    setCellStyle(
      `E` + this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))
    );
    setCellItalicStyle(
      `A` +
        (this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")) +
          1)
    );

    setCellBottomStyle(this.findCellByValue(ws, "ĐẠI DIỆN BÊN A"));
    setCellBottomStyle(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG"));
    setCellBottomStyle(this.findCellByValue(ws, "ĐẠI DIỆN BÊN B"));

    const resentativeA = this.extractNumber(
      this.findCellByValue(ws, "ĐẠI DIỆN BÊN A")
    );
    const resentativeAName =
      this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN BÊN A")) + 5;

    ws["!merges"].push({
      s: { r: resentativeA - 1, c: 0 },
      e: { r: resentativeA - 1, c: 3 },
    });

    ws["!merges"].push({
      s: { r: resentativeAName - 1, c: 0 },
      e: { r: resentativeAName - 1, c: 3 },
    });

    const creatorTable = this.extractNumber(
      this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")
    );
    const creatorTableName =
      this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) + 5;

    ws["!merges"].push({
      s: { r: creatorTable - 1, c: 5 },
      e: { r: creatorTable - 1, c: 14 },
    });

    ws["!merges"].push({
      s: { r: creatorTableName - 1, c: 5 },
      e: { r: creatorTableName - 1, c: 14 },
    });

    const resentativeB = this.extractNumber(
      this.findCellByValue(ws, "ĐẠI DIỆN BÊN B")
    );
    const resentativeBName =
      this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN BÊN B")) + 5;

    ws["!merges"].push({
      s: { r: resentativeB - 1, c: 23 },
      e: { r: resentativeB - 1, c: 32 },
    });

    ws["!merges"].push({
      s: { r: resentativeBName - 1, c: 23 },
      e: { r: resentativeBName - 1, c: 32 },
    });

    applyStyleNormalFont(resentativeA, "A", ws["A" + resentativeA]?.v);
    applyStyleNormalFont(
      resentativeA + 5,
      "A",
      ws["A" + (resentativeA + 5)]?.v
    );
    applyStyleNormalFont(creatorTable, "F", ws["F" + creatorTable]?.v);
    applyStyleNormalFont(
      creatorTable + 5,
      "F",
      ws["F" + (creatorTable + 5)]?.v
    );
    applyStyleNormalFont(resentativeB, "X", ws["X" + resentativeB]?.v);
    applyStyleNormalFont(
      resentativeB + 5,
      "X",
      ws["X" + (resentativeB + 5)]?.v
    );
    ws[`U${resentativeB - 1}`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        italic: true,
      },
    };

    for (let i = 1; i <= resentativeB + 4; i++) ws["!rows"].push({ hpt: "" });
    const count =
      ws["X" + (resentativeB + 5)]?.v.length <= 19
        ? 19
        : ws["X" + (resentativeB + 5)]?.v.length;
    ws["!rows"].push({ hpt: (count * 17) / 19 });

    ws[`X` + (resentativeB + 5)].s = {
      ...ws[`X` + (resentativeB + 5)].s,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
    };

    const startPosition = { r: 9, c: 0 };
    const endPosition = {
      r: this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:")) - 2,
      c: 38,
    }; // Assuming AL corresponds to column 38

    // Add borders to all cells in the range from A9 to AL15
    for (let row = startPosition.r; row <= endPosition.r; row++) {
      for (let col = startPosition.c; col <= endPosition.c; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

        if (ws[cellRef]?.v == "empty") {
          // Delete the cell
          ws[cellRef].v = "";
        }
        // Ensure cell exists
        if (!ws[cellRef]) {
          ws[cellRef] = {};
        }

        // Apply borders to the cell
        ws[cellRef].s = {
          font: {
            name: "Times New Roman",
          },
          ...ws[cellRef].s,
          border: {
            top: { style: "hair", color: { rgb: "000000" } },
            bottom: { style: "hair", color: { rgb: "000000" } },
            left: { style: "hair", color: { rgb: "000000" } },
            right: { style: "hair", color: { rgb: "000000" } },
            inside: { style: "hair", color: { rgb: "000000" } },
          },
          outline: true,
          alignment: {
            wrapText:
              cellRef == XLSX.utils.encode_cell({ r: 9, c: col })
                ? false
                : true,
            vertical: "center",
            horizontal:
              row == 9
                ? "center"
                : col == 0 || col == 1
                ? "left"
                : col == 3 || col == 4
                ? "right"
                : "center",
          },
        };

        if (col === 3 || col === 4) {
          ws[cellRef].z = "#,###,###,###";
          delete ws[cellRef].w;
          XLSX.utils.format_cell(ws[cellRef]);
        }
      }
    }

    ws[`E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`] = {
      ...ws[
        `E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`
      ],
      f: `=SUM(E11:E${this.state.blockListForCertification?.length + 10})`,
      s: {
        ...ws[
          `E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`
        ].s,
      },
    };

    ws[`E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`].z =
      "#,###,###,###";

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].v = ws[
      `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].v
      ? ws[
          `E${this.extractNumber(
            this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:")
          )}`
        ].v + "%"
      : "";

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].s = {
      ...ws[
        `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
      ].s,
    };

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`
    ].z = "#,###,###,###";

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`
    ] = {
      ...ws[
        `E${this.extractNumber(
          this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")
        )}`
      ],
      f: `=E${this.extractNumber(
        this.findCellByValue(ws, "TỔNG GIÁ TRỊ:")
      )} - E${this.extractNumber(
        this.findCellByValue(ws, "TỔNG GIÁ TRỊ:")
      )}*E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`,
      s: {
        ...ws[
          `E${this.extractNumber(
            this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")
          )}`
        ].s,
      },
    };

    applyStyleSmall("7", "C", ws["C7"].v);
    applyStyleSmall("8", "E", ws["E8"].v);
    applyStyleSmall("8", "H", ws["H8"].v);
    applyStyleSmall("8", "P", ws["P8"].v);

    ws["!cols"] = [
      { wch: 19.78 },
      { wch:
        this.soNgayTrongThang(this.state.monthCheck)==31? 11.44:
        this.soNgayTrongThang(this.state.monthCheck)==30? 14.1:
        this.soNgayTrongThang(this.state.monthCheck)==29?12.33:   11.33 },
      // 31:11.44
      { wch: 2.33 },
      { wch: 11.78 },
      { wch: 11.78 },
    ];

    for (let i = 0; i <this.soNgayTrongThang(this.state.monthCheck); i++)
    // 28 : 2.00001
    // 31:1.7
    this.soNgayTrongThang(this.state.monthCheck)==31?
    ws["!cols"].push({ wch: 1.7 })
    : this.soNgayTrongThang(this.state.monthCheck)==30?
    ws["!cols"].push({ wch: 1.8 }):
    this.soNgayTrongThang(this.state.monthCheck)==29?
    ws["!cols"].push({ wch: 1.9 }):
    ws["!cols"].push({ wch: 2})
    ws["!margins"] = {
      left: 0.8 / 2.54,
      right: 0.8 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };
  

    return ws;
  };
  soNgayTrongThang(inputStr) {
    try {
      // Chia đầu vào thành tháng và năm
      const [thang, nam] = inputStr.split("/").map(Number);

      // Kiểm tra tháng có hợp lệ hay không
      if (thang < 1 || thang > 12) {
        return "Tháng không hợp lệ";
      }

      // Kiểm tra năm nhuận
      if (thang === 2) {
        if ((nam % 4 === 0 && nam % 100 !== 0) || nam % 400 === 0) {
          return 29; // Năm nhuận có tháng 2 có 29 ngày
        } else {
          return 28; // Năm không nhuận có tháng 2 có 28 ngày
        }
      }

      // Các tháng có 31 ngày
      const thang31Ngay = [1, 3, 5, 7, 8, 10, 12];
      if (thang31Ngay.includes(thang)) {
        return 31;
      }

      // Các tháng còn lại có 30 ngày
      return 30;
    } catch (error) {
      return "Đầu vào không hợp lệ. Xin vui lòng nhập theo định dạng 'tháng/năm'.";
    }
  }

  //Xuất danh sách hợp đồng
  xport = () => {
    // swal({
    //   title: "Xác nhận xuất danh sách hợp đồng",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, this.listCustomerExport(), "Hợp đồng");

    XLSX.writeFile(wb, "Danh sach hop dong.xlsx");
    //   }
    // });
  };

  listCustomerExport = () => {
    const table = document.getElementById("exportListContract");
    const wb = XLSX.utils.table_to_book(
      table
      // ,{raw:true }
      // ,{dense:true}
    );
    var ws = wb.Sheets["Sheet1"];
    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      // border: {
      //   top: { style: "thin" },
      //   bottom: { style: "thin" },
      //   left: { style: "thin" },
      //   right: { style: "thin" },
      // },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${3 + this.state.listcontract?.length}`].v === "empty") {
        ws[`${alpha}${3 + this.state.listcontract?.length}`].v = " ";
      }
    });
    for (let i = 0; i < this.state.listcontract?.length + 1; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${3 + i}`].v === "empty") {
          ws[`${alpha}${3 + i}`].v = " ";
        }
        if (alpha == "F" || alpha == "G" || alpha == "H" || alpha == "I") {
          ws[`${alpha}${i + 3}`].z = "#,###,###,###";
          delete ws[`${alpha}${i + 3}`].w;
          XLSX.utils.format_cell(ws[`${alpha}${i + 3}`]);
        }
        if (alpha == "D") {
          ws[`${alpha}${i + 3}`].z = "#,###,###,###";
          delete ws[`${alpha}${i + 3}`].w;
          XLSX.utils.format_cell(ws[`${alpha}${i + 3}`]);
        } else {
        }
        ws[`${alpha}${3 + i}`].s = {
          font: {
            name: "Times New Roman",
            // sz: 11
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            // horizontal: "left",
            horizontal:
              alpha === "A" ||
              alpha === "B" ||
              alpha === "C" ||
              alpha === "D" ||
              alpha === "E"
                ? "left"
                : "right",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }
    colAlpha?.map((alpha) => {
      ws[`${alpha}${this.state.listcontract?.length + 3}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        numFmt: "#,##0.00",
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
    });
    ws["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 11 },
      { wch: 11 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    for (let i = 0; i < this.state.listcontract?.length; i++) {
      // ws[`D${i + 3}`].v = {
      //   f:
      //   `=LEFT(D${i+3}, LEN(D${i+3})-2)`
      // };
      ws[`D${i + 3}`].v = `${ws[`D${i + 3}`].v.substring(1)}`;
      ws[`E${i + 3}`].v = `${ws[`E${i + 3}`].v.substring(1)}`;
    }
    ws["!rows"] = [];
    // ws['H9'] = { f: `SUM(VALUE(F3:F4))` }
    ws[`F${this.state.listcontract?.length + 3}`] = {
      f: `=SUM(F3:F${this.state.listcontract.length + 2})`,
    };
    ws[`G${this.state.listcontract?.length + 3}`] = {
      f: `=SUM(G3:G${this.state.listcontract.length + 2})`,
    };
    ws[`H${this.state.listcontract?.length + 3}`] = {
      f: `=SUM(H3:H${this.state.listcontract.length + 2})`,
    };
    ws[`I${this.state.listcontract?.length + 3}`] = {
      f: `=SUM(I3:I${this.state.listcontract.length + 2})`,
    };
    ws[`F${this.state.listcontract?.length + 3}`].z = "#,###,###,###";
    delete ws[`F${this.state.listcontract?.length + 3}`].w;
    XLSX.utils.format_cell(ws[`F${this.state.listcontract?.length + 3}`]);

    ws[`G${this.state.listcontract?.length + 3}`].z = "#,###,###,###";
    delete ws[`G${this.state.listcontract?.length + 3}`].w;
    XLSX.utils.format_cell(ws[`G${this.state.listcontract?.length + 3}`]);

    ws[`H${this.state.listcontract?.length + 3}`].z = "#,###,###,###";
    delete ws[`H${this.state.listcontract?.length + 3}`].w;
    XLSX.utils.format_cell(ws[`H${this.state.listcontract?.length + 3}`]);

    ws[`I${this.state.listcontract?.length + 3}`].z = "#,###,###,###";
    delete ws[`I${this.state.listcontract?.length + 3}`].w;
    XLSX.utils.format_cell(ws[`I${this.state.listcontract?.length + 3}`]);

    ws[`A${this.state.listcontract?.length + 3}`].v = "Tổng";
    const mergePositions = [
      { s: { c: 0, r: 0 }, e: { c: 8, r: 0 } },

      {
        s: { c: 0, r: this.state.listcontract?.length + 2 },
        e: { c: 4, r: this.state.listcontract?.length + 2 },
      },
    ];

    // Gộp các ô
    ws["!merges"] = mergePositions;
    ws[`H${this.state.listcontract?.length + 3}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[`I${this.state.listcontract?.length + 3}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[`G${this.state.listcontract?.length + 3}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[`F${this.state.listcontract?.length + 3}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    return ws;
  };

  //Xuất Thanh lý hđ

  xportLiquidationbyId = () => {
    // swal({
    //   title: "Xác nhận xuất thanh lý hợp đồng",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      this.listLiquidationbyId(),
      "Thanh lý hợp đồng"
    );

    XLSX.writeFile(
      wb,
      `TLHD_${this.state.dataExport?.contractCode?.replaceAll("/", ".")}.xlsx`
    );
    //   }
    // });
  };
  listLiquidationbyId = () => {
    const table = document.getElementById("exportContractLiquidationbyId");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];
    const colAlpha = ["A", "B", "C", "D", "E", "F"];

    ws[`A4`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 16,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`B5`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "left",
      },
    };

    ws[`B6`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
        italic: true,
      },
      alignment: {
        wrapText: true,

        vertical: "top",
        horizontal: "left",
      },
    };
    ws[`B7`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B13`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B23`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B24`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B22`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
        italic: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`B31`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: false,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`B26`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        // bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "left",
        horizontal: "left",
      },
    };
    ws[`E31`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: false,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`E26`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: false,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`B26`].s = {
      font: {
        name: "Times New Roman",
        sz: 12.5,
        bold: true,
      },
      alignment: {
        wrapText: false,
        vertical: "center",
        horizontal: "center",
      },
    };
    for (let i = 5; i <= 24; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`]?.v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }
      });
    }
    for (let i = 8; i <= 12; i++) {
      colAlpha.map((alpha) => {
        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 12.5,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "left",
          },
        };
      });
    }
    for (let i = 14; i <= 21; i++) {
      colAlpha.map((alpha) => {
        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 12.5,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "left",
          },
        };
      });
    }

    for (let i = 1; i <= 3; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`]?.v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}2`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
            underline: true,
            sz: 10,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };

        ws[`${alpha}1`].s = {
          font: {
            name: "Times New Roman",
            bold: true,

            sz: 10,
          },
          alignment: {
            wrapText: alpha == "D" ? false : true,
            vertical: "center",
            horizontal: "center",
          },
        };
        ws[`${alpha}3`].s = {
          font: {
            name: "Times New Roman",
            bold: true,

            sz: 11,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };

        return alpha;
      });
    }

    ws["!cols"] = [
      { wch: 1 },
      { wch: 19 },
      { wch: 20.3 },
      { wch: 6.9 },
      { wch: 19.8 },
      { wch: 20.7 },
    ];

    ws["!rows"] = [
      { hpt: 17 },
      { hpt: 17 },
      { hpt: 15 },
      { hpt: 40 },
      { hpt: 17 },
    ];
    for (let i = 1; i <= 14; i++) ws["!rows"].push({ hpt: 17 });
    ws["!rows"].push({ hpt: 34 });

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const fontSize = 12.5;
    context.font = fontSize + "px Times New Roman";
    const textWidth = context.measureText(ws["B15"]?.v).width;

    let countB15 =
      (textWidth * 17) / 495 > 17 ? Math.ceil(textWidth / 495) * 17 : 17;

    ws["!rows"][14] = { hpt: countB15 };

    const mergePositions = [
      { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } },
      { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } },
      { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } },
      { s: { c: 3, r: 1 }, e: { c: 5, r: 1 } },
      { s: { c: 0, r: 2 }, e: { c: 2, r: 2 } },
      { s: { c: 3, r: 2 }, e: { c: 5, r: 2 } },

      { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } },
      { s: { c: 1, r: 4 }, e: { c: 5, r: 4 } },
      { s: { c: 1, r: 5 }, e: { c: 5, r: 5 } },
      { s: { c: 1, r: 6 }, e: { c: 5, r: 6 } },
      { s: { c: 1, r: 7 }, e: { c: 2, r: 7 } },
      { s: { c: 3, r: 7 }, e: { c: 5, r: 7 } },

      { s: { c: 1, r: 8 }, e: { c: 5, r: 8 } },
      { s: { c: 1, r: 9 }, e: { c: 5, r: 9 } },
      { s: { c: 1, r: 10 }, e: { c: 5, r: 10 } },
      { s: { c: 1, r: 11 }, e: { c: 5, r: 11 } },
      { s: { c: 1, r: 12 }, e: { c: 5, r: 12 } },
      { s: { c: 1, r: 13 }, e: { c: 2, r: 13 } },
      { s: { c: 3, r: 13 }, e: { c: 5, r: 13 } },

      { s: { c: 1, r: 14 }, e: { c: 5, r: 14 } },
      { s: { c: 1, r: 15 }, e: { c: 5, r: 15 } },
      { s: { c: 1, r: 16 }, e: { c: 5, r: 16 } },
      { s: { c: 1, r: 17 }, e: { c: 5, r: 17 } },
      { s: { c: 1, r: 18 }, e: { c: 5, r: 18 } },
      { s: { c: 1, r: 19 }, e: { c: 5, r: 19 } },
      { s: { c: 1, r: 20 }, e: { c: 5, r: 20 } },
      { s: { c: 1, r: 21 }, e: { c: 5, r: 21 } },
      { s: { c: 1, r: 22 }, e: { c: 5, r: 22 } },
      { s: { c: 1, r: 23 }, e: { c: 5, r: 23 } },
      { s: { c: 1, r: 25 }, e: { c: 2, r: 25 } },
      { s: { c: 4, r: 25 }, e: { c: 5, r: 25 } },
      { s: { c: 1, r: 30 }, e: { c: 2, r: 30 } },
      { s: { c: 4, r: 30 }, e: { c: 5, r: 30 } },
    ];

    // Gộp các ô
    ws["!merges"] = mergePositions;
    ws["!margins"] = {
      left: 1.5 / 2.54,
      right: 1.0 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };
    return ws;
  };
  // Xuất sổ doanh thu
  xportRevenue = () => {
    // swal({
    //   title: "Xác nhận xuất sổ doanh thu",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, this.listRevenueExport(), "Sổ doanh thu");
    const ws = this.listRevenueExport();
    ws["!gridlines"] = {
      $: { showGridLines: false },
    };
    ws["!sheetView"] = {
      showGridLines: false, // 0: Tắt đường lưới, 1: Hiện đường lưới
    };
    ws["!options"] = { showGridLines: 0 };
    XLSX.utils.book_append_sheet(wb, ws, "Sổ doanh thu");
    XLSX.writeFile(wb, "So doanh thu.xlsx");
    //   }
    // });
  };
  listRevenueExport = () => {
    const table = document.getElementById("exportlistRevenueExport");
    var ws = XLSX.utils.table_to_sheet(table);

    const colAlpha = ["A", "B", "C", "D", "E", "F"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
        bold: false,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: undefined,
    };
    ws[`D1`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: undefined,
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          underline: true,
          sz: 11,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: undefined,
      };

      return alpha;
    });
    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: undefined,
      };

      return alpha;
    });
    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 16,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: undefined,
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}5`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: undefined,
      };
      return alpha;
    });

    colAlpha.map((alpha) => {
      if (ws[`${alpha}3`].v === "empty") {
        ws[`${alpha}3`].v = " ";
      }
      if (ws[`${alpha}${this.state.listRevenue?.length + 8}`].v === "empty") {
        ws[`${alpha}${this.state.listRevenue?.length + 8}`].v = " ";
      }
      if (ws[`${alpha}${this.state.listRevenue?.length + 9}`].v === "empty") {
        ws[`${alpha}${this.state.listRevenue?.length + 9}`].v = " ";
      }
    });

    for (let i = 3; i < this.state.listRevenue?.length + 5; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${3 + i}`].v === "empty") {
          ws[`${alpha}${3 + i}`].v = " ";
        }

        ws[`${alpha}${3 + i}`].s = {
          font: {
            name: "Times New Roman",
            // sz: 11
            bold: i == 3 ? true : false,
          },
          alignment: {
            wrapText: i == 3 ? false : true,
            vertical: "center",
            horizontal:
              alpha == "A"
                ? "center"
                : i == 3
                ? "center"
                : alpha == "F"
                ? "right"
                : "left",
            // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
        return alpha;
      });

      ws[`C${i + 3}`].v = ws[`C${i + 3}`]?.v?.replace("a", "");

      ws[`F${i + 3}`].z = "#,###,###,###";
      delete ws[`F${i + 3}`].w;
      XLSX.utils.format_cell(ws[`F${i + 3}`]);
    }

    ws[`${"F" + (7 + this.state.listRevenue?.length)}`].s = {
      ...ws[`${"F" + (7 + this.state.listRevenue?.length)}`].s,
      font: {
        name: "Times New Roman",
        bold: true,
      },
    };

    ws[`E${9 + this.state.listRevenue?.length}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        // sz: 11
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: undefined,
    };

    ws["!cols"] = [
      { wch: 5 },
      { wch: 20 },
      { wch: 10 },
      { wch: 30 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws["!rows"] = [
      { hpt: "" },
      { hpt: "" },
      { hpt: "" },
      { hpt: "" },
      { hpt: 30 },
      { hpt: 22 },
    ];
    ws["A5"].s = {
      ...ws["A5"].s,
      alignment: {
        vertical: "top",
        horizontal: "center",
      },
    };

    const mergePositions = [
      { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } },
      { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } },
      { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } },
      { s: { c: 3, r: 1 }, e: { c: 5, r: 1 } },
      { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
      { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } },
      { s: { c: 0, r: 4 }, e: { c: 5, r: 4 } },

      {
        s: { c: 0, r: this.state.listRevenue?.length + 6 },
        e: { c: 4, r: this.state.listRevenue?.length + 6 },
      },
      {
        s: { c: 0, r: this.state.listRevenue?.length + 7 },
        e: { c: 5, r: this.state.listRevenue?.length + 7 },
      },
      {
        s: { c: 0, r: this.state.listRevenue?.length + 8 },
        e: { c: 3, r: this.state.listRevenue?.length + 8 },
      },
      {
        s: { c: 4, r: this.state.listRevenue?.length + 8 },
        e: { c: 5, r: this.state.listRevenue?.length + 8 },
      },
    ];

    // Gộp các ô
    ws["!merges"] = mergePositions;

    return ws;
  };

  timestampToDateString = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  timestampToMonthString = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${month}`;
  };

  timestampToYearString = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();

    return `${year}`;
  };

  changeDateToDay = (date) => {
    const [month, year] = this.state.certificationMonth?.month.split("/");
    var data_ = new Date(
      new Date(`${year}-${month}-01`).getFullYear(),
      new Date(`${year}-${month}-01`).getMonth(),
      date
    );
    var daysOfWeek = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

    var rs = daysOfWeek[data_.getDay()];

    return rs;
  };

  CheckRes(numberdate, arrRes, startMonth) {
    let count = 0;
    for (var i = 0; i < arrRes?.length; i++) {
      if (
        new Date(arrRes[i].date * 1000).getDate() == numberdate &&
        new Date(arrRes[i].date * 1000).getMonth() == startMonth?.getMonth() &&
        new Date(arrRes[i].date * 1000).getFullYear() ==
          startMonth?.getFullYear()
      ) {
        count++;
      }
    }

    return count;
  }

  async exportPdfBroadcast() {
    const element = document.getElementById("exportBroadcastCertificationPdf");

    const clonedElement = element.cloneNode(true);

    clonedElement.classList.remove("d-none");

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `CNPS_${this.state.broadcastCertification?.contractCode?.replaceAll(
        "/",
        "."
      )}.pdf`,

      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "landscape",
      },
      html2canvas: {
        dpi: 1000,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      image: { type: "jpeg", quality: 1 },
    };

    await html2pdf().from(clonedElement).set(opt).save();

    clonedElement.remove();
  }

  showDate = (month) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });
    this.setState({ listDate: viewDate });
    let arr = this.state.broadcastCertification?.blockList;

    arr?.map((item) => {
      let arrRes = [{ numberdate: 0, date: 0 }];

      viewDate?.map((date) => {
        arrRes?.push({
          numberdate: date.label,
          date:
            this.CheckRes(date.label, item?.broadcastDateList, month) > 0
              ? this.CheckRes(date.label, item?.broadcastDateList, month)
              : "",
        });
        item.arrDate = [...arrRes.filter((i) => i.numberdate > 0)];
      });
    });

    // let a = dataInput
    this.setState(
      {
        blockListForCertification: arr,
      },
      () => this.exportCNPS()
    );
  };

  showDatePdf = (month) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });
    this.setState({ listDate: viewDate });
    let arr = this.state.broadcastCertification?.blockList;

    arr?.map((item) => {
      let arrRes = [{ numberdate: 0, date: 0 }];

      viewDate?.map((date) => {
        arrRes?.push({
          numberdate: date.label,
          date:
            this.CheckRes(date.label, item?.broadcastDateList, month) > 0
              ? this.CheckRes(date.label, item?.broadcastDateList, month)
              : "",
        });
        item.arrDate = [...arrRes.filter((i) => i.numberdate > 0)];
      });
    });
    this.setState(
      {
        blockListForCertification: arr,
      },
      () => this.exportPdfBroadcast()
    );
  };
  closeMenu = () => {
    this.setState({ showMenu: false });
  };
  renderClassList() {
    var tong = 0;
    return (
      <>
        <Card.Header className=" cardheaderfix bg-light">
          {/* {new Check().permission(['13']) ? */}
          <Card.Title
            className="d-flex "
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <i className="fas fa-list me-1"></i> Hợp đồng{" "}
              {this.props.page == "notification" ? "thông báo" : "quảng cáo"}
            </div>
            {/* {console.log(document.getElementById('buttonContract')?.offsetWidth)} */}
            <div id="buttonMobile">
              <Button
                onClick={() => this.setState({ showMenu: true })}
                variant="secondary"
              >
                Hiển thị thao tác
              </Button>
            </div>
            <div id="buttonContract">
              {new Check().permission(["47"]) ? (
                <Button
                  type="button"
                  variant="success"
                  size="sm"
                  className="mt-1"
                  id="buttonContractNone"
                  style={{ float: "right" }}
                  onClick={() => {
                    this.setState({
                      _class: [],
                      show: true,
                      type: "TM",
                      contractitem: {
                        id: "",
                        contractCode: "",
                        createdDate: new Date() / 1000,
                        effectiveDate: new Date() / 1000,
                        expirationDate: 0,
                        companyId: 1,
                        percentDiscount: 0,
                        contractTypeId: 0,
                        contractValue: 0,
                        paymentType: 1,
                        note: "",
                        customer: {
                          id: 0,
                          shortName: "",
                          name: "",
                          address: "",
                          phoneNumber: "",
                          faxNumber: "",
                          taxCode: "",
                          customerTypeId: null,
                        },
                        bankCustomer: {
                          id: 0,
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          customerId: 1,
                          note: "",
                        },
                        bankCompany: {
                          id: 0,
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          companyId: 1,
                          note: "note",
                        },
                        representativeCustomer: {
                          id: 0,
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                          customerId: 0,
                        },
                        representativeCompany: {
                          id: 0,
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                          companyId: 1,
                        },
                      },
                    });
                  }}
                >
                  <i className="fa-solid fa-plus"></i> Thêm
                </Button>
              ) : null}
              {new Check().permission(["48"]) ? (
                <Button
                  variant="warning"
                  title="Cập nhật"
                  style={{ float: "right" }}
                  size="sm"
                  id="buttonContractNone"
                  className="ms-2 mt-1"
                  disabled={!this.state.selectedRows[0]?.item}
                  onClick={() => {
                    this.setState({
                      type: "CN",
                      show: true,
                    });
                    this.setState({
                      contractitem: this.state.selectedRows[0]?.item,
                    });
                  }}
                >
                  <i className="fa-solid fa-pencil"></i> Sửa
                </Button>
              ) : null}
              {new Check().permission(["49"]) ? (
                <Button
                  variant="danger"
                  style={{ float: "right" }}
                  title="Xóa"
                  className="ms-2 me-2 mt-1"
                  size="sm"
                  id="buttonContractNone"
                  disabled={!this.state.selectedRows[0]?.item}
                  onClick={() => {
                    this.deleteclass(this.state.selectedRows[0]?.item);
                  }}
                  // disabled
                >
                  <i className="fas fa-trash"></i> Xóa
                </Button>
              ) : null}
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.xportLiquidationbyId()}
                className="me-2 mt-1"
                disabled={
                  this.state.dataExport?.length < 1 ||
                  this.state.selectedRows?.length == 0
                }
              >
                <i className="fa-solid fa-file-export"></i> Xuất thanh lý HĐ
              </Button>
              {this.props.page == "notification" ? (
                <>
                  <Button
                    type="button"
                    variant="success"
                    size="sm"
                    style={{ float: "right" }}
                    onClick={() => this.xportContractbyId()}
                    className="me-2 mt-1"
                    disabled={
                      this.state.dataExport?.length < 1 ||
                      this.state.selectedRows?.length == 0
                    }
                  >
                    <i className="fa-solid fa-file-export"></i> Xuất HĐ TB
                  </Button>
                  {new Check().permission(["91"]) ? (
                    <Button
                      variant="success"
                      className="me-2 mt-1"
                      title="Đăng ký phát sóng"
                      size="sm"
                      id="buttonContractNone"
                      style={{ float: "right" }}
                      onClick={() => {
                        this.getRes(this.state.selectedRows[0]?.item);
                      }}
                      disabled={!this.state.selectedRows[0]?.item}
                    >
                      <i className="fa-solid fa-list-check"></i> Đăng ký phát
                      sóng
                    </Button>
                  ) : null}
                </>
              ) : null}
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.xportRevenue()}
                className="me-2 mt-1"
                disabled={this.state.listAd?.length < 1}
              >
                <i className="fa-solid fa-file-export"></i> Xuất sổ doanh thu
              </Button>
              <Button
                type="button"
                variant="danger"
                size="sm"
                style={{ float: "right" }}
                onClick={async () => {
                  if (this.state.certificationMonth == null) {
                    return;
                  }

                  const [month, year] =
                    this.state.certificationMonth?.month.split("/");
                  const response = await axios.get(
                    `contract/broadcast-certification`,
                    {
                      params: {
                        contractId: this.state.certificationMonth.contractId,
                        month: new Date(`${year}-${month}-01`).getTime() / 1000,
                      },
                    }
                  );

                  this.setState(
                    { broadcastCertification: response.data.data },
                    () => {
                      this.showDatePdf(new Date(`${year}-${month}-01`));
                    }
                  );
                }}
                className="mt-1 me-2"
                disabled={this.state.certificationMonth == null}
                hidden={this.props.page != "notification"}
              >
                <i className="fa-solid fa-file-export"></i> Xuất CNPS (PDF)
              </Button>
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={async () => {
                  const [month, year] =
                    this.state.certificationMonth?.month.split("/");

                  const response = await axios.get(
                    `contract/broadcast-certification`,
                    {
                      params: {
                        contractId: this.state.certificationMonth.contractId,
                        month: new Date(`${year}-${month}-01`).getTime() / 1000,
                      },
                    }
                  );

                  this.setState(
                    { broadcastCertification: response.data.data },
                    () => {
                      this.showDate(new Date(`${year}-${month}-01`));
                    }
                  );
                }}
                className="mt-1 me-2"
                disabled={this.state.certificationMonth == null}
                hidden={this.props.page != "notification"}
              >
                <i className="fa-solid fa-file-export"></i> Xuất CNPS
              </Button>
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.xport()}
                className="mt-1"
                disabled={this.state.listAd?.length < 1}
              >
                <i className="fa-solid fa-file-export"></i> Xuất DSHĐ
              </Button>
              {/*  */}

              <></>
            </div>
            {/* //  : null} */}
          </Card.Title>
        </Card.Header>
        <Card.Body className="cardbodyfix">
          {this.renderSearchFilter()}
          {/* <Row>
            <Col md={10}>
            {this.state.checkload ? this.loading() : this.dataTable()}
        
            </Col>
            <Col md={2}></Col>
          </Row> */}
          {this.state.checkload ? this.loading() : this.dataTable()}
          <Table id="exportListContract" className="d-none">
            <thead>
              <tr>
                <th colSpan={9}>DANH SÁCH HỢP ĐỒNG</th>
              </tr>
              <tr>
                <th>Loại HĐ</th>
                <th>Số HĐ</th>
                <th>Khách hàng</th>

                <th>Từ ngày</th>
                <th>Đến ngày</th>

                <th>Giá trị ký kết</th>

                <th>Quyền lợi</th>

                <th>Giá trị QC</th>

                <th>Còn lại</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listcontract?.map((item) => {
                var sumValue = 0;
                item?.scheduleList?.map((e) => (sumValue += e.revenues));

                return (
                  <tr>
                    <td>{this.changeEmpty(item.contractType?.name)}</td>
                    <td>{this.changeEmpty(item.contractCode)}</td>

                    <td>{this.changeEmpty(item.customer?.name)}</td>
                    <td>
                      a{`${this.changeEmpty(this.getDate(item.effectiveDate))}`}
                    </td>
                    <td>
                      a{this.changeEmpty(this.getDate(item.expirationDate))}
                    </td>
                    <td>
                      {this.changeEmpty(
                        parseInt(
                          item.contractValue *
                            (1 - Number(item.percentDiscount) / 100)
                        )
                      )}
                    </td>
                    <td>
                      {this.changeEmpty(
                        parseInt(
                          item.contractValue *
                            (1 - Number(item.percentDiscount) / 100)
                        )
                      )}
                    </td>
                    <td>{this.changeEmpty(parseInt(sumValue))}</td>
                    <td>
                      {this.changeEmpty(
                        parseInt(
                          item.contractValue *
                            (1 - Number(item.percentDiscount) / 100) -
                            sumValue
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>

                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
              </tr>
            </tbody>
          </Table>
          <Table id="exportContractbyId" className="d-none">
            <thead>
              <tr>
                <th> UBND TỈNH CÀ MAU </th>
                <th>empty</th>
                <th>empty</th>
                <th> CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th> ĐÀI PHÁT THANH TRUYỀN HÌNH</th> <th>empty</th>
                <th>empty</th>
                <th> Độc lập - Tự do - Hạnh phúc</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>Số: {this.state.dataExport?.contractCode}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>HỢP ĐỒNG THÔNG BÁO</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Căn cứ Luật Thương Mại của nước Cộng Hòa Xã Hội Chủ Nghĩa
                  Việt Nam đã được Quốc Hội nước Cộng Hòa Xã Hội Chủ Nghĩa Việt
                  Nam khóa XI, kỳ họp thứ 7 thông qua ngày 14/06/2005;
                </th>

                <th>empty</th>
                <th>empty</th>

                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th> - Căn cứ vào yêu cầu và khả năng của 2 bên;</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  Hôm nay ngày{" "}
                  {new Date(
                    this.state?.dataExport?.createdDate * 1000
                  ).getDate()}{" "}
                  tháng{" "}
                  {new Date(
                    this.state?.dataExport?.createdDate * 1000
                  ).getMonth() + 1}{" "}
                  năm{" "}
                  {new Date(
                    this.state?.dataExport?.createdDate * 1000
                  ).getFullYear()}
                </th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>

              <tr>
                {" "}
                <th>empty</th>
                <th>Bên A: {this.state.dataExport?.company?.name}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  - Do ông (bà) :{" "}
                  {this.state.dataExport?.companyRepresentativeName}
                </th>{" "}
                <th>empty</th>
                <th>
                  Chức vụ:{" "}
                  {this.state.dataExport?.companyRepresentativeRole
                    ? this.state.dataExport?.companyRepresentativeRole?.includes(
                        "làm đại diện"
                      )
                      ? this.state.dataExport?.companyRepresentativeRole
                      : this.state.dataExport?.companyRepresentativeRole +
                        " làm đại diện"
                    : " làm đại diện"}
                </th>{" "}
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>- Địa chỉ : {this.state.dataExport?.companyAddress}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Điện thoại : {this.state.dataExport?.companyPhoneNumber}
                </th>
                <th>empty</th>
                <th>
                  Mã số thuế : {this.state.dataExport?.companyTaxCode}
                </th>{" "}
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  - Tài khoản : {this.state.dataExport?.companyBankNumber} tại{" "}
                  {this.state.dataExport?.companyBankName}
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>Bên B: {this.state.dataExport?.customer?.name}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>{" "}
                <th>
                  - Do ông (bà) :{" "}
                  {this.state.dataExport?.customerRepresentativeName}
                </th>
                <th>empty</th>{" "}
                <th>
                  Chức vụ:{" "}
                  {this.state.dataExport?.customerRepresentativeRole
                    ? this.state.dataExport?.customerRepresentativeRole?.includes(
                        "làm đại diện"
                      )
                      ? this.state.dataExport?.customerRepresentativeRole
                      : this.state.dataExport?.customerRepresentativeRole +
                        " làm đại diện"
                    : " làm đại diện"}
                </th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>- Địa chỉ : {this.state.dataExport?.customerAddress}</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Điện thoại : {this.state.dataExport?.customerPhoneNumber}
                </th>
                <th>empty</th>
                <th>
                  Mã số thuế : {this.state.dataExport?.customerTaxCode}
                </th>{" "}
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>

              <tr>
                {" "}
                <th>empty</th>
                <th>Điều 1: Trách nhiệm của 2 bên</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  - Bên A: Có trách nhiệm đọc và phát nội dung thông báo trên
                  Đài PT-TH Cà Mau theo đúng lịch như điều 2. Nếu bên A đọc sai
                  nội dung hoặc phát sai lịch thì sẽ phát bù.
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Bên B: Có trách nhiệm cung cấp nội dung thông báo và các
                  giấy tờ cần thiết liên quan đến nội dung thông báo theo quy
                  định của Nhà nước.
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  Điều 2: Giá trị hợp đồng lịch phát và phương thức thanh toán
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>Trên mục</th>
                <th>empty</th>
                <th>Nội dung</th>
                <th>empty</th>
                <th>Số lần</th>
                <th>Đơn giá</th>

                <th>Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              {}
              {this.mergeBroadcastDate(this.state.dataExport?.blockList)?.map(
                (item, index) => {
                  return (
                    <tr>
                      <td>{this.changeEmpty(item.adPrice?.name)}</td>
                      <th>empty</th>
                      <td>{this.changeEmpty(item.content)}</td>
                      <th>empty</th>
                      <td>
                        {this.changeEmpty(item.broadcastDateList?.length)}
                      </td>
                      <td>{this.changeEmpty(item.realPrice)}</td>
                      <td>
                        {this.changeEmpty(
                          parseInt(item.realPrice) *
                            item.broadcastDateList?.length
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
              <tr>
                <td>Tổng cộng</td>
                <td>empty</td>
                <th>empty</th>
                <td>empty</td>
                <td>
                  {this.changeEmpty(this.getCountvalue(this.state.dataExport))}
                </td>

                <th>empty</th>
                <td>
                  {this.changeEmpty(this.getSumvalue(this.state.dataExport))}
                </td>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  Bằng chữ :{" "}
                  {this.capitalizeFirstLetter(
                    this.soThanhChu(this.getSumvalue(this.state.dataExport))
                  )}
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Lịch phát sóng:{" "}
                  {this.ConvertBlockList(this.state.dataExport?.blockList)}
                  {/* {this.ConvertBlockList(
                    this.mergeBroadcastDate(this.state.dataExport?.blockList)
                  )} */}
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Phương thức thanh toán:{" "}
                  {this.state.dataExport?.paymentType == 1
                    ? "Bằng tiền mặt"
                    : this.state.dataExport?.paymentType == 2
                    ? "Chuyển khoản"
                    : "Bằng tiền mặt hoặc chuyển khoản"}{" "}
                  trước khi phát sóng
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>Điều 3: Điều khoản chung</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  - Hai bên có trách nhiệm thi hành các điều khoản của hợp đồng.
                  Trong quá trình thực hiện nếu có phát sinh vướng mắc thì hai
                  bên cùng nhau giải quyết. Trường hợp không giải quyết được thì
                  2 bên thống nhất đưa đến tòa án kinh tế giải quyết. Mọi phí
                  tổn về khiếu kiện sẽ do bên có lỗi chịu.
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  {" "}
                  - Hợp đồng này có hiệu lực từ ngày ký và tự thanh lý khi các
                  bên hoàn thành nghĩa vụ đã thỏa thuận trong hợp đồng.
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  {" "}
                  - Hợp đồng được thành lập thành 04 bản mỗi bên giữ 02 bản có
                  giá trị như nhau.
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>ĐẠI DIỆN BÊN A</th>
                <th>empty</th>
                <th>empty</th>
                <th>ĐẠI DIỆN BÊN B</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>{" "}
              <tr>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th colSpan={7}></th>
              </tr>
              <tr>
                <th colSpan={7}></th>
              </tr>{" "}
              <tr>
                <th colSpan={7}></th>
              </tr>
              <tr>
                <th></th>
                <th>{this.state.dataExport?.companyRepresentativeName}</th>
                <th></th>
                <th></th>
                <th>{this.state.dataExport?.customerRepresentativeName}</th>
                <th></th> <th></th>
              </tr>
            </tbody>
          </Table>
          <Table id="exportlistRevenueExport" className="d-none">
            <thead>
              <tr>
                <th> UBND TỈNH CÀ MAU </th>
                <th>empty</th>
                <th>empty</th>
                <th> CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM </th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th> ĐÀI PHÁT THANH TRUYỀN HÌNH</th> <th>empty</th>
                <th>empty</th>
                <th> Độc lập - Tự do - Hạnh phúc</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>
                  SỔ DOANH THU{" "}
                  {this.props.page == "notification"
                    ? "THÔNG BÁO"
                    : "QUẢNG CÁO"}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>
                  {" "}
                  {this.state.startDate == ""
                    ? ""
                    : "Từ " +
                      this.getDate(this.state.startDate / 1000) +
                      " đến " +
                      this.getDate(this.state.endDate / 1000)}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>STT</th>
                <th>SỐ HĐ</th>
                <th>NGÀY</th>
                <th>KHÁCH HÀNG</th>
                <th>NỘI DUNG HỢP ĐỒNG</th>
                <th>GIÁ TRỊ HĐ</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listRevenue?.map((item, index) => {
                return (
                  <tr>
                    <td>{this.changeEmpty(index + 1)}</td>
                    <td>{this.changeEmpty(item.contractCode)}</td>
                    <td>
                      {"a" + this.changeEmpty(this.getDate(item.effectiveDate))}
                    </td>
                    <td>{this.changeEmpty(item.customer?.name)}</td>{" "}
                    <td>{this.changeEmpty(item.contentList[0])}</td>{" "}
                    <td classname="text-end">
                      {this.changeEmpty(item.totalPrice)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>{this.changeEmpty(this.sumValue())}</td>
              </tr>
              <tr>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <td>Phòng TC-HC và Dịch vụ </td>
                <th>empty</th>
              </tr>
            </tbody>
          </Table>

          <Table
            striped
            bordered
            style={{ border: "1px solid black" }}
            id="exportBroadcastCertification"
            className="d-none"
          >
            <tr>
              <th>UBND TỈNH CÀ MAU</th>
            </tr>
            <tr>
              <th>ĐÀI PHÁT THANH - TRUYỀN HÌNH TỈNH CÀ MAU</th>
            </tr>
            <tr>
              <th>----oOo----</th>
            </tr>
            <tr>
              <th>CHỨNG NHẬN PHÁT SÓNG</th>
            </tr>
            <tr></tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                - Kèm theo hợp đồng số:{" "}
                {this.state.broadcastCertification?.contractCode}{" "}
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                {" "}
                Ngày:{" "}
                {this.timestampToDateString(
                  this.state.broadcastCertification?.createdDate
                )}
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                - Đài Phát Thanh - Truyền Hình Cà Mau đã phát sóng QC cho:{" "}
                {this.state.broadcastCertification?.customerName}{" "}
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                Từ tháng: {this.state.certificationMonth?.month.split("/")[0]}
              </th>
              <th></th>
              <th></th>
              <th>
                Đến tháng: {this.state.certificationMonth?.month.split("/")[0]}{" "}
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Năm: {this.state.certificationMonth?.month.split("/")[1]}</th>
            </tr>
            <tr></tr>
            <tr>
              <th>Nội dung TB</th>
              <th>Trên</th>
              <th>SL</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
              
              {Array.from({ length: 
           this.soNgayTrongThang(this.state.monthCheck)
            
            }, (_, index) => (
                <th key={index}>{index + 1}</th>
              ))}
            </tr>
            {this.state.blockListForCertification?.map((item) => {
              return (
                <tr>
                  <td>{item.content}</td>
                  <td>{item.adPrice?.name}</td>
                  <td>
                    {item.arrDate.reduce((sum, item) => {
                      if (item.date != "") return sum + item.date;
                      else return sum;
                    }, 0)}
                  </td>
                  <td className="text-end">{parseInt(item?.realPrice)}</td>
                  <td className="text-end">
                    {parseInt(
                      item.arrDate.reduce((sum, item) => {
                        if (item.date != "") return sum + item.date;
                        else return sum;
                      }, 0) * item?.realPrice
                    )}
                  </td>
                  {Array.from({ length:    this.soNgayTrongThang(this.state.monthCheck) }, (_, index) => (
                    <td key={index}>
                      {item.arrDate[index] && item.arrDate[index].date != 0
                        ? item.arrDate[index].date
                        : "empty"}
                    </td>
                  ))}
                </tr>
              );
            })}
            <tr></tr>
            <tr>
              <th></th>
              <th>TỔNG GIÁ TRỊ:</th>
              <th></th>
              <th></th>
              <th>
                {parseInt(
                  this.totalValueBroadcastCertification(
                    this.state.blockListForCertification
                  )
                )}
              </th>
            </tr>
            <tr>
              <th></th>
              <th>GIẢM GIÁ THEO HĐ:</th>
              <th></th>
              <th></th>
              <th>{this.state.broadcastCertification?.percentDiscount}</th>
            </tr>
            <tr>
              <th></th>
              <th>SỐ TIỀN THANH TOÁN:</th>
              <th></th>
              <th></th>
              <th>
                {this.totalValueBroadcastCertification(
                  this.state.blockListForCertification
                ) -
                  (this.state.broadcastCertification?.percentDiscount *
                    this.totalValueBroadcastCertification(
                      this.state.blockListForCertification
                    )) /
                    100}
              </th>
            </tr>
            <tr>
              <th>
                Bằng chữ:{" "}
                {this.capitalizeFirstLetter(
                  this.soThanhChu(
                    this.totalValueBroadcastCertification(
                      this.state.blockListForCertification
                    ) -
                      (this.state.broadcastCertification?.percentDiscount *
                        this.totalValueBroadcastCertification(
                          this.state.blockListForCertification
                        )) /
                        100
                  )
                )}
              </th>
            </tr>
            <tr></tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                Cà Mau, ngày{" "}
                {new Date().getDate() > 9
                  ? new Date().getDate()
                  : "0" + new Date().getDate()}{" "}
                tháng{" "}
                {new Date().getMonth() + 1 > 9
                  ? new Date().getMonth() + 1
                  : "0" + (new Date().getMonth() + 1)}{" "}
                năm {new Date().getFullYear()}
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>ĐẠI DIỆN BÊN A</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>NGƯỜI LẬP BẢNG</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>ĐẠI DIỆN BÊN B</th>
              <th></th>
              <th></th>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>{localStorage.getItem("auth_name_ctv")}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </Table>
          <Table id="exportContractLiquidationbyId" className="d-none">
            <thead>
              <tr>
                <th> UBND TỈNH CÀ MAU </th>
                <th>empty</th>
                <th>empty</th>
                <th> CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM </th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th> ĐÀI PHÁT THANH TRUYỀN HÌNH</th> <th>empty</th>
                <th>empty</th>
                <th> Độc lập - Tự do - Hạnh phúc</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>Số: {this.state.dataExport?.contractCode}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>BIÊN BẢN THANH LÝ HỢP ĐỒNG</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Căn cứ hợp đồng số : {this.state.dataExport?.contractCode}{" "}
                  ký ngày {this.getDate(this.state.dataExport?.createdDate)}
                </th>

                <th>empty</th>
                <th>empty</th>

                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  Hôm nay ngày {new Date().getDate()} tháng{" "}
                  {new Date().getMonth() + 1} năm {new Date().getFullYear()}
                </th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>Bên A: {this.state.dataExport?.company?.name}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  - Do ông (bà) :{" "}
                  {this.state.dataExport?.companyRepresentativeName}
                </th>{" "}
                <th>empty</th>
                <th>
                  {" "}
                  Chức vụ :{" "}
                  {this.state.dataExport?.companyRepresentativeRole
                    ? this.state.dataExport?.companyRepresentativeRole?.includes(
                        "làm đại diện"
                      )
                      ? this.state.dataExport?.companyRepresentativeRole
                      : this.state.dataExport?.companyRepresentativeRole +
                        " làm đại diện"
                    : " làm đại diện"}
                </th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>- Địa chỉ : {this.state.dataExport?.companyAddress}</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Điện thoại : {this.state.dataExport?.companyPhoneNumber}
                </th>
                <th>empty</th>
                <th>
                  Mã số thuế : {this.state.dataExport?.companyTaxCode}
                </th>{" "}
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>- Mã số thuế : {this.state.dataExport?.companyTaxCode}</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  - Tài khoản : {this.state.dataExport?.companyBankNumber} tại{" "}
                  {this.state.dataExport?.companyBankName}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>Bên B: {this.state.dataExport?.customer?.name}</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>{" "}
                <th>
                  - Do ông (bà) :{" "}
                  {this.state.dataExport?.customerRepresentativeName}
                </th>{" "}
                <th>empty</th>
                <th>
                  Chức vụ :{" "}
                  {this.state.dataExport?.customerRepresentativeRole
                    ? this.state.dataExport?.customerRepresentativeRole?.includes(
                        " làm đại diện"
                      )
                      ? this.state.dataExport?.customerRepresentativeRole
                      : this.state.dataExport?.customerRepresentativeRole +
                        " làm đại diện"
                    : ""}
                </th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>- Địa chỉ : {this.state.dataExport?.customerAddress}</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Điện thoại : {this.state.dataExport?.customerPhoneNumber}
                </th>
                <th>empty</th>
                <th>
                  Mã số thuế : {this.state.dataExport?.customerTaxCode}
                </th>{" "}
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>- Mã số thuế : {this.state.dataExport?.customerTaxCode}</th>
                <th>empty</th>
                <th>empty</th> <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  - Tài khoản : {this.state.dataExport?.customerBankNumber} tại{" "}
                  {this.state.dataExport?.customerBankName}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                {" "}
                <th>empty</th>
                <th>
                  Hai bên cùng nhau tiến hành thanh lý hợp đồng số :{" "}
                  {this.state.dataExport?.contractCode} ngày{" "}
                  {this.getDate(this.state.dataExport?.createdDate)}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  1. Bên A đã thực hiện đúng theo các điều khoản mà hợp đồng đã
                  ghi, đảm bảo chất lượng, đạt yêu cầu
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  2. Bên B đã thanh toán cho bên A số tiền là:{" "}
                  {this.formatNumber(
                    this.state.dataExport?.contractValue -
                      (this.state.dataExport?.contractValue *
                        this.state.dataExport?.percentDiscount) /
                        100
                  )}{" "}
                  đồng
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  Bằng chữ:{" "}
                  {this.capitalizeFirstLetter(
                    this.soThanhChu(
                      this.state.dataExport?.contractValue -
                        (this.state.dataExport?.contractValue *
                          this.state.dataExport?.percentDiscount) /
                          100
                    )
                  )}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  Hai bên thống nhất thanh lý hợp đồng số :{" "}
                  {this.state.dataExport?.contractCode} ngày{" "}
                  {this.getDate(this.state.dataExport?.createdDate)}
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <th>empty</th>
                <th>
                  Biên bản đã lập thành 02 (hai) bản, mỗi bên giữ 01 bản có giá
                  trị như nhau.
                </th>
                <th>empty</th>
                <th>empty</th> <th>empty</th> <th>empty</th>
              </tr>
              <tr>
                <td colSpan={6}></td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>ĐẠI DIỆN BÊN A</td>
                <td></td>

                <td colSpan={2}>ĐẠI DIỆN BÊN B</td>
              </tr>
              <tr>
                <td colSpan={6}></td>
              </tr>{" "}
              <tr>
                <td colSpan={6}></td>
              </tr>{" "}
              <tr>
                <td colSpan={6}></td>
              </tr>{" "}
              <tr>
                <td colSpan={6}></td>
              </tr>{" "}
              <tr>
                <td></td>
                <td colSpan={2}>
                  {this.state.dataExport?.companyRepresentativeName}
                </td>{" "}
                <td></td>
                <td colSpan={2}>
                  {this.state.dataExport?.customerRepresentativeName}
                </td>
              </tr>
            </thead>
          </Table>
          <div
            striped
            id="exportBroadcastCertificationPdf"
            className="border border-0 d-none"
            style={{
              fontWeight: "normal",
              fontFamily: "Times New Roman, Times, serif",
              width: "1250px",
              color: "#000",
            }}
          >
            <div
              style={{ width: "445px", fontSize: "14px" }}
              className="text-center fw-bold"
            >
              UBND TỈNH CÀ MAU
            </div>
            <div
              style={{ width: "445px", fontSize: "16px" }}
              className="text-center fw-bold"
            >
              ĐÀI PHÁT THANH - TRUYỀN HÌNH TỈNH CÀ MAU
            </div>
            <div
              style={{ width: "445px", fontSize: "14px" }}
              className="text-center fw-bold"
            >
              ----oOo----
            </div>
            <div
              style={{ width: "1094px", fontSize: "21px" }}
              className="text-center fw-bold"
            >
              CHỨNG NHẬN PHÁT SÓNG
            </div>
            <div className="d-flex">
              <div style={{ marginLeft: "245px", fontSize: "15px" }}>
                - Kèm theo hợp đồng số:{" "}
                {this.state.broadcastCertification?.contractCode}{" "}
              </div>
              <div style={{ marginLeft: "130px", fontSize: "15px" }}>
                Ngày:{" "}
                {this.timestampToDateString(
                  this.state.broadcastCertification?.createdDate
                )}
              </div>
            </div>
            <div
              style={{
                marginLeft: "245px",
                fontSize: "15px",
                width: "780px",
                textAlign: "justify",
              }}
            >
              - Đài Phát Thanh - Truyền Hình Cà Mau đã phát sóng QC cho:{" "}
              {this.state.broadcastCertification?.customerName}{" "}
            </div>
            <div
              className="d-flex"
              style={{ marginLeft: "360px", fontSize: "15px" }}
            >
              <div style={{ fontSize: "15px" }}>
                Từ tháng: {this.state.certificationMonth?.month.split("/")[0]}
              </div>
              <div style={{ marginLeft: "69px", fontSize: "15px" }}>
                Đến tháng: {this.state.certificationMonth?.month.split("/")[0]}{" "}
              </div>
              <div style={{ marginLeft: "69px", fontSize: "15px" }}>
                Năm: {this.state.certificationMonth?.month.split("/")[1]}
              </div>
            </div>
            <div></div>
            <div
              className="d-flex fw-bold text-center"
              style={{ marginTop: "24px", fontSize: "11px" }}
            >
              <div
                className=""
                style={{
                  border: "0.5px solid black",
                  width: "160px",
                  fontSize: "14px",
                }}
              >
                Nội dung TB
              </div>
              <div
                style={{
                  borderBottom: "0.5px solid black",
                  borderTop: "0.5px solid black",
                  borderRight: "0.5px solid black",
                  width: "99px",
                  fontSize: "14px",
                }}
              >
                Trên
              </div>
              <div
                style={{
                  borderBottom: "0.5px solid black",
                  borderTop: "0.5px solid black",
                  borderRight: "0.5px solid black",
                  width: "25px",
                  fontSize: "14px",
                }}
              >
                SL
              </div>
              <div
                style={{
                  borderBottom: "0.5px solid black",
                  borderTop: "0.5px solid black",
                  borderRight: "0.5px solid black",
                  width: "75px",
                  fontSize: "14px",
                }}
              >
                Đơn giá
              </div>
              <div
                style={{
                  borderBottom: "0.5px solid black",
                  borderTop: "0.5px solid black",
                  borderRight: "0.5px solid black",
                  width: "85px",
                  fontSize: "14px",
                }}
              >
                Thành tiền
              </div>
              {Array.from({ length: this.soNgayTrongThang(this.state.monthCheck) }, (_, index) => (
                <div
                  style={{
                    borderBottom: "0.5px solid black",
                    borderTop: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    width: `${19.4*31/ this.soNgayTrongThang(this.state.monthCheck)}px`,
                    fontSize: "14px",
                  }}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            {this.state.blockListForCertification?.map((item) => {
              return (
                <div class="d-flex">
                  <div
                    class="px-2 d-flex align-items-center "
                    style={{
                      width: "160px",
                      borderBottom: "0.5px solid black",
                      borderRight: "0.5px solid black",
                      borderLeft: "0.5px solid black",
                      fontSize: "14px",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item.content}
                  </div>
                  <div
                    className="px-2 d-flex align-items-center"
                    style={{
                      width: "99px",
                      borderBottom: "0.5px solid black",
                      borderRight: "0.5px solid black",
                      fontSize: "14px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {item.adPrice?.name}
                  </div>
                  <div
                    className="text-center d-flex align-items-center justify-content-center"
                    style={{
                      width: "25px",
                      borderBottom: "0.5px solid black",
                      borderRight: "0.5px solid black",
                      fontSize: "14px",
                    }}
                  >
                    {item.arrDate.reduce((sum, item) => {
                      if (item.date != "") return sum + item.date;
                      else return sum;
                    }, 0)}
                  </div>
                  <div
                    className="justify-content-end px-2 d-flex align-items-center "
                    style={{
                      width: "75px",
                      borderBottom: "0.5px solid black",
                      borderRight: "0.5px solid black",
                      fontSize: "14px",
                    }}
                  >
                    {this.formatNumber(parseInt(item?.realPrice))}
                  </div>
                  <div
                    className="justify-content-end px-2 d-flex align-items-center d-flex align-items-center"
                    style={{
                      width: "85px",
                      borderBottom: "0.5px solid black",
                      borderRight: "0.5px solid black",
                      fontSize: "14px",
                    }}
                  >
                    {this.formatNumber(
                      parseInt(
                        item.arrDate.reduce((sum, item) => {
                          if (item.date != "") return sum + item.date;
                          else return sum;
                        }, 0) * item?.realPrice
                      )
                    )}
                  </div>
                  {Array.from({ length: this.soNgayTrongThang(this.state.monthCheck) }, (_, index) => (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: `${19.4*31/ this.soNgayTrongThang(this.state.monthCheck)}px`,
                        borderBottom: "0.5px solid black",
                        borderRight: "0.5px solid black",
                        fontSize: "14px",
                      }}
                    >
                      {item.arrDate[index] && item.arrDate[index].date != 0
                        ? item.arrDate[index].date
                        : ""}
                    </div>
                  ))}
                </div>
              );
            })}
            <div></div>
            <div
              className="d-flex fw-bold"
              style={{ marginTop: "24px", fontSize: "15px" }}
            >
              <div
                className="text-end"
                style={{ width: "230px", fontSize: "15px" }}
              >
                TỔNG GIÁ TRỊ:
              </div>
              <div
                className="text-end px-2"
                style={{ width: "213px", fontSize: "15px" }}
              >
                {this.formatNumber(
                  parseInt(
                    this.totalValueBroadcastCertification(
                      this.state.blockListForCertification
                    )
                  )
                )}
              </div>
            </div>
            <div className="d-flex fw-bold">
              <div
                className="text-end"
                style={{ width: "230px", fontSize: "15px" }}
              >
                GIẢM GIÁ THEO HĐ:
              </div>
              <div
                className="text-end px-2"
                style={{ width: "213px", fontSize: "15px" }}
              >
                {this.state.broadcastCertification?.percentDiscount
                  ? `${this.state.broadcastCertification?.percentDiscount}%`
                  : ""}
              </div>
            </div>
            <div className="d-flex fw-bold">
              <div
                className="text-end"
                style={{ width: "230px", fontSize: "15px" }}
              >
                SỐ TIỀN THANH TOÁN:
              </div>
              <div
                className="text-end px-2"
                style={{ width: "213px", fontSize: "15px" }}
              >
                {this.formatNumber(
                  this.totalValueBroadcastCertification(
                    this.state.blockListForCertification
                  ) -
                    (this.state.broadcastCertification?.percentDiscount *
                      this.totalValueBroadcastCertification(
                        this.state.blockListForCertification
                      )) /
                      100
                )}
              </div>
            </div>
            <div className="fst-italic" style={{ fontSize: "14px" }}>
              Bằng chữ:{" "}
              {this.capitalizeFirstLetter(
                this.soThanhChu(
                  this.totalValueBroadcastCertification(
                    this.state.blockListForCertification
                  ) -
                    (this.state.broadcastCertification?.percentDiscount *
                      this.totalValueBroadcastCertification(
                        this.state.blockListForCertification
                      )) /
                      100
                )
              )}
            </div>
            <div
              style={{ marginLeft: "737px", fontSize: "16px" }}
              className="fst-italic"
            >
              Cà Mau, ngày{" "}
              {new Date().getDate() > 9
                ? new Date().getDate()
                : "0" + new Date().getDate()}{" "}
              tháng{" "}
              {new Date().getMonth() + 1 > 9
                ? new Date().getMonth() + 1
                : "0" + (new Date().getMonth() + 1)}{" "}
              năm {new Date().getFullYear()}
            </div>
            <div className="d-flex">
              <div
                className="text-center"
                style={{ fontSize: "16px", width: "377px" }}
              >
                ĐẠI DIỆN BÊN A
              </div>
              <div
                className="text-center"
                style={{ marginLeft: "40px", width: "230px", fontSize: "16px" }}
              >
                NGƯỜI LẬP BẢNG
              </div>
              <div
                className="text-center"
                style={{
                  marginLeft: "105px",
                  width: "230px",
                  fontSize: "16px",
                }}
              >
                ĐẠI DIỆN BÊN B
              </div>
            </div>

            <div className="d-flex" style={{ marginTop: "89px" }}>
              <div
                className="text-center"
                style={{ fontSize: "16px", width: "377px", fontSize: "16px" }}
              ></div>
              <div
                className="text-center"
                style={{ marginLeft: "40px", width: "230px", fontSize: "16px" }}
              >
                {localStorage.getItem("auth_name_ctv")}
              </div>
            </div>
          </div>
        </Card.Body>
        {this.renderClassAdd()}
      </>
    );
  }

  formcloseaddRe = () => {
    this.setState({ showDetailContract: false, detailContractItem: {} });
  };
  getSumvalue(data) {
    let sum = 0;
    let count = 0;
    // if ((data = [])) return sum;
    // else
    data?.blockList?.map((item) => {
      return (sum += item.broadcastDateList?.length * parseInt(item.realPrice));
    });
    return sum;
  }
  getCountvalue = (data) => {
    let sum = 0;
    let count = 0;
    // if ((data = [])) return count;
    // else
    data?.blockList?.map((item) => {
      return (count += Number(item.broadcastDateList?.length));
    });
    return count;
  };
  detailContract() {
    return (
      <Modal
        show={this.state.showDetailContract}
        onHide={this.formcloseaddRe}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modal-in-contract"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Hợp đồng {this.state.detailContractItem?.contractCode}
          </Modal.Title>
          {this.props.page == "notification" ? (
            <>
              <Button
                variant="success"
                className="ms-2"
                title="Đăng ký phát sóng"
                size="sm"
                onClick={() => {
                  this.getRes(this.state.detailContractItem);
                }}
              >
                <i className="fa-solid fa-list-check"></i> Đăng ký phát sóng
              </Button>
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.xportContractbyId()}
                className="ms-2"
                disabled={this.state.dataExport?.length < 1}
                hidden
              >
                <i className="fa-solid fa-file-export"></i> Xuất hợp đồng TB
              </Button>
            </>
          ) : null}
        </Modal.Header>

        <Modal.Body>
          <Table></Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              this.setState({
                showDetailContract: false,
                detailContractItem: {},
              })
            }
          >
            <i className="fa-solid fa-times"></i> Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  renderClassAdd() {
    return this.state.show ? (
      <ContractModal
        _class={this.state._class}
        listuser={this.state.listuser}
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}
        submit={this.submit}
        listcontract={this.state.listcontract}
        contractitem={this.state.contractitem}
        page={this.props.page}
        // onClick={()=>this.setState({show:false})}
      />
    ) : null;
  }
  getDate = (data) => {
    if (data) {
      const date = new Date(data * 1000);
      date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  getDateEx = (data) => {
    if (data) {
      const date = new Date(data * 1000);
      date.setHours(0, 0, 0, 0);
      return (
        "Ngày " +
        date.getDate() +
        " tháng " +
        (date.getMonth() + 1) +
        " năm " +
        date.getFullYear()
      );
    } else {
      return "";
    }
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  getDate_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}`;
  };
  getMonth_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getMonth() + 1}`;
  };
  getYear_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getFullYear()}`;
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  formCloseRe = () => {
    this.setState({ showRes: false });
    this.getlistclass();
  };

  updateItem(newState) {
    this.setState({ itemShedule: newState });
  }
  render() {
    return (
      <>
        <Container fluid>
          {this.renderSelectedRows()}
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>
              {this.props.page == "notification" ? "Thông báo" : "Quảng cáo"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Hợp đồng</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="mb-4">{this.renderClassList()}</Card>
          {this.state.showDetailContract && this.state.dataExport
            ? this.detailContract()
            : null}
          {this.state.showRes ? (
            <>
              <RegisterBlockFormModal
                show={this.state.showRes}
                onHide={this.formCloseRe}
                modal={{
                  title: "Đăng ký lịch",
                  button: (
                    <>
                      <i className="fa-solid fa-check" /> Lưu
                    </>
                  ),
                }}
                onUpdateItem={this.updateItem}
                datarole={this.state.itemShedule}
                submit={this.formCloseRe}
              />
            </>
          ) : null}
        </Container>
        <Offcanvas
          show={this.state.showMenu}
          onHide={this.closeMenu}
          className="menu-contract"
          placement="top"
        >
          {/* here */}
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Thao tác</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="d-grid">
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.xport()}
              className="mt-1 me-2"
              disabled={this.state.listAd?.length < 1}
            >
              <i className="fa-solid fa-file-export"></i> Xuất DSHĐ
            </Button>
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={async () => {
                const [month, year] =
                  this.state.certificationMonth?.month.split("/");

                const response = await axios.get(
                  `contract/broadcast-certification`,
                  {
                    params: {
                      contractId: this.state.certificationMonth.contractId,
                      month: new Date(`${year}-${month}-01`).getTime() / 1000,
                    },
                  }
                );

                this.setState(
                  { broadcastCertification: response.data.data },
                  () => {
                    this.showDate(new Date(`${year}-${month}-01`));
                  }
                );
              }}
              className="mt-1 me-2"
              disabled={this.state.certificationMonth == null}
              hidden={this.props.page != "notification"}
            >
              <i className="fa-solid fa-file-export"></i> Xuất CNPS
            </Button>
            <Button
              type="button"
              variant="danger"
              size="sm"
              style={{ float: "right" }}
              onClick={async () => {
                if (this.state.certificationMonth == null) {
                  return;
                }

                const [month, year] =
                  this.state.certificationMonth?.month.split("/");
                const response = await axios.get(
                  `contract/broadcast-certification`,
                  {
                    params: {
                      contractId: this.state.certificationMonth.contractId,
                      month: new Date(`${year}-${month}-01`).getTime() / 1000,
                    },
                  }
                );

                this.setState(
                  { broadcastCertification: response.data.data },
                  () => {
                    this.showDatePdf(new Date(`${year}-${month}-01`));
                  }
                );
              }}
              className="mt-1 me-2"
              disabled={this.state.certificationMonth == null}
              hidden={this.props.page != "notification"}
            >
              <i className="fa-solid fa-file-export"></i> Xuất CNPS (PDF)
            </Button>
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.xportRevenue()}
              className="me-2 mt-1"
              disabled={this.state.listAd?.length < 1}
            >
              <i className="fa-solid fa-file-export"></i> Xuất sổ doanh thu
            </Button>
            {this.props.page == "notification" ? (
              <>
                <Button
                  variant="success"
                  className="me-2 mt-1"
                  title="Đăng ký phát sóng"
                  size="sm"
                  id="buttonContractNone"
                  style={{ float: "right" }}
                  onClick={() => {
                    this.getRes(this.state.selectedRows[0]?.item);
                  }}
                  disabled={!this.state.selectedRows[0]?.item}
                >
                  <i className="fa-solid fa-list-check"></i> Đăng ký phát sóng
                </Button>

                <Button
                  type="button"
                  variant="success"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={() => this.xportContractbyId()}
                  className="me-2 mt-1"
                  disabled={
                    this.state.dataExport?.length < 1 ||
                    this.state.selectedRows?.length == 0
                  }
                >
                  <i className="fa-solid fa-file-export"></i> Xuất HĐ TB
                </Button>
              </>
            ) : null}
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.xportLiquidationbyId()}
              className="me-2 mt-1"
              disabled={
                this.state.dataExport?.length < 1 ||
                this.state.selectedRows?.length == 0
              }
            >
              <i className="fa-solid fa-file-export"></i> Xuất thanh lý HĐ
            </Button>
            <>
              <Button
                variant="danger"
                style={{ float: "right" }}
                title="Xóa"
                className="ms-2 me-2 mt-1"
                size="sm"
                id="buttonContractNone"
                disabled={!this.state.selectedRows[0]?.item}
                onClick={() => {
                  this.deleteclass(this.state.selectedRows[0]?.item);
                }}
                // disabled
              >
                <i className="fas fa-trash"></i> Xóa
              </Button>
              <Button
                variant="warning"
                title="Cập nhật"
                style={{ float: "right" }}
                size="sm"
                id="buttonContractNone"
                className="ms-2 mt-1"
                disabled={!this.state.selectedRows[0]?.item}
                onClick={() => {
                  this.setState({
                    type: "CN",
                    show: true,
                  });
                  this.setState({
                    contractitem: this.state.selectedRows[0]?.item,
                  });
                }}
              >
                <i className="fa-solid fa-pencil"></i> Sửa
              </Button>{" "}
            </>
            <Button
              type="button"
              variant="success"
              size="sm"
              className="mt-1"
              id="buttonContractNone"
              style={{ float: "right" }}
              onClick={() => {
                this.setState({
                  _class: [],
                  show: true,
                  type: "TM",
                  contractitem: {
                    id: "",
                    contractCode: "",
                    createdDate: new Date() / 1000,
                    effectiveDate: new Date() / 1000,
                    expirationDate: new Date() / 1000,
                    companyId: 1,
                    percentDiscount: 0,
                    contractTypeId: 0,
                    contractValue: 0,
                    paymentType: 1,
                    note: "",
                    customer: {
                      id: 0,
                      shortName: "",
                      name: "",
                      address: "",
                      phoneNumber: "",
                      faxNumber: "",
                      taxCode: "",
                      customerTypeId: null,
                    },
                    bankCustomer: {
                      id: 0,
                      bankName: "",
                      bankNumber: "",
                      cardOwner: "",
                      address: "",
                      customerId: 1,
                      note: "",
                    },
                    bankCompany: {
                      id: 0,
                      bankName: "",
                      bankNumber: "",
                      cardOwner: "",
                      address: "",
                      companyId: 1,
                      note: "note",
                    },
                    representativeCustomer: {
                      id: 0,
                      name: "",
                      role: "",
                      phoneNumber: "",
                      email: "",
                      customerId: 0,
                    },
                    representativeCompany: {
                      id: 0,
                      name: "",
                      role: "",
                      phoneNumber: "",
                      email: "",
                      companyId: 1,
                    },
                  },
                });
              }}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
// export default withRouter(Contract);
export default Contract;
