import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import GroupCreate from "../category_customer/GroupCreate";
import CategoryContractCreate from "../category_contract/CategoryContractCreate";
import Check from "../other/Check";
// import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DataTables from "../datatable/DataTables";
import CustommerModal from "../customer/CustommerModal";
import CustomerEditModal from "../customer/CustomerEditModal";
class ContractModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      type: "",
      contract: {},
      litscustomer: [],
      company: [],
      dataBank: this.props.contractitem?.bankCompany,
      bankList: {
        address: "",
        bankName: "",
        bankNumber: "",
        cardOwner: "",
      },
      ReList: {
        name: "",
        email: "",
        phoneNumber: "",
        role: "",
      },
      representativeList: [],
      selectedOption: null,
      listcontractType: [],
      datatest: { id: "", role_name: "" },
      bankListCompany: [],
      showModalBankList: false,
      showAddBank: false,
      // dataBank: {},
      showModalRepresentative: false,

      dataRe: this.props.contractitem?.representativeCompany,
      dataCus: this.props.contractitem?.customer,
      showAddRe: false,
      showCus: false,
      listtypecustomer: [],
      typeModal: "",
      modalAddCustomer: false,
      dataBankCus: this.props.contractitem?.bankCustomer,

      dataReCus: this.props.contractitem?.representativeCustomer,
      dataInput: { ...this.props.dataContract },
      contractitem: { ...this.props.contractitem },
      createDate: this.props.contractitem?.createdDate
        ? new Date(this.props.contractitem?.createdDate * 1000)
        : new Date(),
      effectiveDate: this.props.contractitem?.effectiveDate
        ? new Date(this.props.contractitem?.effectiveDate * 1000)
        : new Date(),
      expirationDate: this.props.contractitem?.expirationDate
        ? new Date(this.props.contractitem?.expirationDate * 1000)
        : new Date(),
      isCashSelected: this.props.contractitem?.paymentType
        ? this.props.contractitem?.paymentType
        : 3,
      listUser: [],
      err: {},
      modaleditCustomer: false,
      customerbyID: {},
      keyword: "",
    };
  }
  componentDidMount() {
    let arr = {
      bankName: this.props.contractitem?.companyBankName,
      bankNumber: this.props.contractitem?.companyBankNumber,
      address: this.props.contractitem?.companyBankAddress,
    };
    let arr1 = {
      name: this.props.contractitem?.companyRepresentativeName,
      email: "",
      phoneNumber: "",
      role: this.props.contractitem?.companyRepresentativeRole,
    };
    let arr2 = {
      name: this.props.contractitem?.customerName,
      shortName: this.props.contractitem?.customer?.shortName,
      address: this.props.contractitem?.customerAddress,
      phoneNumber: this.props.contractitem?.customerPhoneNumber,
      id: this.props.contractitem?.customerId,
      taxCode: this.props.contractitem?.customerTaxCode,
      faxNumber: this.props.contractitem?.customerFaxNumber,
      representativeList: this.props.contractitem?.customer?.representativeList,
      bankList: this.props.contractitem?.customer?.bankList,
    };
    let arr3 = {
      bankName: this.props.contractitem?.customerBankName,
      bankNumber: this.props.contractitem?.customerBankNumber,
      address: this.props.contractitem?.customerBankAddress,
    };
    let arr4 = {
      name: this.props.contractitem?.customerRepresentativeName,
      email: "",
      phoneNumber: "",
      role: this.props.contractitem?.customerRepresentativeRole,
    };

    this.setState(
      {
        dataBank: arr,
        dataRe: arr1,
        dataCus: arr2,
        dataBankCus: arr3,
        dataReCus: arr4, 
         expirationDate: this.props.contractitem?.expirationDate
        ? new Date(this.props.contractitem?.expirationDate * 1000)
        : this.changeEndMonth(new Date())
        ,
      },
      () => {
        this.getlistcustomer();
        this.getCompany();
        this.getContractType();
        this.getCustomerType();
        this.getAllUser();
      }
    );

    // this.setState({dataCus: this.state.litscustomer?.filter(e=>e.id==this.state.contractitem?.customer?.id )[0]})
  }
   changeEndMonth = (date) => {
    return new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59));
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.show != prevProps.show) {
      this.getContractType();
    }
  }
  getAllUser = () => {
    axios.get(`/user`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ listUser: res.data.data });
      }
    });
  };
  functionsum(a, b) {
    return a + b;
  }
  getCustomerType = () => {
    axios.get(`/customer-type`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ listtypecustomer: res.data.data });
      }
    });
  };
  getContractType = () => {
    axios.get(`/contract-type`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ listcontractType: res.data.data });
      }
    });
  };
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.contractitem };
    dataInput[field] = value;
    this.setState({ contractitem: dataInput });
    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };
  handleInputCompany = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.company };
    dataInput[field] = value;

    this.setState({ company: dataInput });
    if (field == "faxNumber") delete this.state.err["companyFaxNumber"];
    if (field == "address") delete this.state.err["companyAddress"];
    if (field == "taxCode") delete this.state.err["companyTaxCode"];
    if (field == "phoneNumber") delete this.state.err["companyPhoneNumber"];
  };
  handleInputdataBank = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataBank };
    dataInput[field] = value;
    this.setState({ dataBank: dataInput });
    if (field == "bankName") delete this.state.err["companyBankName"];
    if (field == "bankNumber") delete this.state.err["companyBankNumber"];
    if (field == "address") delete this.state.err["companyBankAddress"];
  };

  handleInputdataRe = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataRe };
    dataInput[field] = value;
    this.setState({ dataRe: dataInput });
    if (field == "name") delete this.state.err["companyRepresentativeName"];
    if (field == "role") delete this.state.err["companyRepresentativeRole"];
  };
  handleInputdataCus = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataCus };
    dataInput[field] = value;

    this.setState({ dataCus: dataInput });
    if (field == "faxNumber") delete this.state.err["customerFaxNumber"];
    if (field == "address") delete this.state.err["customerAddress"];
    if (field == "taxCode") delete this.state.err["customerTaxCode"];
    if (field == "phoneNumber") delete this.state.err["customerPhoneNumber"];
  };
  handleInputdataBankCus = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataBankCus };
    dataInput[field] = value;

    this.setState({ dataBankCus: dataInput });
    if (field == "bankName") delete this.state.err["customerBankName"];
    if (field == "bankNumber") delete this.state.err["customerBankNumber"];
    if (field == "address") delete this.state.err["customerBankAddress"];
  };
  handleInputdataReCus = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataReCus };
    dataInput[field] = value;
    this.setState({ dataReCus: dataInput });
    if (field == "name") delete this.state.err["customerRepresentativeName"];
    if (field == "role") delete this.state.err["customerRepresentativeRole"];
  };

  getlistcustomer = () => {
    var arr = [];
    arr.push({
      value: 0,
      label: "Chọn khách hàng",
    });
    axios
      .get(`/customer?page=1&limit=100`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ checkload: false });

          this.setState({ litscustomer: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createclass() {}

  updateclass() {}
  getCompany = () => {
    axios.get(`/company/1`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        if (!this.props.contractitem?.id)
          this.setState({ company: res.data.data });
        else {
          let arrT = {
            createdAt: res.data.data?.createdAt,
            createdBy: res.data.data?.createdAt,
            faxNumber: this.props.contractitem?.companyFaxNumber,
            id: res.data.data?.id,
            isPublic: res.data.data?.isPublic,
            name: res.data.data?.name,
            phoneNumber: this.props.contractitem?.companyPhoneNumber,
            representativeList: res.data.data?.representativeList,
            taxCode: this.props.contractitem?.companyTaxCode,
            updatedAt: res.data.data?.updatedAt,
            updatedBy: res.data.data?.updatedBy,
            bankList: res.data.data?.bankList,
            address: this.props.contractitem?.companyAddress,
          };
          this.setState({ company: arrT });
        }

        let arr = res.data.data.bankList;
        this.setState({ bankListCompany: arr });
        this.setState({
          dataBank: this.props.contractitem?.companyBankName
            ? this.state.dataBank
            : arr[0],
        });
        let arr1 = res.data.data.representativeList;
        this.setState({ representativeList: arr1 });
        this.setState({
          dataRe: this.props.contractitem?.companyRepresentativeName
            ? this.state.dataRe
            : arr1[0],
        });
      }
    });
  };
  getCustommerbyid = () => {
    axios.get(`/customer/${this.state.dataCus?.id}`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ dataCus: res.data.data });
      }
    });
  };
  formclose = () => {
    this.setState({ show: false });

    this.getContractType();
  };

  formclosebanklist = () => {
    this.setState({ showModalBankList: false });

    this.getCompany();
  };
  formcloseRe = () => {
    this.setState({ showModalRepresentative: false });

    this.getCompany();
  };
  formcloseCus = () => {
    this.setState({ showCus: false });
  };
  formcloseaddbank = () => {
    this.setState({ showAddBank: false, err: {}, bankList: {} });

    // this.getCompany()
    this.getlistcustomer();
  };
  formcloseaddRe = () => {
    this.setState({ showAddRe: false, err: {} });
    this.getlistcustomer();
    this.getCompany();
  };
  toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };
  renderGroupAdd = () => {
    return (
      <CategoryContractCreate
        PT={this.state.type}
        show={this.state.show}
        modal={{
          title: "Thêm loại hợp đồng",
          button: (
            <>
              <i className="fa-solid fa-check" /> Lưu
            </>
          ),
        }}
        onHide={this.formclose}
        submit={this.formclose}
        datarole={this.state.datatest}
      />
    );
  };
  dataTableCustomer() {
    const columns = [
      {
        name: "Khách hàng",
        selector: (row) => row.shortName,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.shortName}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Tên đầy đủ",
        selector: (row) => row.name,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.name}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Loại",
        selector: (row) => row.role,
        sortable: true,
      },
      {
        name: ""
        // <i className="fa-solid fa-check" style={{color:"blue"}}></i>
        ,
       
        selector: (row) => row.setting,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-end">
            {row.setting}
          </div>
        ),
        right: true,
      },
    ];

    const data = [];
 
    const selectedRows = (items) => {
      this.setState({ err: {}, dataBankCus: {}, dataReCus: {} }, () =>
        this.setState({ showCus: false, dataCus: items })
      );
    };

    // if (this.state.roles.length > 0) {
    this.state.litscustomer?.map((item, i) => {
      data.push({
        id: item.id,
        shortName: item.shortName,
        address: item.address,
        phoneNumber: item.phoneNumber,
        faxNumber: item.faxNumber,
        taxCode: item.taxCode,
        name: item.name,
        role: this.state.listtypecustomer.filter(
          (e) => e.id == item.customerTypeId
        )[0]?.name,
        bankList: item.bankList,
        representativeList: item.representativeList,
        // phoneNumber:item.phoneNumber
        setting: (
          <>
            {/* <Button style={{height:'20px',padding:'3px', margin:'2px'}}
              onClick={() =>
                this.setState({
                  dataCus: item,
                  showCus: false,
                  dataBankCus: {},
                  dataReCus: {},
                })
              }
            >
              <span style={{paddingBlock:'5px  !important', marginBottom:'5px !important'}}>
             Chọn
              </span>
             
            </Button> */}
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                style={{ border: "2px solid #5c8eff" }}
                id={item?.id}
                value={item}
                checked={this.state.dataCus == item}
                onChange={() => {
                  this.setState({
                    dataCus: item,
                    showCus: false,
                    dataBankCus: {},
                    dataReCus: {},
                  });
                }}
              />
              <label class="form-check-label" for="inlineCheckbox1"></label>
            </div>
          </>
        ),
      });

      return item;
    });
    const filteredItems = data.filter((item) => {
      if (
        this.toSlug(item.name)?.includes(
          this.toSlug(this.state.keyword?.trim())
        )
      ) {
        return item;
      }
      return false;
    });

    return (
      <DataTables
        data={filteredItems}
        columns={columns}
        // customStyles={customStyles}
        onRowClicked={selectedRows}
      />
    );
  }
  dataTableRe() {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.name}
          </div>
        ),
      },
      {
        name: "Chức vụ",
        selector: (row) => row.role,
        sortable: true,
      },
      {
        name: "",
        selector: (row) => row.setting,
        sortable: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ showModalRepresentative: false });
      if (this.state.typeModal == "Comp") {
        this.setState({
          dataRe: items,
        });
      } else {
        this.setState({
          dataReCus: items,
        });
      }
    };
    var arr = [];
    if (this.state.typeModal == "Comp") {
      arr = this.state.representativeList;
    } else {
      arr =
        // this.state.contractitem?.customer?.id == ""
        //   ?
        this.state.dataCus?.representativeList;
      // : this.state.litscustomer?.filter(
      //     (e) => e?.id == this.state.contractitem?.customer?.id
      //   )[0]?.representativeList;
    }
    arr?.map((item, i) => {
      data.push({
        id: item.id,
        email: item.email,
        name: item.name,
        role: item.role,
        phoneNumber: item.phoneNumber,
        setting: (
          <>
            {/* <Button
              style={{ height: "20px", padding: "3px", margin: "2px" }}
              onClick={() => {
                this.setState({
                  showModalRepresentative: false,
                });
                if (this.state.typeModal == "Comp") {
                  this.setState({ dataRe: item });
                } else {
                  if (this.state.typeModal == "Cus")
                    this.setState({ dataReCus: item });
                }
              }}
            >
              <span
                style={{
                  paddingBlock: "5px  !important",
                  marginBottom: "5px !important",
                }}
              >
                Chọn
              </span>
            </Button> */}
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                style={{ border: "2px solid #5c8eff" }}
                id={item?.id}
                value={item}
                checked={this.state.dataCus == item}
                onChange={() => {
                  this.setState({
                    showModalRepresentative: false,
                  });
                  if (this.state.typeModal == "Comp") {
                    this.setState({ dataRe: item });
                  } else {
                    if (this.state.typeModal == "Cus")
                      this.setState({ dataReCus: item });
                  }
                }}
              />
              <label class="form-check-label" for="inlineCheckbox1"></label>
            </div>
          </>
        ),
      });

      return item;
    });
    // }

    return (
      <DataTables
        data={data}
        columns={columns}
        // customStyles={customStyles}
        onRowClicked={selectedRows}
      />
    );
  }
  dataTable() {
    const columns = [
      {
        name: "Tên tài khoản",
        selector: (row) => row.cardOwner,
        sortable: true,
      },
      {
        name: "Số tài khoản",
        selector: (row) => row.bankNumber,
        sortable: true,
      },
      {
        name: "Ngân hàng",
        selector: (row) => row.bankName,
        sortable: true,
      },
      {
        name: "Địa chỉ",
        selector: (row) => row.address,
        sortable: true,
      },
      {
        name: "",
        selector: (row) => row.setting,
        sortable: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ showModalBankList: false });
      if (this.state.typeModal == "Comp") {
        this.setState({ dataBank: items });
      } else {
        if (this.state.typeModal == "Cus")
          this.setState({ dataBankCus: items });
      }
    };
    let arr = [];
    if (this.state.typeModal == "Comp") {
      arr = this.state.bankListCompany;
    } else {
      if (this.state.typeModal == "Cus") {
        arr =
          // this.state.contractitem?.customer.id == ""
          // ?
          this.state.dataCus?.bankList;
        // : this.state.litscustomer?.filter(
        //     (e) => e.id == this.state.contractitem?.customer.id
        //   )[0]?.bankList;
      }
    }

    arr?.map((item, i) => {
      data.push({
        id: item.id,
        address: item.address,
        cardOwner: item.cardOwner,
        bankNumber: item.bankNumber,
        bankName: item.bankName,
        setting: (
          <>
            {/* <Button
              style={{ height: "20px", padding: "3px", margin: "2px" }}
              onClick={() => {
                this.setState({
                  showModalBankList: false,
                });
                if (this.state.typeModal == "Comp") {
                  this.setState({ dataBank: item });
                } else {
                  if (this.state.typeModal == "Cus")
                    this.setState({ dataBankCus: item });
                }
              }}
            >
              <span
                style={{
                  paddingBlock: "5px  !important",
                  marginBottom: "5px !important",
                }}
              >
                Chọn
              </span>
            </Button> */}
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                style={{ border: "2px solid #5c8eff" }}
                id={item?.id}
                value={item}
                checked={this.state.dataCus == item}
                onChange={() => {
                  this.setState({
                    showModalBankList: false,
                  });
                  if (this.state.typeModal == "Comp") {
                    this.setState({ dataBank: item });
                  } else {
                    if (this.state.typeModal == "Cus")
                      this.setState({ dataBankCus: item });
                  }
                }}
              />
              <label class="form-check-label" for="inlineCheckbox1"></label>
            </div>
          </>
        ),
      });

      return item;
    });
    // }

    return (
      <DataTables
        data={data}
        columns={columns}
        // customStyles={customStyles}
        onRowClicked={selectedRows}
      />
    );
  }

  renderAddRe = () => {
    return (
      <Modal
        show={this.state.showAddRe}
        onHide={this.formcloseaddRe}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modal-in-contract"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-solid fa-user-tag" /> Thêm người đại diện
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="name"
                    value={this.state.ReList.name}
                    onChange={(e) => this.InputRe(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Họ và tên<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-form-err">{this.state.err.name}</span>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="role"
                    value={this.state.ReList.role}
                    onChange={(e) => this.InputRe(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Chức vụ<i className="text-danger"></i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="email"
                    value={this.state.ReList.email}
                    onChange={(e) => this.InputRe(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Email<i className="text-danger"></i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="phoneNumber"
                    value={this.state.ReList.phoneNumber}
                    onChange={(e) => this.InputRe(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Số điện thoại<i className="text-danger"></i>
                  </Form.Label>
                </Form.Floating>
              </Col>
            </Row>
            <span className="text-form-err">
              {this.state.err.representativeList}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                this.formcloseaddRe();
                // this.props.close()
                this.setState({
                  bankList: {
                    bankName: "",
                    bankNumber: "",
                    cardOwner: "",
                    address: "",
                    note: "",
                  },
                });
              }}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button
              size="sm"
              // type="submit"
              variant="success"
              onClick={() => {
                this.createRe();
              }}
            >
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
  renderAddBank = () => {
    return (
      <Modal
        show={this.state.showAddBank}
        onHide={this.formcloseaddbank}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modal-in-contract"
      >
        <Modal.Header closeButton>
          <Modal.Title>Thêm tài khoản</Modal.Title>
        </Modal.Header>

        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="cardOwner"
                    value={this.state.bankList.cardOwner}
                    onChange={(e) => this.InputBank(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Tên tài khoản
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="bankNumber"
                    value={this.state.bankList.bankNumber}
                    onChange={(e) => this.InputBank(e)}
                    required
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Số tài khoản<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="bankName"
                    value={this.state.bankList.bankName}
                    onChange={(e) => this.InputBank(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Tại ngân hàng<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="address"
                    value={this.state.bankList.address}
                    onChange={(e) => this.InputBank(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Địa chỉ<i className="text-danger"></i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên hợp đồng"
                    name="note"
                    value={this.state.bankList.note}
                    onChange={(e) => this.InputBank(e)}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Ghi chú<i className="text-danger"></i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <span className="text-form-err">{this.state.err.bankList}</span>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                this.formcloseaddbank();
                // this.props.close()
                this.setState({
                  bankList: {
                    bankName: "",
                    bankNumber: "",
                    cardOwner: "",
                    address: "",
                    note: "",
                  },
                });
              }}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button
              size="sm"
              // type="submit"
              variant="success"
              onClick={() => {
                this.createBank();
              }}
            >
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
  createRe = () => {
    var arr = [];
    if (this.state.typeModal == "Comp") arr = this.state.representativeList;
    else {
      arr = this.state.dataCus?.representativeList;
    }

    arr?.push(this.state.ReList);
    let data = {
      name: this.state.company.name,
      representativeList: arr,
      //  arr?[arr[arr?.length - 1]]:arr
    };
    let dataCus = {
      shortName: this.state.dataCus.shortName,
      name: this.state.dataCus.name,
      representativeList: arr,
      // arr?[arr[arr?.length - 1]]:arr
    };
    if (this.state.typeModal == "Comp") var path = `/company/1/update`;
    else {
      var path = `/customer/${this.state.dataCus.id}/update`;
    }
    if (this.state.typeModal == "Comp") var datainput = data;
    else {
      var datainput = dataCus;
    }
    axios
      .put(path, datainput)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          // return this.props.submit();
          return this.formcloseaddRe();
        } else {
          this.setState({ err: res.data.errorMessage, ReList: {} });
          arr = [...arr.slice(0, arr.length - 1)];

          if (this.state.typeModal == "Comp")
            this.state.representativeList?.pop();
          else {
            this.state.dataCus?.representativeList?.pop();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };
  createBank = () => {
    // console.log(this.state.dataCus)
    var arr = [];
    if (this.state.typeModal == "Comp") arr = this.state.bankListCompany;
    else {
      arr = this.state.dataCus.bankList;
    }

    arr?.push(this.state.bankList);
    let data = {
      name: this.state.company.name,
      bankList: arr,
      // arr?[arr[arr?.length - 1]]:arr
    };
    let dataCus = {
      shortName: this.state.dataCus.shortName,
      name: this.state.dataCus.name,
      bankList: arr,
      // arr?[arr[arr?.length - 1]]:arr
    };
    if (this.state.typeModal == "Comp") var path = `/company/1/update`;
    else {
      var path = `/customer/${this.state.dataCus.id}/update`;
    }
    var datainput = {};
    if (this.state.typeModal == "Comp") datainput = data;
    else {
      datainput = dataCus;
    }
    axios
      .put(path, datainput)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`Thêm mới thành công`, { type: "success", autoClose: 1000 });
          return this.formcloseaddbank();
        } else {
          this.setState({ err: res.data.errorMessage });
          // toast(`Lỗi`, { type: "success", autoClose: 1000, });
          // arr = []
          arr = arr ? [...arr?.slice(0, arr.length - 1)] : [];

          if (this.state.typeModal == "Comp") this.state.bankListCompany?.pop();
          else {
            this.state.dataCus?.bankList?.pop();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };
  formclosecustom = () => {
    this.setState({ modalAddCustomer: false, modaleditCustomer: false });
    this.getlistcustomer();
    this.getCustommerbyid();
  };

  renderCustomer = () => {
    return (
      <Modal
        show={this.state.showCus}
        onHide={this.formcloseCus}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modal-in-contract"
      >
        <Modal.Header closeButton>
          <Modal.Title> Chọn khách hàng</Modal.Title>
               {new Check().permission(['39']) ?
          <Button
            onClick={() => this.setState({ modalAddCustomer: true })}
            className="ms-2"
            variant="success"
            size="sm"
          >
            {" "}
            <i className="fa-solid fa-plus"></i> Thêm
          </Button>:null}
          <Row>
            <Col md={3} xs={5} className="mb-2 ms-2">
              <InputGroup>
                <Form.Control
                  style={{
                    height: "27px",
                    minWidth: "150px",
                    marginTop: "9px",
                  }}
                  onChange={(event) =>
                    this.setState({ keyword: event.target.value })
                  }
                  value={this.state.keyword}
                  placeholder="Nhập tên khách hàng"
                />
              </InputGroup>
            </Col>
          </Row>
        </Modal.Header>

        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>{this.dataTableCustomer()}</Modal.Body>
        </Form>
      </Modal>
    );
  };
  renderRepresentativeList = (data) => {
    return (
      <Modal
        show={this.state.showModalRepresentative}
        onHide={this.formcloseRe}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modal-in-contract"
      >
        <Modal.Header closeButton>
          <Modal.Title> Chọn người đại diện</Modal.Title>
          {/* (this.state.typeModal == "Comp") { */}
               {new Check().permission([this.state.typeModal == "Comp"?'14':'40']) ?
          <Button
            onClick={() => this.setState({ showAddRe: true })}
            variant="success"
            size="sm"
            className="ms-2"
          >
            {" "}
            <i className="fa-solid fa-plus"></i> Thêm

          </Button>
          :null}
        </Modal.Header>

        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>{this.dataTableRe()}</Modal.Body>
        </Form>
      </Modal>
    );
  };
  renderBankList = (data) => {
    return (
      <Modal
        show={this.state.showModalBankList}
        onHide={this.formclosebanklist}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modal-in-contract"
      >
        <Modal.Header closeButton>
          <Modal.Title> Chọn tài khoản</Modal.Title>
          {new Check().permission([this.state.typeModal == "Comp"?'14':'40']) ?
          <Button
            onClick={() => this.setState({ showAddBank: true })}
            variant="success"
            className="ms-2"
            size="sm"
          >
            {" "}
            <i className="fa-solid fa-plus"></i> Thêm
          </Button>:null}
        </Modal.Header>

        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>{this.dataTable()}</Modal.Body>
        </Form>
      </Modal>
    );
  };
  customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "48px",
      color: state.isFocused ? "yellow" : "red",
      boxShadow: state.isFocused ? "0 0 0 4px rgba(199, 246, 255, 1)" : null,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "20px",
    }),
  };

  InputBank = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.bankList };
    dataInput[field] = value;
    this.setState({ bankList: dataInput, err: {} });
  };
  InputRe = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.ReList };
    dataInput[field] = value;
    this.setState({ ReList: dataInput });
  };
  handleSubmitContract = (e) => {
    e.preventDefault();

    if (this.state.contractitem.id === "") {
      this.createContract();
    } else {
      this.updateContract();
    }
  };
  createContract = () => {
    let input = this.state.contractitem;
    let data = {
      contractCode: input.contractCode,
      companyId: 1, // require
      customerId: this.state.dataCus.id, // require
      contractTypeId:
        this.props.page == "advertisement" ? Number(input.contractTypeId) : 1, // require
      userChargeId: input.userChargeId ? Number(input.userChargeId) : 0,
      effectiveDate: this.state.effectiveDate / 1000,
      expirationDate: this.state.expirationDate / 1000,
      percentDiscount: !isNaN(input.percentDiscount)
        ? Number(input.percentDiscount)
        : input.percentDiscount, // require
      contractValue: Number(input.contractValue),
      paymentType: this.state.isCashSelected,
      createdDate: this.state.createDate / 1000,
      note: input.note,

      companyName: this.state.company?.name, // require
      companyAddress: this.state.company?.address,
      companyPhoneNumber: this.state.company?.phoneNumber,
      companyTaxCode: this.state.company?.taxCode,
      companyFaxNumber: this.state.company?.faxNumber,
      companyBankNumber: this.state.dataBank?.bankNumber,
      companyBankName: this.state.dataBank?.bankName,
      companyRepresentativeName: this.state.dataRe?.name,
      companyRepresentativeRole: this.state.dataRe?.role,
      customerName: this.state.dataCus?.name, // require
      customerAddress: this.state.dataCus?.address,
      customerPhoneNumber: this.state.dataCus?.phoneNumber,
      customerTaxCode: this.state.dataCus?.taxCode,
      customerFaxNumber: this.state.dataCus?.faxNumber,
      customerBankNumber: this.state.dataBankCus?.bankNumber,
      customerBankName: this.state.dataBankCus?.bankName,
      customerRepresentativeName: this.state.dataReCus?.name,
      customerRepresentativeRole: this.state.dataReCus?.role,
      customerBankAddress: this.state.dataBankCus?.address,
      companyBankAddress: this.state.dataBank?.address,
    };

    axios
      .post(`/contract`, data)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          return this.props.submit();
        } else {
          this.setState({ err: res.data.errorMessage });
        }
      })
      .catch((error) => {
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };
  updateContract() {
    let input = this.state.contractitem;
    let data = {
      contractCode: input.contractCode,
      companyId: 1, // require
      customerId: this.state.dataCus.id, // require
      contractTypeId:
        this.props.page == "advertisement" ? Number(input.contractTypeId) : 1, // require
      userChargeId: input.userChargeId ? Number(input.userChargeId) : 0,
      effectiveDate: this.state.effectiveDate / 1000,
      expirationDate: this.state.expirationDate / 1000,
      percentDiscount: !isNaN(input.percentDiscount)
        ? Number(input.percentDiscount)
        : input.percentDiscount, // require
      contractValue: Number(input.contractValue),
      paymentType: this.state.isCashSelected,
      createdDate: this.state.createDate / 1000,
      note: input.note,

      companyName: this.state.company?.name, // require
      companyAddress: this.state.company?.address,
      companyPhoneNumber: this.state.company?.phoneNumber,
      companyTaxCode: this.state.company?.taxCode,
      companyFaxNumber: this.state.company?.faxNumber,
      companyBankNumber: this.state.dataBank?.bankNumber,
      companyBankName: this.state.dataBank?.bankName,
      companyRepresentativeName: this.state.dataRe?.name,
      companyRepresentativeRole: this.state.dataRe?.role,
      customerName: this.state.dataCus?.name, // require
      customerAddress: this.state.dataCus?.address,
      customerPhoneNumber: this.state.dataCus?.phoneNumber,
      customerTaxCode: this.state.dataCus?.taxCode,
      customerFaxNumber: this.state.dataCus?.faxNumber,
      customerBankNumber: this.state.dataBankCus?.bankNumber,
      customerBankName: this.state.dataBankCus?.bankName,
      customerRepresentativeName: this.state.dataReCus?.name,
      customerRepresentativeRole: this.state.dataReCus?.role,
      customerBankAddress: this.state.dataBankCus?.address,
      companyBankAddress: this.state.dataBank?.address,
    };
    axios
      .put(`/contract/${input.id}/update`, data)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          return this.props.submit();
        } else {
          this.setState({ err: res.data.errorMessage });
        }
      })
      .catch((error) => {
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  }
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    else return 0;
  }
  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.props.close} size="lg">
          <Modal.Header closeButton>
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              {this.props.PT === "TM" || this.props.PT === ""
                ? "Thêm hợp đồng"
                : "Cập nhật hợp đồng"}
            </h1>
          </Modal.Header>
          <Form onSubmit={this.handleSubmitContract}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      id="floatingInputClassName"
                      placeholder="Tên hợp đồng"
                      name="contractCode"
                      required={this.props.page == "advertisement"}
                      value={this.state.contractitem?.contractCode}
                      onChange={this.handleInput}
                    />
                    <Form.Label htmlFor="floatingInputClassName">
                      Số HĐ
                      <i className="text-danger">
                        {" "}
                        {this.props.page != "advertisement" ? null : "*"}
                      </i>
                    </Form.Label>
                    <div className="invalid-feedback"></div>
                  </Form.Floating>
                  <span className="text-form-err">
                    {this.state.err.contractCode}
                  </span>
                </Col>
                <Col className="">
                  <span
                    className="pe-2 "
                    style={{
                      position: "absolute",
                      marginLeft: "8px",
                      zIndex: 1,
                      color: "#757e86",
                    }}
                  >
                    Ngày
                  </span>{" "}
                  <DatePicker
                    selected={this.state.createDate}
                    onChange={(date) =>
                      this.setState({
                        createDate: date,
                        err: { ...delete this.state.err["createDate"] },
                      })
                    }
                    isClearable
                    placeholderText="Chọn ngày"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                  <span className="text-form-err">
                    {this.state.err.createdDate}
                  </span>
                </Col>
                <Col className="d-flex">
                  {/* <span className="pe-2 pt-3 text-nowrap " >Hiệu lực hợp đồng</span>   */}
                  <span
                    className="pe-2 "
                    style={{
                      position: "absolute",
                      marginLeft: "8px",
                      zIndex: 1,
                      color: "#757e86",
                    }}
                  >
                    Hiệu lực từ ngày <i className="text-danger">*</i>
                  </span>
                  <DatePicker
                    selected={this.state.effectiveDate}
                    onChange={(date) =>
                      this.setState({ effectiveDate: date, err: {} })
                    }
                    isClearable
                    placeholderText="Chọn ngày"
                    dateFormat="dd/MM/yyyy"
                    required
                    className="form-control"
                  />

                  {/* <span className="text-form-err">{this.state.err.effectiveDate}</span> */}
                  {/* <span className="pe-2 pt-3 text-nowrap ps-1" >-</span> */}
                </Col>
                <Col>
                  {" "}
                  <span
                    className="pe-2 "
                    style={{
                      position: "absolute",
                      marginLeft: "8px",
                      zIndex: 1,
                      color: "#757e86",
                    }}
                  >
                    Hiệu lực đến ngày<i className="text-danger">*</i>
                  </span>
                  <DatePicker
                    selected={this.state.expirationDate}
                    onChange={(date) => this.setState({ expirationDate: date })}
                    isClearable
                    placeholderText="Chọn ngày"
                    dateFormat="dd/MM/yyyy"
                    required
                    className="form-control"
                  />
                  <span className="text-form-err">
                    {this.state.err.expirationDate}
                  </span>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      id="floatingInputClassName"
                      placeholder="Tên hợp đồng"
                      name="percentDiscount"
                      value={
                        this.state.contractitem?.percentDiscount
                          ? `${this.state.contractitem.percentDiscount}`
                          : ""
                      }
                      onChange={this.handleInput}
                    />
                    <Form.Label htmlFor="floatingInputClassName">
                      Giảm giá (%)<i className="text-danger"></i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err.percentDiscount}
                    </span>
                    <div className="invalid-feedback"></div>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <div
                  className="mb-1 ms-5 "
                  style={{ fontSize: "1.05rem", lineHeight: 2 }}
                >
                  <div>
                    Bên Đài :{" "}
                    <input
                      className="long"
                      type="text"
                      value={this.state.company?.name}
                      disabled
                      // onChange={handleInputChange}
                    />
                    {/* <span className="fw-bold"> {this.state.company?.name}</span> */}
                  </div>
                  <div>
                    Địa chỉ :{" "}
                    <input
                      className="long"
                      type="text"
                      value={this.state.company?.address}
                      // value={this.props.contractitem?.companyAddress}
                      name="address"
                      onChange={this.handleInputCompany}
                    />
                    {/* <span className="fw-bold">
                      {" "}
                      {this.state.company?.address}
                    </span> */}
                  </div>
                  <span className="text-form-err">
                    {this.state.err.companyAddress}
                  </span>
                  <Row>
                    <Col md={4}>
                      <span>
                        Số điện thoại :{" "}
                        <input
                          className="short"
                          type="text"
                          // value={this.props.contractitem?.companyPhoneNumber}
                          value={this.state.company?.phoneNumber}
                          name="phoneNumber"
                          onChange={this.handleInputCompany}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                    <Col md={4}>
                      <span>
                        Mã Fax :{" "}
                        <input
                          className="short"
                          type="text"
                          // value={this.props.contractitem?.companyFaxNumber}

                          value={this.state.company?.faxNumber}
                          name="faxNumber"
                          onChange={this.handleInputCompany}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                    <Col md={4}>
                      <span>
                        Mã số thuế :{" "}
                        <input
                          className="shorter"
                          type="text"
                          // value={this.props.contractitem?.companyTaxCode }

                          value={this.state.company?.taxCode}
                          name="taxCode"
                          onChange={this.handleInputCompany}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.companyPhoneNumber}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.companyFaxNumber}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.companyTaxCode}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="d-flex">
                      <div className="text-label"> Số tài khoản </div>:
                      <Button
                        size="sm"
                        onClick={() => {
                          this.setState({
                            showModalBankList: true,
                            typeModal: "Comp",
                          });
                          delete this.state.err["companyBankNumber"];
                          delete this.state.err["companyBankName"];
                          delete this.state.err["companyBankAddress"];
                        }}
                        className="ms-1 mb-1 me-1"
                      >
                        Chọn
                      </Button>
                      <input
                        className="short"
                        type="text"
                        value={this.state.dataBank?.bankNumber}
                        name="bankNumber"
                        onChange={(e) => {
                          this.handleInputdataBank(e);
                          // this.setState({  err: { ...delete this.state.err["companyBankNumber"]}})
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <span>
                        Ngân hàng :{" "}
                        <input
                          className="short"
                          type="text"
                          value={this.state.dataBank?.bankName}
                          name="bankName"
                          onChange={(e) => {
                            this.handleInputdataBank(e);
                            // this.setState({  err: { ...delete this.state.err["companyBankName"]}})
                          }}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>

                    <Col md={4}>
                      <span>
                        Địa chỉ:{" "}
                        <input
                          className="short"
                          type="text"
                          value={this.state.dataBank?.address}
                          name="address"
                          onChange={(e) => {
                            this.handleInputdataBank(e);
                            //   this.setState({  err: { ...delete this.state.err["companyBankAddress"]}
                            // })
                          }}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.companyBankNumber}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.companyBankName}
                      </span>
                    </Col>{" "}
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.companyBankAddress}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="d-flex">
                      <div className="text-label">Do ông / bà </div>:{" "}
                      <Button
                        size="sm"
                        className="ms-1 mb-1 me-1"
                        onClick={() => {
                          this.setState({
                            showModalRepresentative: true,
                            typeModal: "Comp",
                          });
                          delete this.state.err["companyRepresentativeName"];
                          delete this.state.err["companyRepresentativeRole"];
                        }}
                      >
                        Chọn
                      </Button>
                      <input
                        className="medium"
                        type="text"
                        value={this.state.dataRe?.name}
                        name="name"
                        onChange={this.handleInputdataRe}
                        // onChange={handleInputChange}
                      />
                    </Col>
                    <Col md={6}>
                      <span>
                        Chức vụ :{" "}
                        <input
                          className="medium"
                          type="text"
                          value={this.state.dataRe?.role}
                          name="role"
                          onChange={this.handleInputdataRe}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <span className="text-form-err">
                        {this.state.err.companyRepresentativeName}
                      </span>
                    </Col>
                    <Col md={6}>
                      <span className="text-form-err">
                        {this.state.err.companyRepresentativeRole}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Row>
              <hr />
              <Row>
                <div
                  className="mb-1 ms-5 "
                  style={{ fontSize: "1.05rem", lineHeight: 2 }}
                >
                  <div className="d-flex">
                    <div className="text-label">Khách hàng </div> :{" "}
                    <Button
                      size="sm"
                      className="ms-1 mb-1 me-1 "
                      onClick={() => {
                        this.setState({ showCus: true });
                        delete this.state.err["customerName"];
                        delete this.state.err["customerAddress"];
                        delete this.state.err["customerPhoneNumber"];
                        delete this.state.err["customerTaxCode"];
                        delete this.state.err["customerFaxNumber"];
                        delete this.state.err["customerBankNumber"];
                        delete this.state.err["customerBankName"];
                        delete this.state.err["customerRepresentativeName"];
                        delete this.state.err["customerRepresentativeRole"];
                        delete this.state.err["customerBankAddress"];
                      }}
                    >
                      Chọn
                    </Button>
                    <input
                      className="long"
                      type="text"
                      disabled
                      required
                      value={this.state.dataCus?.name}
                      // onChange={handleInputChange}
                    />
                  </div>
                  <span className="text-form-err">
                    {this.state.err.customerName}
                  </span>
                  <div>
                    Địa chỉ:
                    <input
                      className="long"
                      type="text"
                      value={this.state.dataCus?.address}
                      name="address"
                      onChange={this.handleInputdataCus}
                    />
                  </div>
                  <span className="text-form-err">
                    {this.state.err.customerAddress}
                  </span>
                  <Row>
                    <Col>
                      <span>
                        Số điện thoại :{" "}
                        <input
                          className="short"
                          type="text"
                          value={this.state.dataCus?.phoneNumber}
                          name="phoneNumber"
                          onChange={this.handleInputdataCus}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                    <Col>
                      <span>
                        Mã Fax :{" "}
                        <input
                          className="short"
                          type="text"
                          value={this.state.dataCus?.faxNumber}
                          name="faxNumber"
                          onChange={this.handleInputdataCus}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                    <Col>
                      <span>
                        Mã số thuế :{" "}
                        <input
                          className="shorter"
                          type="text"
                          value={this.state.dataCus?.taxCode}
                          name="taxCode"
                          onChange={this.handleInputdataCus}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.customerPhoneNumber}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.customerTaxCode}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.customerFaxNumber}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="d-flex">
                      <div className="text-label"> Số tài khoản </div>:{" "}
                      <Button
                        size="sm"
                        className="ms-1 mb-1 me-1"
                        onClick={() =>
                          this.setState({
                            showModalBankList: true,
                            typeModal: "Cus",
                          })
                        }
                        disabled={this.state.dataCus?.id == ""}
                      >
                        Chọn
                      </Button>{" "}
                      <input
                        className="short"
                        type="text"
                        value={this.state.dataBankCus?.bankNumber}
                        name="bankNumber"
                        onChange={(e)=>
                          // console.log("><>>>>")
                          this.handleInputdataBankCus(e)
                        }
                        // onFocus={(e)=>this.handleInputdataBankCus(e)}
                      />
                    </Col>

                    <Col md={4}>
                      <span>
                        Ngân hàng :{" "}
                        <input
                          className="short"
                          type="text"
                          value={this.state.dataBankCus?.bankName}
                          name="bankName"
                          onChange={this.handleInputdataBankCus}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                    <Col md={4}>
                      <span>
                        Địa chỉ:{" "}
                        <input
                          className="short"
                          type="text"
                          value={this.state.dataBankCus?.address}
                          name="address"
                          onChange={this.handleInputdataBankCus}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.customerBankNumber}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.customerBankName}
                      </span>
                    </Col>
                    <Col md={4}>
                      <span className="text-form-err">
                        {this.state.err.customerBankAddress}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="d-flex">
                      <div className="text-label">Do ông / bà </div> :
                      <Button
                        size="sm"
                        onClick={() =>
                          this.setState({
                            showModalRepresentative: true,
                            typeModal: "Cus",
                          })
                        }
                        className="ms-1 mb-1 me-1"
                        disabled={this.state.dataCus?.id == ""}
                      >
                        Chọn
                      </Button>{" "}
                      <input
                        className="medium"
                        type="text"
                        value={this.state.dataReCus?.name}
                        name="name"
                        onChange={this.handleInputdataReCus}
                      />
                    </Col>
                    <Col md={6}>
                      <span>
                        Chức vụ :{" "}
                        <input
                          className="medium"
                          type="text"
                          value={this.state.dataReCus?.role}
                          name="role"
                          onChange={this.handleInputdataReCus}
                          // onChange={handleInputChange}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <span className="text-form-err">
                        {this.state.err.customerRepresentativeName}
                      </span>
                    </Col>
                    <Col md={6}>
                      <span className="text-form-err">
                        {this.state.err.customerRepresentativeRole}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Row>

              {/* :null} */}
              <Row>
              {new Check().permission(['43']) ?
                this.props.page == "notification" ? null : (
                  <Form.Group className="text-start">
                    <Link
                      className="fst-italic"
                      onClick={() => {
                        this.setState({
                          show: true,
                          type: "TM",
                        });
                      }}
                    >
                      +Thêm loại hợp đồng
                    </Link>
                  </Form.Group>
                ):null}
                {this.props.page == "notification" ? (
                  <Col>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        id="floatingInputClassName"
                        placeholder="Tên hợp đồng"
                        value={"Thông báo"}
                        readOnly
                      />
                      <Form.Label htmlFor="floatingInputClassName">
                        Loại hợp đồng<i className="text-danger"></i>
                      </Form.Label>
                      <div className="invalid-feedback"></div>
                    </Form.Floating>
                  </Col>
                ) : (
                  <Col>
                    <Form.Floating className="mb-3">
                      <Form.Select
                        name="contractTypeId"
                        placeholder="Loại hợp đồng"
                        value={this.state.contractitem?.contractTypeId}
                        // required
                        onChange={this.handleInput}
                      >
                        <option value={""}>--Chọn--</option>
                        {this.state.listcontractType
                          ?.filter((e) => e.id != 1)
                          ?.map((group, i) => {
                            return (
                              <option key={group.id} value={group.id}>
                                {group.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <Form.Label htmlFor="selectGroupId">
                        Loại hợp đồng<i className="text-danger"></i>
                      </Form.Label>
                    </Form.Floating>
                  </Col>
                )}

                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="number"
                      id="floatingInputClassName"
                      placeholder="Tên hợp đồng"
                      value={this.state.contractitem?.contractValue}
                      onChange={this.handleInput}
                      name="contractValue"
                      required
                    />
                    <Form.Label htmlFor="floatingInputClassName">
                      Giá trị hợp đồng<i className="text-danger"></i>
                    </Form.Label>
                    <div className="invalid-feedback"></div>
                  </Form.Floating>
                </Col>
                <Col className="">
                  <div className="d-flex">
                    <label>Tiền mặt</label>
                    <Form.Check
                      type="switch"
                      id="custom-switch-cash1"
                      className="ms-2"
                      value={1}
                      checked={this.state.isCashSelected == 1}
                      onChange={() =>
                        this.setState({
                          isCashSelected: 1,
                        })
                      }
                    />

                    <label className="ms-3">Chuyển khoản</label>
                    <Form.Check
                      type="switch"
                      id="custom-switch-transfer2"
                      className="ms-2"
                      value={2}
                      checked={this.state.isCashSelected == 2}
                      onChange={() =>
                        this.setState({
                          isCashSelected: 2,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex">
                    <label>Tiền mặt/ chuyển khoản</label>
                    <Form.Check
                      type="switch"
                      id="custom-switch-cash"
                      className="ms-2"
                      value={3}
                      checked={this.state.isCashSelected == 3}
                      onChange={() =>
                        this.setState({
                          isCashSelected: 3,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      name="userChargeId"
                      placeholder="Loại hợp đồng"
                      value={this.state.contractitem?.userChargeId}
                      onChange={this.handleInput}
                    >
                      <option value={""}>--Chọn--</option>
                      {this.state.listUser?.map((group, i) => {
                        return (
                          <option key={group.id} value={group.id}>
                            {group.fullName}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Label htmlFor="selectGroupId">
                      Người chịu trách nhiệm<i className="text-danger"></i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      id="floatingInputClassName"
                      placeholder="Tên hợp đồng"
                      name="note"
                      value={this.state.contractitem?.note}
                      onChange={this.handleInput}
                    />
                    <Form.Label htmlFor="floatingInputClassName">
                      Ghi chú<i className="text-danger"></i>
                    </Form.Label>
                    <div className="invalid-feedback"></div>
                  </Form.Floating>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.props.close}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              <Button
                size="sm"
                type="submit"
                variant="success"
                // onClick={() => {
                //   if (this.props.PT === "TM" || this.props.PT === "") {
                //     this.createclass();
                //   } else {
                //     this.updateclass();
                //   }
                // }}
              >
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {this.renderGroupAdd()}
        {this.renderBankList(2)}
        {this.renderAddBank()}
        {this.renderRepresentativeList(2)}
        {this.renderAddRe()}
        {this.renderCustomer()}
        {this.state.modalAddCustomer ? (
          <>
            <CustommerModal
              show={this.state.modalAddCustomer}
              onHide={this.formclosecustom}
              close={this.formclosecustom}
              submit={this.formclosecustom}
            />
          </>
        ) : null}
        {this.state.modaleditCustomer ? (
          <>
            {/* <CustommerModal
              show={this.state.modaleditCustomer}
              onHide={this.formclosecustom}
              close={this.formclosecustom}
              submit={this.formclosecustom}
              
            /> */}
            <CustomerEditModal
              show={this.state.modaleditCustomer}
              onHide={this.formclosecustom}
              close={this.formclosecustom}
              submit={this.formclosecustom}
              data={this.state.dataCus}
              PT={"CN"}
            />
          </>
        ) : null}
      </>
    );
  }
}

export default ContractModal;
