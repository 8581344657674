import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Tabs,
  Tab,
  InputGroup,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
class YearSummaryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        name: "",
        timeCode: "",
        price: "",
        unit: "",
        priceType: 0,
        timeStart: "12:00",
        timeEnd: "",
        dayApply: "",
        dayEnd: "",
      },
      selectedRows: [],
      checkload: true,
      priceType: 0,
      listAd: [],
      show: false,
      start_: new Date(),
      startDate: new Date(),
      // new Date(),
      endDate: new Date(),
      end_: new Date(),
      keyword: "",
      show: false,
      listCustomer: [],
      CustomerItem: { value: 0, label: "Chọn khách hàng" },
      listProduct: [],
      ProductItem: { value: 0, label: "Chọn sản phẩm" },

      listContract: [],
      ContractItem: { value: 0, label: "Chọn hợp đồng" },
      listTapCode: [],
      TapCodeItem: { value: 0, label: "Chọn mã băng" },
      listAdPrice: [],
      AdPriceItem: { value: 0, label: "Chọn block" },
      listSupplier: [],
      SupplierItem: { value: 0, label: "Chọn nhà cung cấp" },
      listContractType: [],
      ContractTypeItem: { value: 0, label: "Chọn loại hợp đồng" },
      listUser: [],
      UserItem: { value: 0, label: "Chọn nhân viên" },
      isCashSelected: false,
      dataGroup: [
        { value: 1, label: "Loại khách hàng", name: "customerTypeName" },
        { value: 2, label: "Khách hàng", name: "customerName" },
        { value: 3, label: "Loại hợp đồng", name: "contractTypeName" },
        { value: 4, label: "Hợp đồng", name: "contractContractCode" },
        { value: 5, label: "Mã lịch", name: "scheduleCalendarCode" },
        { value: 6, label: "Loại Spot", name: "tapeCodeTypeName" },
        { value: 7, label: "Block QC (Bảng giá)", name: "adPriceName" },
        { value: 8, label: "Nhà cung cấp", name: "supplierName" },
        { value: 9, label: "Sản phẩm", name: "productName" },
        { value: 10, label: "Mã băng", name: "tapeCodeName" },
        // { value: 11, label: "Ngày", name: "broadcastDate" },
        // { value: 12, label: "Tuần", name: "week" },
        // { value: 13, label: "Tháng", name: "month" },
        // { value: 14, label: "Quí", name: "quarter" },
        // { value: 15, label: "Năm", name: "year" },
      ],
      listGroup: [{ value: 2, label: "Khách hàng", name: "customerName" }],
      dataGroupReturn: [{ value: 2, label: "Tổng thu", name: "totalRevenue" }],
      dataG: [
        { value: 1, label: "Số kỳ", name: "totalPeriod" },
        { value: 2, label: "Tổng thu", name: "totalRevenue" },
        // { value: 3, label: "Giảm giá", name: "totalDiscount" },
        { value: 4, label: "Thời lượng", name: "totalDuration" },
        { value: 5, label: "Thực thu", name: "totalRevenue" },
      ],
      listType: [],
      checkType: 1,
      list1: [],
      list2: [],
      isSortCustomer: false,
      listKeySort: [],
    };
  }

  componentDidMount() {
    document.title = "Danh sách tổng kết năm";
    this.getPermission();
    this.setState(
      {
        startDate: this.changeStartMonth(new Date()),
        endDate: this.changeEndMonth(new Date()),
      },
      () => {
        this.getRoles();
        this.getAllCustomer();
        this.getAllContract(0);
        this.getAllProduct(0);
        this.getAllSupplier();
        this.getAllContractType();
        this.getAllTapeCode(0);
        this.getAllUser();
        this.getAllAdPrice(
          new Date(this.state.startDate?.setHours(0, 0, 0)) / 1000,
          new Date(this.state.endDate.setHours(23, 59, 59)) / 1000
        );
      }
    );
  }
  changeEndMonth = (date) => {
    return new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59));
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  getPermission = () => {
    axios
      .get(`/permission`)
      .then((res) => {
        if (res.data.status == 200) {
          // console.log(res.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllCustomer = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn khách hàng" });
    axios
      .get(`/customer?page=1&limit=100&type=${this.state.isCashSelected ? 1 : 2}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listCustomer: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  formatNumber(num) {
    if (num != null && num != "") return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    else return 0;
  }
  getAllProduct = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn sản phẩm" });
    axios
      .get(`/product/search?name=&supplierId=${id}&page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.product?.name });
          });

          this.setState({
            listProduct: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllSupplier = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn nhà cung cấp" });
    axios
      .get(`/supplier?page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listSupplier: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContractType = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn loại hợp đồng" });
    axios
      .get(`/contract-type`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listContractType: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllUser = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn nhân viên" });
    axios
      .get(`/user`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.fullName });
          });
          this.setState({
            listUser: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContract = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn hợp đồng" });
    axios
      .get(`/contract/?customerId=${id}&contractView=${this.state.isCashSelected == true ? 1 : 2}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.contractCode });
          });
          this.setState({
            listContract: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllTapeCode = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn mã băng" });
    axios
      .get(`/tape-code?productId=${id}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listTapCode: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllAdPrice = (start, end) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn block" });
    axios
      .get(`/ad-price/search?dayApply=${start}&dayEnd=${end}`)
      .then((res) => {
        if (res.data.status == 200) {
          if (this.state.isCashSelected == true)
            res.data.data?.notification?.map((item) => {
              arr.push({ value: item?.id, label: item?.timeCode + " - " + item?.name });
            });
          else{
            res.data.data?.social?.map((item) => {
              arr.push({
                value: item?.id,
                label: item?.timeCode + " - " + item?.name,
              });
            });
            res.data.data?.ad?.map((item) => {
              arr.push({ value: item?.id, label: item?.timeCode + " - " + item?.name });
            });
          }
          
          this.setState({
            listAdPrice: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getRoles = () => {
    axios.get(`/ad-price`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        // this.setState({ listAd: res.data.data });
      }
    });
  };
  getUniqueKey(summaryItem, groupIds) {
    return groupIds
      .map((groupId) => {
        const key = this.getGroupKey(groupId);
        return `${key}_${summaryItem[key]}`;
      })
      .join("_");
  }

  getGroupKey(groupId) {
    if (groupId == 1) return "customerTypeId";
    if (groupId == 2) return "customerId";
    if (groupId == 3) return "contractTypeId";
    if (groupId == 4) return "contractId";
    if (groupId == 5) return "scheduleId";
    if (groupId == 6) return "tapeCodeTypeId";
    if (groupId == 7) return "adPriceId";
    if (groupId == 8) return "supplierId";
    if (groupId == 9) return "productId";
  }
  getRole = (item) => {
    if (item !== "null") {
      this.setState({
        role: item,
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa bảng giá?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/ad-price/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    if (data != null) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()
      );
    } else {
      return "";
    }
    //  + " " +
    //   ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  sumValue = () => {
    let sum = 0;
    this.state.listAd?.map((item) => {
      sum += item?.adPrice?.realPrice;
    });
    return sum;
  };
  sumDura = () => {
    let sum = 0;
    this.state.listAd?.map((item) => {
      sum += item?.tapeCode?.duration;
    });
    return sum;
  };
  sumElement = (element, index) => {
    let sum = 0;

    this.state.listAd?.map((item) => {
      sum += item?.aggregatedData[index]?.[`${element}`];
    });
    return sum;
  };
  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };
  Sort(key) {
    let arr = [...this.state.listAd];

    if (key === "customerName") {
      this.setState({ isSortCustomer: true }, () => {
        arr.sort((a, b) => {
          const nameA = a["customerName"] || ""; // Sử dụng giá trị mặc định nếu là null
          const nameB = b["customerName"] || "";
          return nameA.localeCompare(nameB) || this.secondarySort(a, b, key);
        });

        this.setState({ listAd: arr });
      });
    } else {
      if (this.state.isSortCustomer) {
        arr.sort((a, b) => {
          if (a.customerName === b.customerName) {
            const nameA = a["customerName"] || "";
            const nameB = b["customerName"] || "";
            return nameA.localeCompare(nameB) || this.secondarySort(a, b, key);
          }
        });
      } else {
        arr.sort((a, b) => this.secondarySort(a, b, key));
      }

      this.setState({ listAd: arr });
    }
  }

  secondarySort(a, b, key) {
    if (a[key] === null || a[key] === undefined) return 1;
    if (b[key] === null || b[key] === undefined) return -1;

    if (key === "broadcastDate" || key === "week" || key === "quarter" || key === "month" || key === "year") {
      return a[key] - b[key];
    } else {
      return a[key]?.localeCompare(b[key]) || 0;
    }
  }

  SortReverse(key) {
    let arr = [...this.state.listAd];

    if (key === "customerName") {
      this.setState({ isSortCustomer: true }, () => {
        arr.sort((a, b) => {
          const nameA = b["customerName"] || "";
          const nameB = a["customerName"] || "";
          return nameA.localeCompare(nameB) || this.secondarySortReverse(a, b, key);
        });

        this.setState({ listAd: arr });
      });
    } else {
      if (this.state.isSortCustomer === true) {
        arr.sort((a, b) => {
          if (a.customerName === b.customerName) {
            const nameA = b["customerName"] || "";
            const nameB = a["customerName"] || "";
            return nameA.localeCompare(nameB) || this.secondarySortReverse(a, b, key);
          }
        });
      } else {
        console.log(">>>>run");
        arr.sort((a, b) => this.secondarySortReverse(a, b, key));
      }

      this.setState({ listAd: arr });
    }
  }

  secondarySortReverse(a, b, key) {
    if (a[key] === null || a[key] === undefined) return -1;
    if (b[key] === null || b[key] === undefined) return 1;

    if (key === "broadcastDate" || key === "week" || key === "quarter" || key === "month" || key === "year") {
      return b[key] - a[key];
    } else {
      return b[key]?.localeCompare(a[key]) || 0;
    }
  }
  dataTable() {
    return (
      <div style={{ overflow: "auto" }}>
        <Table striped bordered style={{ border: "1px solid black" }} id="exportListMonth" className="d-none">
          <thead>
            <tr>
              <th>TỔNG KẾT NĂM</th>
            </tr>
            <tr>
              <th>
                {" "}
                Từ tháng {this.state.startDate?.getMonth() + 1}/{this.state.startDate?.getFullYear()} đến tháng{" "}
                {this.state.endDate?.getMonth() + 1}/{this.state.endDate?.getFullYear()}
              </th>
            </tr>
            <tr style={{ whiteSpace: "nowrap" }}>
              {this.state.listGroup?.map((item) => {
                if (item.name == "adPriceName")
                  return (
                    <>
                      <th className="align-middle text-center">{item.label}</th>
                      <th className="align-middle text-center">Mã giờ</th>
                    </>
                  );
                else return <th className="align-middle text-center">{item.label}</th>;
              })}
              {this.state.listType?.map((time) => {
                if (time.value > 0)
                  return (
                    <>
                      <th
                        // colSpan={this.state.dataGroupReturn?.length}
                        className="text-center"
                      >
                        {time.label}
                      </th>
                      {this.renderEmpty(this.state.dataGroupReturn?.length - 1)}
                    </>
                  );
              })}
            </tr>
            <tr>
              {this.state.listGroup?.map((item) => {
                if (item.name == "adPriceName")
                  return (
                    <>
                      <th className="align-middle text-center">empty</th>
                      <th className="align-middle text-center">empty</th>
                    </>
                  );
                else return <th className="align-middle text-center">empty</th>;
              })}
              {this.state.listType?.map((time) => {
                if (time.value > 0)
                  return this.state.dataGroupReturn?.map((e) => {
                    return <th className="text-center">{e.label}</th>;
                  });
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.listAd?.map((item) => {
              return (
                <tr>
                  {this.state.listGroup?.map((group) => {
                    if (group.name != "adPriceName") return <td>{item[group.name]}</td>;
                    else
                      return (
                        <>
                          <td>{item[group.name]}</td>
                          <td>{item["adPriceTimeCode"]}</td>
                        </>
                      );
                  })}

                  {item.aggregatedData?.map((dataA) => {
                    return this.state.dataGroupReturn?.map((group) => {
                      return <td className="text-end">{parseInt(dataA[group.name])}</td>;
                    });
                  })}
                </tr>
              );
            })}
            <tr>
              {this.renderEmpty(
                this.state.dataGroupReturn?.length * (this.state.listType?.length - 1) + this.state.listGroup?.length
              )}
            </tr>
          </tbody>
        </Table>
        <div style={{}}>
          <Table striped bordered style={{ marginTop: "10px", border: "1px solid #ccc" }}>
            <thead>
              <tr style={{ whiteSpace: "nowrap" }}>
                {this.state.listGroup?.map((item) => {
                  if (item.name == "adPriceName")
                    return (
                      <>
                        <th rowSpan={2} className="align-middle text-center">
                          {item.label}
                          <i
                            className="fa-solid fa-right-left ms-2 "
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter((key) => key != item.name),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [...this.state.listKeySort, item.name],
                                });
                              }
                            }}
                            style={{ cursor: "pointer", transform: "rotate(90deg)" }}
                          ></i>
                        </th>
                        <th rowSpan={2} className="align-middle text-center">
                          Mã giờ
                        </th>
                      </>
                    );
                  else
                    return (
                      <th rowSpan={2} className="align-middle text-center">
                        {item.label}
                        {item.label == "Khách hàng" ? (
                          <i
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter((key) => key != item.name),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [...this.state.listKeySort, item.name],
                                });
                              }
                            }}
                            className="fa-solid fa-thumbtack ms-2 "
                            style={{ cursor: "pointer" }}
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter((key) => key != item.name),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [...this.state.listKeySort, item.name],
                                });
                              }
                            }}
                            className="fa-solid fa-right-left ms-2 "
                            style={{ cursor: "pointer", transform: "rotate(90deg)" }}
                          ></i>
                        )}
                      </th>
                    );
                })}

                {this.state.listType?.map((time) => {
                  if (time.value > 0)
                    return (
                      <th colSpan={this.state.dataGroupReturn?.length} className="text-center">
                        {time.label}
                      </th>
                    );
                })}
              </tr>
              <tr>
                {this.state.listType?.map((time) => {
                  if (time.value > 0)
                    return this.state.dataGroupReturn?.map((e) => {
                      return <th className="text-center">{e.label}</th>;
                    });
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.listAd?.map((item) => {
                return (
                  <tr>
                    {this.state.listGroup?.map((group) => {
                      if (group.name != "adPriceName") return <td>{item[group.name]}</td>;
                      else
                        return (
                          <>
                            <td>{item[group.name]}</td>
                            <td>{item["adPriceTimeCode"]}</td>
                          </>
                        );
                    })}

                    {item.aggregatedData?.map((dataA) => {
                      return this.state.dataGroupReturn?.map((group) => {
                        return <td className="text-end">{this.formatNumber(parseInt(dataA[group.name]))}</td>;
                      });
                    })}
                  </tr>
                );
              })}
              <tr>
                {this.state.listGroup?.length > 0 ? (
                  <th
                    colSpan={
                      this.state.listGroup?.length +
                      this.state.listGroup?.filter((i) => i.name == "adPriceName")?.length
                    }
                  >
                    Tổng
                  </th>
                ) : null}
                {this.state.listAd[0]?.aggregatedData?.map((dataA, index) => {
                  return this.state.dataGroupReturn?.map((e) => {
                    return <th className="text-end">{this.formatNumber(parseInt(this.sumElement(e.name, index)))}</th>;
                  });
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
  renderEmpty(sum) {
    let emptyCells = [];

    for (let i = 0; i < sum; i++) {
      emptyCells.push(<td key={i}>empty</td>);
    }

    return emptyCells;
  }
  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  Search() {
    let start = this.state.startDate;
    let end = this.state.endDate;

    if (Number(this.state.checkType < 5)) {
      this.Search_Summary(start, end);
    } else {
      this.Search_Summary_Year(start, end);
    }
  }
  SearchKey(key, B) {
    return B?.filter((e) => {
      return (
        this.getUniqueKey(
          e,
          this.state.listGroup?.map((listg) => {
            return listg.value;
          })
        ) == key
      );
    });
  }
  mergeArrays(A, B) {
    let arr = A;
    arr?.map((item) => {
      let rs = this.SearchKey(
        this.getUniqueKey(
          item,
          this.state.listGroup?.map((listg) => {
            return listg.value;
          })
        ),
        B
      );
      if (rs?.length > 0)
        return (item.aggregatedData = [
          {
            totalDiscount: item.totalDiscount,
            totalDuration: item.totalDuration,
            totalPeriod: item.totalPeriod,
            totalRevenue: item.totalRevenue,
          },
          {
            totalDiscount: rs[0].totalDiscount,
            totalDuration: rs[0].totalDuration,
            totalPeriod: rs[0].totalPeriod,
            totalRevenue: rs[0].totalRevenue,
          },
        ]);
      else
        return (item.aggregatedData = [
          {
            totalDiscount: item.totalDiscount,
            totalDuration: item.totalDuration,
            totalPeriod: item.totalPeriod,
            totalRevenue: item.totalRevenue,
          },
          {
            totalDiscount: 0,
            totalDuration: 0,
            totalPeriod: 0,
            totalRevenue: 0,
          },
        ]);
    });

    this.setState({ listAd: arr });
  }
  Search_Summary(start, end) {
    let start_ = this.state.startDate;
    let end_ = this.state.endDate;
    let arr = [];
    if (Number(this.state.checkType == 5)) {
      arr = [
        {
          value: 1,
          label: this.getDate(this.state.startDate / 1000) + "-" + this.getDate(this.state.endDate / 1000),
        },
        {
          value: 2,
          label:
            this.getDate(new Date(start.getFullYear() - 1, start.getMonth(), start.getDate()) / 1000) +
            "-" +
            this.getDate(new Date(end.getFullYear() - 1, end.getMonth(), end.getDate()) / 1000),
        },
      ];
    } else arr = [{ value: 0, label: "" }];
    let arrGroup = this.state.listGroup?.map((e) => e.value);
    this.setState({ checkload: true });

    axios
      .get(
        `/summary/year-summary?customerId=${this.state.CustomerItem?.value}&timeType=${Number(
          this.state.checkType
        )}&startDay=${parseInt(start / 1000)}&endDay=${parseInt(end / 1000)}&adPriceId=${
          this.state.AdPriceItem?.value
        }&contractId=${this.state.ContractItem?.value}&contractTypeId=${
          this.state.ContractTypeItem?.value
        }&tapeCodeId=${this.state.TapCodeItem?.value}&productId=${this.state.ProductItem?.value}&supplierId=${
          this.state.SupplierItem?.value
        }&viewType=${this.state.isCashSelected == true ? 1 : 2}&createdBy=${
          this.state.UserItem?.value
        }&groupIds=${JSON.stringify(arrGroup)}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ listAd: res.data.data });
          res.data.data[0]?.aggregatedData?.map((agg, index) => {
            if (Number(this.state.checkType < 5))
              return arr.push({
                value: index + 1,
                label: agg.time,
              });
          });

          this.setState({ listType: arr });
        } else {
          toast(res.data.errorMessage || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        }
      });
  }
  Search_Summary_Year(start, end) {
    let start_ = this.state.startDate;
    let end_ = this.state.endDate;
    let arr = [
      {
        value: 1,
        label: this.getDate(this.state.startDate / 1000) + "-" + this.getDate(this.state.endDate / 1000),
      },
      {
        value: 2,
        label:
          this.getDate(new Date(start.getFullYear() - 1, start.getMonth(), start.getDate()) / 1000) +
          "-" +
          this.getDate(new Date(end.getFullYear() - 1, end.getMonth(), end.getDate()) / 1000),
      },
    ];

    this.setState({ listType: arr });
    var A = [];
    var B = [];
    let arrGroup = this.state.listGroup?.map((e) => e.value);
    this.setState({ checkload: true });

    axios
      .get(
        `/summary/year-summary?customerId=${this.state.CustomerItem?.value}&timeType=${Number(
          this.state.checkType
        )}&startDay=${parseInt(start / 1000)}&endDay=${parseInt(end / 1000)}&adPriceId=${
          this.state.AdPriceItem?.value
        }&contractId=${this.state.ContractItem?.value}&contractTypeId=${
          this.state.ContractTypeItem?.value
        }&tapeCodeId=${this.state.TapCodeItem?.value}&productId=${this.state.ProductItem?.value}&supplierId=${
          this.state.SupplierItem?.value
        }&viewType=${this.state.isCashSelected == true ? 1 : 2}&createdBy=${
          this.state.UserItem?.value
        }&groupIds=${JSON.stringify(arrGroup)}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ list1: res.data.data }, () => {
            axios
              .get(
                `/summary/year-summary?customerId=${this.state.CustomerItem?.value}&timeType=${Number(
                  this.state.checkType
                )}&startDay=${parseInt(
                  new Date(start.getFullYear() - 1, start.getMonth(), start.getDate()) / 1000
                )}&endDay=${parseInt(
                  new Date(end.getFullYear() - 1, end.getMonth(), end.getDate()) / 1000
                )}&adPriceId=${this.state.AdPriceItem?.value}&contractId=${
                  this.state.ContractItem?.value
                }&contractTypeId=${this.state.ContractTypeItem?.value}&tapeCodeId=${
                  this.state.TapCodeItem?.value
                }&productId=${this.state.ProductItem?.value}&supplierId=${this.state.SupplierItem?.value}&viewType=${
                  this.state.isCashSelected == true ? 1 : 2
                }&createdBy=${this.state.UserItem?.value}&groupIds=${JSON.stringify(arrGroup)}`
              )
              .then((res) => {
                if (res.data.status == 200) {
                  B = res.data.data;
                  this.setState({ checkload: false });

                  this.setState({ list2: res.data.data }, () => this.mergeArrays(this.state.list1, this.state.list2));
                }
              });
          });

          this.setState({ checkload: false });
        }
      });
  }
  getDate_ = (data) => {
    if (data) {
      let date = data;
      let result = `${date?.getDate()}/${date?.getMonth() + 1}/${date?.getFullYear()}`;
      return result;
    } else return "";
  };
  getDate_M = (data) => {
    if (data) {
      let date = new Date(data * 1000);
      let result = `${date?.getMonth() + 1}/${date?.getFullYear()}`;
      return result;
    } else return "";
  };
  xport = () => {
    // swal({
    //   title: "Xác nhận xuất Danh sách tổng kết năm",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, this.listCustomerExport(), "Danh sách tổng kết năm");

        XLSX.writeFile(wb, "DS tong ket nam.xlsx");
    //   }
    // });
  };
  listCustomerExport = () => {
    const table = document.getElementById("exportListMonth");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    const colA = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
      "AL",
      "AM",
      "AN",
      "AO",
      "AP",
      "AQ",
      "AR",
      "AS",
      "AT",
      "AU",
      "AV",
      "AW",
      "AX",
      "AY",
      "AZ",
      "BA",
      "BB",
      "BC",
      "BD",
      "BE",
      "BF",
      "BG",
      "BH",
      "BI",
      "BJ",
      "BK",
      "BL",
      "BM",
      "BN",
      "BO",
      "BP",
      "BQ",
      "BR",
      "BS",
      "BT",
      "BU",
      "BV",
      "BW",
      "BX",
      "BY",
      "BZ",
      "CA",
      "CB",
      "CC",
      "CD",
      "CE",
      "CF",
      "CG",
      "CH",
      "CI",
      "CJ",
      "CK",
      "CL",
      "CM",
      "CN",
      "CO",
      "CP",
      "CQ",
      "CR",
      "CS",
      "CT",
      "CU",
      "CV",
      "CW",
      "CX",
      "CY",
      "CZ",
      "DA",
      "DB",
      "DC",
      "DD",
      "DE",
      "DF",
      "DG",
      "DH",
      "DI",
      "DJ",
      "DK",
      "DL",
      "DM",
      "DN",
      "DO",
      "DP",
      "DQ",
      "DR",
      "DS",
      "DT",
      "DU",
      "DV",
      "DW",
      "DX",
      "DY",
      "DZ",
      "EA",
      "EB",
      "EC",
      "ED",
      "EE",
      "EF",
      "EG",
      "EH",
      "EI",
      "EJ",
      "EK",
      "EL",
      "EM",
      "EN",
      "EO",
      "EP",
      "EQ",
      "ER",
      "ES",
      "ET",
      "EU",
      "EV",
      "EW",
      "EX",
      "EY",
      "EZ",
      "FA",
      "FB",
      "FC",
      "FD",
      "FE",
      "FF",
      "FG",
      "FH",
      "FI",
      "FJ",
      "FK",
      "FL",
      "FM",
      "FN",
      "FO",
      "FP",
      "FQ",
      "FR",
      "FS",
      "FT",
      "FU",
      "FV",
      "FW",
      "FX",
      "FY",
      "FZ",
      "GA",
      "GB",
      "GC",
      "GD",
      "GE",
      "GF",
      "GG",
      "GH",
      "GI",
      "GJ",
      "GK",
      "GL",
      "GM",
      "GN",
      "GO",
      "GP",
      "GQ",
      "GR",
      "GS",
      "GT",
      "GU",
      "GV",
      "GW",
      "GX",
      "GY",
      "GZ",
      "HA",
      "HB",
      "HC",
      "HD",
      "HE",
      "HF",
      "HG",
      "HH",
      "HI",
      "HJ",
      "HK",
      "HL",
      "HM",
      "HN",
      "HO",
      "HP",
      "HQ",
      "HR",
      "HS",
      "HT",
      "HU",
      "HV",
      "HW",
      "HX",
      "HY",
      "HZ",
      "IA",
      "IB",
      "IC",
      "ID",
      "IE",
      "IF",
      "IG",
      "IH",
      "II",
      "IJ",
      "IK",
      "IL",
      "IM",
      "IN",
      "IO",
      "IP",
      "IQ",
      "IR",
      "IS",
      "IT",
      "IU",
      "IV",
      "IW",
      "IX",
      "IY",
      "IZ",
      "JA",
      "JB",
      "JC",
      "JD",
      "JE",
      "JF",
      "JG",
      "JH",
      "JI",
      "JJ",
      "JK",
      "JL",
      "JM",
      "JN",
      "JO",
      "JP",
      "JQ",
      "JR",
      "JS",
      "JT",
      "JU",
      "JV",
      "JW",
      "JX",
      "JY",
      "JZ",
      "KA",
      "KB",
      "KC",
      "KD",
      "KE",
      "KF",
      "KG",
      "KH",
      "KI",
      "KJ",
      "KK",
      "KL",
      "KM",
      "KN",
      "KO",
      "KP",
      "KQ",
      "KR",
      "KS",
      "KT",
      "KU",
      "KV",
      "KW",
      "KX",
      "KY",
      "KZ",
      "LA",
      "LB",
      "LC",
      "LD",
      "LE",
      "LF",
      "LG",
      "LH",
      "LI",
      "LJ",
      "LK",
      "LL",
      "LM",
      "LN",
      "LO",
      "LP",
      "LQ",
      "LR",
      "LS",
      "LT",
      "LU",
      "LV",
      "LW",
      "LX",
      "LY",
      "LZ",
      "MA",
      "MB",
      "MC",
      "MD",
      "ME",
      "MF",
      "MG",
      "MH",
      "MI",
      "MJ",
      "MK",
      "ML",
      "MM",
      "MN",
      "MO",
      "MP",
      "MQ",
      "MR",
      "MS",
      "MT",
      "MU",
      "MV",
      "MW",
      "MX",
      "MY",
      "MZ",
      "NA",
      "NB",
      "NC",
      "ND",
      "NE",
      "NF",
      "NG",
      "NH",
      "NI",
      "NJ",
      "NK",
      "NL",
      "NM",
      "NN",
      "NO",
      "NP",
      "NQ",
      "NR",
      "NS",
      "NT",
      "NU",
      "NV",
      "NW",
      "NX",
      "NY",
      "NZ",
      "OA",
      "OB",
      "OC",
      "OD",
      "OE",
      "OF",
      "OG",
      "OH",
      "OI",
      "OJ",
      "OK",
      "OL",
      "OM",
      "ON",
      "OO",
      "OP",
      "OQ",
      "OR",
      "OS",
      "OT",
      "OU",
      "OV",
      "OW",
      "OX",
      "OY",
      "OZ",
      "PA",
      "PB",
      "PC",
      "PD",
      "PE",
      "PF",
      "PG",
      "PH",
      "PI",
      "PJ",
      "PK",
      "PL",
      "PM",
      "PN",
      "PO",
      "PP",
      "PQ",
      "PR",
      "PS",
      "PT",
      "PU",
      "PV",
      "PW",
      "PX",
      "PY",
      "PZ",
      "QA",
      "QB",
      "QC",
      "QD",
      "QE",
      "QF",
      "QG",
      "QH",
      "QI",
      "QJ",
      "QK",
      "QL",
      "QM",
      "QN",
      "QO",
      "QP",
      "QQ",
      "QR",
      "QS",
      "QT",
      "QU",
      "QV",
      "QW",
      "QX",
      "QY",
      "QZ",
      "RA",
      "RB",
      "RC",
      "RD",
      "RE",
      "RF",
      "RG",
      "RH",
      "RI",
      "RJ",
      "RK",
      "RL",
      "RM",
      "RN",
      "RO",
      "RP",
      "RQ",
      "RR",
      "RS",
      "RT",
      "RU",
      "RV",
      "RW",
      "RX",
      "RY",
      "RZ",
      "SA",
      "SB",
      "SC",
      "SD",
      "SE",
      "SF",
      "SG",
      "SH",
      "SI",
      "SJ",
      "SK",
      "SL",
      "SM",
      "SN",
      "SO",
      "SP",
      "SQ",
      "SR",
      "SS",
      "ST",
      "SU",
      "SV",
      "SW",
      "SX",
      "SY",
      "SZ",
      "TA",
      "TB",
      "TC",
      "TD",
      "TE",
      "TF",
      "TG",
      "TH",
      "TI",
      "TJ",
      "TK",
      "TL",
      "TM",
      "TN",
      "TO",
      "TP",
      "TQ",
      "TR",
      "TS",
      "TT",
      "TU",
      "TV",
      "TW",
      "TX",
      "TY",
      "TZ",
      "UA",
      "UB",
      "UC",
      "UD",
      "UE",
      "UF",
      "UG",
      "UH",
      "UI",
      "UJ",
      "UK",
      "UL",
      "UM",
      "UN",
      "UO",
      "UP",
      "UQ",
      "UR",
      "US",
      "UT",
      "UU",
      "UV",
      "UW",
      "UX",
      "UY",
      "UZ",
      "VA",
      "VB",
      "VC",
      "VD",
      "VE",
      "VF",
      "VG",
      "VH",
      "VI",
      "VJ",
      "VK",
      "VL",
      "VM",
      "VN",
      "VO",
      "VP",
      "VQ",
      "VR",
      "VS",
      "VT",
      "VU",
      "VV",
      "VW",
      "VX",
      "VY",
      "VZ",
      "WA",
      "WB",
      "WC",
      "WD",
      "WE",
      "WF",
      "WG",
      "WH",
      "WI",
      "WJ",
      "WK",
      "WL",
      "WM",
      "WN",
      "WO",
      "WP",
      "WQ",
      "WR",
      "WS",
      "WT",
      "WU",
      "WV",
      "WW",
      "WX",
      "WY",
      "WZ",
      "XA",
      "XB",
      "XC",
      "XD",
      "XE",
      "XF",
      "XG",
      "XH",
      "XI",
      "XJ",
      "XK",
      "XL",
      "XM",
      "XN",
      "XO",
      "XP",
      "XQ",
      "XR",
      "XS",
      "XT",
      "XU",
      "XV",
      "XW",
      "XX",
      "XY",
      "XZ",
      "YA",
      "YB",
      "YC",
      "YD",
      "YE",
      "YF",
      "YG",
      "YH",
      "YI",
      "YJ",
      "YK",
      "YL",
      "YM",
      "YN",
      "YO",
      "YP",
      "YQ",
      "YR",
      "YS",
      "YT",
      "YU",
      "YV",
      "YW",
      "YX",
      "YY",
      "YZ",
      "ZA",
      "ZB",
      "ZC",
      "ZD",
      "ZE",
      "ZF",
      "ZG",
      "ZH",
      "ZI",
      "ZJ",
      "ZK",
      "ZL",
      "ZM",
      "ZN",
      "ZO",
      "ZP",
      "ZQ",
      "ZR",
      "ZS",
      "ZT",
      "ZU",
      "ZV",
      "ZW",
      "ZX",
      "ZY",
      "ZZ",
      "AAA",
      "AAB",
      "AAC",
      "AAD",
      "AAE",
      "AAF",
      "AAG",
      "AAH",
      "AAI",
      "AAJ",
      "AAK",
      "AAL",
      "AAM",
      "AAN",
      "AAO",
      "AAP",
      "AAQ",
      "AAR",
      "AAS",
      "AAT",
      "AAU",
      "AAV",
      "AAW",
      "AAX",
      "AAY",
      "AAZ",
      "ABA",
      "ABB",
      "ABC",
      "ABD",
      "ABE",
      "ABF",
      "ABG",
      "ABH",
      "ABI",
      "ABJ",
      "ABK",
      "ABL",
      "ABM",
      "ABN",
      "ABO",
      "ABP",
      "ABQ",
      "ABR",
      "ABS",
      "ABT",
      "ABU",
      "ABV",
      "ABW",
      "ABX",
      "ABY",
      "ABZ",
      "ACA",
      "ACB",
      "ACC",
      "ACD",
      "ACE",
      "ACF",
      "ACG",
      "ACH",
      "ACI",
      "ACJ",
      "ACK",
      "ACL",
      "ACM",
      "ACN",
      "ACO",
      "ACP",
      "ACQ",
      "ACR",
      "ACS",
      "ACT",
      "ACU",
      "ACV",
      "ACW",
      "ACX",
      "ACY",
      "ACZ",
      "ADA",
      "ADB",
      "ADC",
      "ADD",
      "ADE",
      "ADF",
      "ADG",
      "ADH",
      "ADI",
      "ADJ",
      "ADK",
      "ADL",
      "ADM",
      "ADN",
      "ADO",
      "ADP",
      "ADQ",
      "ADR",
      "ADS",
      "ADT",
      "ADU",
      "ADV",
      "ADW",
      "ADX",
      "ADY",
      "ADZ",
      "AEA",
      "AEB",
      "AEC",
      "AED",
      "AEE",
      "AEF",
      "AEG",
      "AEH",
      "AEI",
      "AEJ",
      "AEK",
      "AEL",
      "AEM",
      "AEN",
      "AEO",
      "AEP",
      "AEQ",
      "AER",
      "AES",
      "AET",
      "AEU",
      "AEV",
      "AEW",
      "AEX",
      "AEY",
      "AEZ",
      "AFA",
      "AFB",
      "AFC",
      "AFD",
      "AFE",
      "AFF",
      "AFG",
      "AFH",
      "AFI",
      "AFJ",
      "AFK",
      "AFL",
      "AFM",
      "AFN",
      "AFO",
      "AFP",
      "AFQ",
      "AFR",
      "AFS",
      "AFT",
      "AFU",
      "AFV",
      "AFW",
      "AFX",
      "AFY",
      "AFZ",
      "AGA",
      "AGB",
      "AGC",
      "AGD",
      "AGE",
      "AGF",
      "AGG",
      "AGH",
      "AGI",
      "AGJ",
      "AGK",
      "AGL",
      "AGM",
      "AGN",
      "AGO",
      "AGP",
      "AGQ",
      "AGR",
      "AGS",
      "AGT",
      "AGU",
      "AGV",
      "AGW",
      "AGX",
      "AGY",
      "AGZ",
      "AHA",
      "AHB",
      "AHC",
      "AHD",
      "AHE",
      "AHF",
      "AHG",
      "AHH",
      "AHI",
      "AHJ",
      "AHK",
      "AHL",
      "AHM",
      "AHN",
      "AHO",
      "AHP",
      "AHQ",
      "AHR",
      "AHS",
      "AHT",
      "AHU",
      "AHV",
      "AHW",
      "AHX",
      "AHY",
      "AHZ",
      "AIA",
      "AIB",
      "AIC",
      "AID",
      "AIE",
      "AIF",
      "AIG",
      "AIH",
      "AII",
      "AIJ",
      "AIK",
      "AIL",
      "AIM",
      "AIN",
      "AIO",
      "AIP",
      "AIQ",
      "AIR",
      "AIS",
      "AIT",
      "AIU",
      "AIV",
      "AIW",
      "AIX",
      "AIY",
      "AIZ",
      "AJA",
      "AJB",
      "AJC",
      "AJD",
      "AJE",
      "AJF",
      "AJG",
      "AJH",
      "AJI",
      "AJJ",
      "AJK",
      "AJL",
      "AJM",
      "AJN",
      "AJO",
      "AJP",
      "AJQ",
      "AJR",
      "AJS",
      "AJT",
      "AJU",
      "AJV",
      "AJW",
      "AJX",
      "AJY",
      "AJZ",
      "AKA",
      "AKB",
      "AKC",
      "AKD",
      "AKE",
      "AKF",
      "AKG",
      "AKH",
      "AKI",
      "AKJ",
      "AKK",
      "AKL",
      "AKM",
      "AKN",
      "AKO",
      "AKP",
      "AKQ",
      "AKR",
      "AKS",
      "AKT",
      "AKU",
      "AKV",
      "AKW",
      "AKX",
      "AKY",
      "AKZ",
      "ALA",
      "ALB",
      "ALC",
      "ALD",
      "ALE",
      "ALF",
      "ALG",
      "ALH",
      "ALI",
      "ALJ",
      "ALK",
      "ALL",
    ];
    const colAlpha = colA.slice(
      0,
      this.state.listGroup?.length +
        this.state.dataGroupReturn?.length * (this.state.listType.length - 1) +
        (this.state.checkType == 5 ? 1 : 0) * this.state.dataGroupReturn?.length * (this.state.listType.length - 1) +
        Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
    );

    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    for (let i = 3; i < this.state.listAd?.length + 3; i++) {
      colA.slice(0, this.state.listGroup?.length + Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)).map((alpha) => {
        if (ws[`${alpha}${2 + i}`].v == "empty") {
          ws[`${alpha}${2 + i}`].v = " ";
        }

        ws[`${alpha}${2 + i}`].s = {
          font: {
            name: "Times New Roman",
            // sz: 11
          },
          alignment: {
            wrapText: false,
            vertical: "center",
            horizontal: "left",
            // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
      colA
        .slice(
          this.state.listGroup?.length + Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
          this.state.dataGroupReturn?.length * (this.state.listType?.length - 1) +
            this.state.listGroup?.length +
            (this.state.checkType == 5 ? 1 : 0) *
              this.state.dataGroupReturn?.length *
              (this.state.listType.length - 1) +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
        )
        ?.map((alpha) => {
          if (ws[`${alpha}${2 + i}`].v == "empty") {
            ws[`${alpha}${2 + i}`].v = " ";
          }
          ws[`${alpha}${i + 2}`].z = "#,###,###,###";
          delete ws[`${alpha}${i + 2}`].w;
          XLSX.utils.format_cell(ws[`${alpha}${i + 2}`]);
          ws[`${alpha}${2 + i}`].s = {
            font: {
              name: "Times New Roman",
              // sz: 11
            },
            alignment: {
              wrapText: false,
              vertical: "center",
              horizontal: "right",
              // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        });
    }
    colA
      .slice(
        this.state.listGroup?.length + Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
        this.state.dataGroupReturn?.length * (this.state.listType?.length - 1) +
          this.state.listGroup?.length +
          (this.state.checkType == 5 ? 1 : 0) * this.state.dataGroupReturn?.length * (this.state.listType.length - 1) +
          Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
      )
      ?.map((alpha) => {
        ws[`${alpha}${this.state.listAd?.length + 5}`] = {
          f: `=SUM(${alpha}5:${alpha}${this.state.listAd.length + 4})`,
        };
        ws[`${alpha}${this.state.listAd?.length + 5}`].z = "#,###,###,###";
        delete ws[`${alpha}${this.state.listAd?.length + 5}`].w;
        XLSX.utils.format_cell(ws[`${alpha}${this.state.listAd?.length + 5}`]);
      });
    ws["!cols"] = [
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws["!rows"] = [];

    ws[`A${this.state.listAd?.length + 5}`].v = "Tổng";
    colA
      .slice(
        0,
        this.state.dataGroupReturn?.length * (this.state.listType?.length - 1) +
          this.state.listGroup?.length +
          (this.state.checkType == 5 ? 1 : 0) * this.state.dataGroupReturn?.length * (this.state.listType.length - 1) +
          Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
      )
      ?.map((alpha) => {
        ws[`${alpha}${this.state.listAd?.length + 5}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      });

    const mergePositions = [
      {
        s: { c: 0, r: this.state.listAd?.length + 4 },
        e: {
          c: this.state.listGroup?.length - 1 + Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
          r: this.state.listAd?.length + 4,
        },
      },
    ];
    this.state.listGroup?.map((item, index) => {
      if (!this.findCellByValue(ws, "Mã giờ")) mergePositions.push({ s: { c: index, r: 2 }, e: { c: index, r: 3 } });
      else {
        mergePositions.push({ s: { c: index, r: 2 }, e: { c: index, r: 3 } });
        mergePositions.push({ s: { c: index + 1, r: 2 }, e: { c: index + 1, r: 3 } });
      }
    });
    this.state.listType?.map((item, index) => {
      mergePositions.push({
        s: {
          c:
            this.state.listGroup?.length +
            this.state.dataGroupReturn?.length * index +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
          r: 2,
        },
        e: {
          c:
            this.state.listGroup?.length +
            this.state.dataGroupReturn.length * (index + 1) -
            1 +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
          r: 2,
        },
      });
    });

    for (let i = 0; i <= 1; i++) {
      mergePositions.push({
        s: { c: 0, r: i },
        e: { c: this.state.listGroup.length + this.state.listType.length - 2, r: i },
      });
    }

    ws["!merges"] = mergePositions;

    ws[`A1`].s = {
      font: { name: "Times New Roman", sz: 16, bold: true },
      alignment: { vertical: "center", horizontal: "center" },
    };

    ws[`A2`].s = {
      font: { name: "Times New Roman", sz: 13 },
      alignment: { horizontal: "center", vertical: "top" },
    };

    ws["!rows"][1] = { hpt: 25 };

    return ws;
  };
  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (data?.hasOwnProperty(cellRef) && data[cellRef].t === "s" && data[cellRef].v == targetValue) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };
  renderRoleList() {
    const renderSearchFilter = () => {
      return (
        <Row>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 9 }}>
            <Select
              defaultValue={this.state.listContractType[0]}
              options={this.state.listContractType}
              placeholder="Chọn loại hợp đồng"
              value={this.state.ContractTypeItem}
              onChange={(choice) => {
                this.setState({ ContractTypeItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 8 }}>
            <Select
              defaultValue={this.state.listCustomer[0]}
              options={this.state.listCustomer}
              placeholder="Chọn khách hàng"
              value={this.state.CustomerItem}
              onChange={(choice) => {
                this.setState({
                  CustomerItem: choice,
                  ContractItem: { value: 0, label: "Chọn hợp đồng" },
                });
                this.getAllContract(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 7 }}>
            <Select
              defaultValue={this.state.listContract[0]}
              options={this.state.listContract}
              placeholder="Chọn hợp đồng"
              value={this.state.ContractItem}
              onChange={(choice) => {
                this.setState({ ContractItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 6 }}>
            <Select
              // defaultValue={this.state.listSupplier[0]}
              options={this.state.listSupplier}
              placeholder="Chọn nhà cung cấp"
              value={this.state.SupplierItem}
              onChange={(choice) => {
                this.setState({
                  SupplierItem: choice,
                  ProductItem: { value: 0, label: "Chọn sản phẩm" },
                  TapCodeItem: { value: 0, label: "Chọn mã băng" },
                });
                this.getAllProduct(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 5 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listProduct}
              placeholder="Chọn sản phẩm"
              value={this.state.ProductItem}
              onChange={(choice) => {
                this.setState({
                  ProductItem: choice,
                  TapCodeItem: { value: 0, label: "Chọn mã băng" },
                });

                this.getAllTapeCode(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 4 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listTapCode}
              placeholder="Chọn mã băng"
              value={this.state.TapCodeItem}
              onChange={(choice) => {
                this.setState({ TapCodeItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 3 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listAdPrice}
              placeholder="Chọn block"
              value={this.state.AdPriceItem}
              onChange={(choice) => {
                this.setState({ AdPriceItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 2 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listUser}
              placeholder="Chọn nhân viên"
              value={this.state.UserItem}
              onChange={(choice) => {
                this.setState({ UserItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12 d-flex align-items-center  ">
            <label>Thông báo</label>
            <Form.Check
              type="switch"
              id="custom-switch-cash"
              className="ms-2"
              checked={this.state.isCashSelected}
              onChange={() =>
                this.setState(
                  {
                    isCashSelected: true,
                  },
                  () => {
                    this.getAllContract(this.state.CustomerItem?.value);

                    this.getAllCustomer();
                    this.getAllAdPrice(this.state.startDate / 1000, this.state.endDate / 1000);
                  }
                )
              }
            />

            <label className="ms-3">Quảng cáo</label>
            <Form.Check
              type="switch"
              id="custom-switch-transfer"
              className="ms-2"
              checked={!this.state.isCashSelected}
              onChange={() =>
                this.setState(
                  {
                    isCashSelected: false,
                  },
                  () => {
                    this.getAllContract(this.state.CustomerItem?.value);

                    this.getAllCustomer();
                    this.getAllAdPrice(this.state.startDate / 1000, this.state.endDate / 1000);
                  }
                )
              }
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12 " style={{ zIndex: 2, display: "contents" }}>
            <span className="mt-2 me-2 ms-2">Dữ liệu phân tích</span>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(select) => {
                this.setState({
                  listGroup: select,
                });
              }}
              defaultValue={[this.state.dataGroup[1]]}
              isMulti
              placeholder="Dữ liệu phân tích"
              options={this.state.dataGroup}
              className="mb-2"
            />
          </Col>
          <Col className="mb-2 col-md-5 col-12 col-sm-12 " style={{ zIndex: 2, display: "contents" }}>
            <span className="mt-2 me-2 ms-2">Dữ liệu tổng hợp</span>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(select) => {
                this.setState({
                  dataGroupReturn: select,
                });
              }}
              defaultValue={[this.state.dataG[1]]}
              isMulti
              placeholder="Dữ liệu tổng hợp"
              options={this.state.dataG}
            />
          </Col>
          <Col
            className="mb-1 col-md-6 col-12 col-sm-12 mt-2"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Form.Check
              inline
              label="Theo tuần"
              name="group1"
              id={`inline-1`}
              onChange={() => this.setState({ checkType: 1 })}
              checked={this.state.checkType == "1"}
            />
            <Form.Check
              inline
              label="Theo tháng"
              name="group2"
              id={`inline-2`}
              onChange={() => this.setState({ checkType: 2 })}
              checked={this.state.checkType == "2"}
            />
            <Form.Check
              inline
              label="Theo quý"
              name="group3"
              id={`inline-3`}
              onChange={() => this.setState({ checkType: 3 })}
              checked={this.state.checkType == "3"}
            />
            <Form.Check
              inline
              label="Theo năm"
              id={`inline-4`}
              onChange={() => this.setState({ checkType: 4 })}
              checked={this.state.checkType == "4"}
            />
            <Form.Check
              inline
              label="Cùng kỳ"
              id={`inline-5`}
              onChange={() => this.setState({ checkType: 5 })}
              checked={this.state.checkType == "5"}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-1 col-sm-1">
            <Button variant="outline-secondary" className="mt-1" size="md" onClick={() => this.Search()}>
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {/* {new Check().permission(['63']) ? */}
          {/* <Button type="button" variant="success" size="sm" style={{ float: "right" }} onClick={() => this.setState({ modalRoleShow: true })} >
            <i className="fa-solid fa-plus"></i> Thêm
          </Button> */}
          {/* : null} */}
          <Card.Title className="d-md-flex align-items-center justify-content-between ">
            <div className="d-md-flex">
              <i className="fas fa-list me-1"></i> Danh sách tổng kết năm{" "}
              <div className="d-md-flex ms-md-1">
                <div className="d-flex w-100 my-md-0 my-2">
                  <div className="" style={{ zIndex: 10 }}>
                    <DatePicker
                        showWeekNumbers
                        calendarStartDay={1}
                      selected={this.state.startDate}
                      onChange={(date) =>
                        this.setState({ startDate: new Date(date.setHours(0, 0, 0)) }, () =>
                          this.getAllAdPrice(
                            new Date(date.setHours(0, 0, 0)) / 1000,
                            new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000
                          )
                        )
                      }
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>{" "}
                  <div className="ms-1" style={{ zIndex: 10 }}>
                    <DatePicker
                        showWeekNumbers
                        calendarStartDay={1}
                      selected={this.state.endDate}
                      onChange={(date) =>
                        this.setState({ endDate: new Date(date.setHours(23, 59, 59)) }, () => {
                          this.getAllAdPrice(
                            new Date(this.state.startDate?.setHours(0, 0, 0)) / 1000,

                            new Date(date.setHours(23, 59, 59)) / 1000
                          );
                        })
                      }
                      dateFormat="dd/MM/yyyy"
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              type="button"
              variant="success"
              size="sm"
              onClick={() => this.xport()}
              className="me-2"
              disabled={this.state.listAd?.length < 1}
              // hidden
            >
              <i className="fa-solid fa-file-export"></i> Xuất Danh sách tổng kết năm
            </Button>
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Quản trị</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh sách tổng kết năm</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                selected={this.state.start_}
                onChange={(update) => {
                  this.setState({ start_: update[0] });
                  this.setState({ end_: update[1] });
                }}
                startDate={this.state.start_}
                endDate={this.state.end_}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
                // this.getListMealstudent(this.state.startDate, this.state.endDate)
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={this.state.start_ === null || this.state.end_ === null}
              onClick={() => {
                this.setState({ show: false });
                this.setState({
                  startDate: new Date(this.state.start_.setHours(0, 0, 0)),
                });
                this.setState({
                  endDate: new Date(this.state.end_?.setHours(23, 59, 59)),
                });
                this.getAllAdPrice(
                  new Date(this.state.start_.setHours(0, 0, 0)) / 1000,
                  new Date(this.state.end_?.setHours(23, 59, 59)) / 1000
                );
                // this.getListMealstudent(this.state.start_, this.state.end_)
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default YearSummaryList;
