import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb, InputGroup, Form } from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import ProductFormModal from "./ProductFormModal";
import Check from "../other/Check";
import Select from "react-select";
class ProductIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        supplierid: 0,
        supplier: "Chọn nhà cung cấp",
      },
      selectedRows: [],
      checkload: true,
      keyword: "",
      listSupplier: [],
      productSearch: {
        value: 0,
        label: "Tất cả nhà cung cấp",
      },
    };
  }

  componentDidMount() {
    document.title = "Sản phẩm";
    this.getRoles();
    this.getAllSupplier();
  }

  getRoles = () => {
    axios.get(`/product?page=1&limit=100`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ roles: res.data.data });
      }
    });
  };
  getAllSupplier = () => {
    axios.get(`/supplier`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ listSupplier: res.data.data });
      }
    });
  };

  getRole = (item) => {
    if (item !== "null") {
      // axios.post(`/viewRole?id_role=${id}`).then((res) => {
      //   if (res.data.status === true) {
      //     const data = res.data.data[0];
      this.setState({
        role: {
          id: item.id,
          role_name: item.product?.name,
          supplierid: item.supplier?.id,
          supplier: item.supplier?.name,
          // is_rank: data.is_rank,
          // permission: data.permission !== null ? data.permission.split(',') : [],
        },
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa sản phẩm?",
      text: `"${item.product?.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/product/${item.product?.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  dataTable() {
    const columns = [
      {
        name: "Tên sản phẩm",
        selector: (row) => row.role_name,
        cell: (row) => (
          <div style={{ width: "100%", fontWeight: "normal" }} className="text-wrap">
            {row.role_name}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Nhà cung cấp",
        selector: (row) => row.supplier,
        sortable: true,
        cell: (row) => <div style={{ fontWeight: "normal" }}>{row.supplier}</div>,
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updateAt,
        // sortable: true,
        cell: (row) => <div style={{ fontWeight: "normal" }}>{row.updateAt}</div>,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.roles.length > 0) {
      this.state.roles.map((item, i) => {
        if (item.is_rank !== 1) {
          data.push({
            id: item.id,
            // is_rank: item.is_rank,
            // permission: item.permission,
            updateAt: this.getDate(item.updatedAt),
            role_name: item.product?.name,
            supplier: item.supplier?.name,
            supplierid: item.supplier?.id,
            setting: (
              <>
                {new Check().permission(["24"]) ? (
                  <Button
                    size="sm"
                    variant="warning m-1"
                    type="button"
                    onClick={() => this.getRole(item)}
                    title="Chi tiết sản phẩm"
                  >
                    <i className="fa-solid fa-pencil"></i>
                  </Button>
                ) : null}
                {new Check().permission(["25"]) ? (
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    className="m-1"
                    onClick={() => this.handleDelete(item)}
                    title="Xóa sản phẩm"
                    disabled={item.product?.isNotDelete}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                ) : null}
              </>
            ),
          });
        }

        return item;
      });
    }

    return <DataTables data={data} columns={columns} selectedRows={selectedRows} />;
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };
  getDate = (data) => {
    if (data != 0) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()
      );
    } else return "";
    // const date = new Date(data * 1000);
    // // date.setHours(0, 0, 0, 0);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() +" "+
    // ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0'+date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  Search = () => {
    axios
      .get(
        `/product/search?name=${this.state.keyword?.replace(/\s+/g, " ")?.trim()}&supplierId=${
          this.state.productSearch?.value
        }`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ roles: res.data.data });
        }
      });
  };
  renderSearchFilter = () => {
    let options = [];
    this.state.listSupplier?.map((item) => {
      options.push({
        value: item.id,
        label: item?.name,
      });

      return item;
    }, options.unshift({ value: 0, label: "Tất cả nhà cung cấp" }));

    return (
      <Row>
        <Col md={3} xs={5} className="mb-2">
          <InputGroup>
            <Form.Control
              style={{ height: "39px" }}
              onChange={(event) => this.setState({ keyword: event.target.value })}
              value={this.state.keyword}
              placeholder="Nhập tên sản phẩm"
            />
          </InputGroup>
        </Col>

        <Col md={3} xs={5} className="mb-2" style={{ zIndex: "2" }}>
          <Select
            defaultValue={options[0]}
            options={options}
            placeholder="Chọn nhà cung cấp"
            value={this.state.productSearch}
            onChange={(choice) => {
              this.setState({ productSearch: choice });
            }}
          />
        </Col>
        <Col md={4} xs={1}>
          <Button variant="outline-secondary" className="mt-1" onClick={() => this.Search()}>
            <i className="fa-solid fa-search"></i>
          </Button>
        </Col>
      </Row>
    );
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      // this.getRoles();
      this.Search();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(["23"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách sản phẩm
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {this.renderSearchFilter()}
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <ProductFormModal
              show={this.state.modalRoleShow}
              onHide={modalClose}
              modal={
                this.state.role.id === ""
                  ? {
                      title: "Thêm sản phẩm",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết sản phẩm",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Danh mục</Breadcrumb.Item>
            <Breadcrumb.Item active>Sản phẩm</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ProductIndex;
