import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Tabs,
  Tab,
  InputGroup,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
class SpotDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        name: "",
        timeCode: "",
        price: "",
        unit: "",
        priceType: 0,
        timeStart: "12:00",
        timeEnd: "",
        dayApply: "",
        dayEnd: "",
      },
      selectedRows: [],
      checkload: true,
      priceType: 0,
      listAd: [],
      show: false,
      start_: new Date(),
      startDate: new Date(),
      // new Date(),
      endDate: new Date(),
      end_: new Date(),
      keyword: "",
      show: false,
      listCustomer: [],
      CustomerItem: { value: 0, label: "Chọn khách hàng" },
      listProduct: [],
      ProductItem: { value: 0, label: "Chọn sản phẩm" },

      listContract: [],
      ContractItem: { value: 0, label: "Chọn hợp đồng" },
      listTapCode: [],
      TapCodeItem: { value: 0, label: "Chọn mã băng" },
      listAdPrice: [],
      AdPriceItem: { value: 0, label: "Chọn block" },
    };
  }

  componentDidMount() {
    document.title = "Chi tiết Spot";
    this.getRoles();
    this.getAllCustomer();
    this.getAllContract(0);
    this.getAllProduct();
    this.getAllTapeCode(0);
    this.getAllAdPrice(
      new Date(new Date().setHours(0, 0, 0)) / 1000,
      new Date(new Date().setHours(23, 59, 59)) / 1000
    );
    this.setState({ startDate: new Date(new Date().setHours(0, 0, 0)) });
    this.setState({ endDate: new Date(new Date().setHours(23, 59, 59)) });
  }
  getAllCustomer = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn khách hàng" });
    axios
      .get(`/customer?page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listCustomer: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
  getAllProduct = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn sản phẩm" });
    axios
      .get(`/product?page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.product?.name });
          });
          this.setState({
            listProduct: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContract = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn hợp đồng" });
    axios
      .get(`/contract/?customerId=${id}&contractView=0`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.contractCode });
          });
          this.setState({
            listContract: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllTapeCode = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn mã băng" });
    axios
      .get(`/tape-code?productId=${id}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listTapCode: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllAdPrice = (start, end) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn block" });
    axios
      .get(`/ad-price/search?dayApply=${start}&dayEnd=${end}`)
      .then((res) => {
        if (res.data.status == 200) {
          res.data.data?.notification?.map((item) => {
            arr.push({
              value: item?.id,
              label: item?.timeCode + " - " + item?.name,
            });
          });
          res.data.data?.ad?.map((item) => {
            arr.push({
              value: item?.id,
              label: item?.timeCode + " - " + item?.name,
            });
          });
          res.data.data?.social?.map((item) => {
            arr.push({
              value: item?.id,
              label: item?.timeCode + " - " + item?.name,
            });
          });
          this.setState({
            listAdPrice: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getRoles = () => {
    axios.get(`/ad-price`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        // this.setState({ listAd: res.data.data });
      }
    });
  };

  getRole = (item) => {
    if (item !== "null") {
      this.setState({
        role: item,
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa bảng giá?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/ad-price/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    if (data != null) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
    //  + " " +
    //   ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  sumValue = () => {
    let sum = 0;
    this.state.listAd?.map((item) => {
      sum += item?.adPrice?.realPrice;
    });
    return sum;
  };
  sumDura = () => {
    let sum = 0;
    this.state.listAd?.map((item) => {
      sum += item?.tapeCode?.duration;
    });
    return sum;
  };
  dataTable() {
    return (
      <div style={{ overflow: "auto" }}>
        <Table striped bordered style={{ border: "1px solid #ccc" }}>
          <thead>
            <tr>
              <th colSpan={4} className="text-center">
                {" "}
                Sản phẩm
              </th>
              <th colSpan={3} className="text-center">
                {" "}
                Khách hàng
              </th>
              <th colSpan={6} className="text-center">
                Giá trị
              </th>
            </tr>
            <tr style={{ whiteSpace: "nowrap" }}>
              <th>Sản phẩm </th>
              <th>Mã băng</th>
              <th>TVC</th>
              <th>TL</th>
              <th>Khách hàng</th>
              <th>Số HĐ</th>
              <th>Mã lịch</th>
              <th>Tổng cộng</th>
              <th>Giá trị </th>
              <th>GG (%)</th>
              <th>Ngày</th>
              <th>Mã giờ</th>
              <th>Block QC</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listAd?.map((item) => {
              return (
                <tr>
                  <td>{item?.tapeCode?.product?.name}</td>
                  <td>{item?.tapeCode?.code}</td>
                  <td>{item?.tapeCode?.name}</td>
                  <td>{item?.tapeCode?.duration}</td>
                  <td>{item?.customer?.name}</td>
                  <td>{item?.contract?.contractCode}</td>
                  <td>{item?.schedule?.calendarCode}</td>
                  <td className="text-end">
                    {this.formatNumber(parseInt(item?.adPrice?.realPrice))}
                  </td>
                  <td className="text-end">
                    {this.formatNumber(parseInt(item?.adPrice?.realPrice))}
                  </td>

                  <td>{item?.contract?.percentDiscount}</td>
                  <td>{this.getDate(item?.date)}</td>
                  <td>{item?.adPrice?.name}</td>
                  <td>{item?.adPrice?.timeCode}</td>
                </tr>
              );
            })}
            <tr>
              <th colSpan={3} className="text-center">
                Tổng
              </th>
              <th>{this.formatNumber(parseInt(this.sumDura()))}</th>
              <th colSpan={3}></th>
              <th className="text-end">
                {this.formatNumber(parseInt(this.sumValue()))}
              </th>
              <th className="text-end">
                {this.formatNumber(parseInt(this.sumValue()))}
              </th>
              <th colSpan={4}></th>
            </tr>
          </tbody>
        </Table>
        <Table
          striped
          bordered
          style={{ border: "1px solid black" }}
          id="exportListSpot"
          className="d-none"
        >
          <thead>
            <tr>
              {" "}
              <th>CHI TIẾT SPOT</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>{" "}
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>{" "}
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>{" "}
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
            </tr>
            <tr>
              <th
                colSpan={
                  this.state.listGroup?.length +
                  this.state.dataGroupReturn?.length
                }
              >
                {" "}
                Từ tháng {this.state.startDate?.getMonth() + 1}/
                {this.state.startDate?.getFullYear()} đến tháng{" "}
                {this.state.endDate?.getMonth() + 1}/
                {this.state.endDate?.getFullYear()}
              </th>
            </tr>
            <tr></tr>
            <tr>
              <th className="text-center"> Sản phẩm</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>

              <th className="text-center"> Khách hàng</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center">Giá trị</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
            </tr>
            <tr>
              <th>Sản phẩm </th>
              <th>Mã băng</th>
              <th>TVC</th>
              <th>TL</th>
              <th>Khách hàng</th>
              <th>Số HĐ</th>
              <th>Mã lịch</th>
              <th>Tổng cộng</th>
              <th>Giá trị </th>
              <th>GG (%)</th>
              <th>Ngày</th>
              <th>Mã giờ</th>
              <th>Block QC</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listAd?.map((item) => {
              return (
                <tr>
                  <td>
                    {this.changeEmpty(this.chitem?.tapeCode?.product?.name)}
                  </td>
                  <td>{this.changeEmpty(item?.tapeCode?.code)}</td>
                  <td>{this.changeEmpty(item?.tapeCode?.name)}</td>
                  <td>{this.changeEmpty(item?.tapeCode?.duration)}</td>
                  <td>{this.changeEmpty(item?.customer?.name)}</td>
                  <td>{this.changeEmpty(item?.contract?.contractCode)}</td>
                  <td>{this.changeEmpty(item?.schedule?.calendarCode)}</td>
                  <td className="text-end">
                    {this.changeEmpty(parseInt(item?.adPrice?.realPrice))}
                  </td>
                  <td className="text-end">
                    {this.changeEmpty(parseInt(item?.adPrice?.realPrice))}
                  </td>

                  <td>{this.changeEmpty(item?.contract?.percentDiscount)}</td>
                  <td>{this.changeEmpty(this.getDate(item?.date))}</td>
                  <td>{this.changeEmpty(item?.adPrice?.name)}</td>
                  <td>{this.changeEmpty(item?.adPrice?.timeCode)}</td>
                </tr>
              );
            })}
            <tr>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th>{parseInt(this.sumDura())}</th>

              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-end">{parseInt(this.sumValue())}</th>
              <th className="text-end">{parseInt(this.sumValue())}</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
              <th className="text-center"> Empty</th>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  Search() {
    axios
      .get(
        `/summary/spots-detail?startDay=${parseInt(
          this.state.startDate / 1000
        )}&endDay=${parseInt(this.state.endDate / 1000)}&contractId=${
          this.state.ContractItem?.value
        }&customerId=${this.state.CustomerItem?.value}&productId=${
          this.state.ProductItem?.value
        }&tapeCodeId=${this.state.TapCodeItem?.value}&adPriceId=${
          this.state.AdPriceItem?.value
        }`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ listAd: res.data.data });
        }
      });
  }
  getDate_ = (data) => {
    if (data) {
      let date = data;
      let result = `${date?.getDate()}/${
        date?.getMonth() + 1
      }/${date?.getFullYear()}`;
      return result;
    } else return "";
  };
  xport = () => {
    // swal({
    //   title: "Xác nhận xuất chi tiết spot",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, this.listSpotExport(), "Chi tiết spot");

    XLSX.writeFile(wb, "Chi tiet spot.xlsx");
    //   }
    // });
  };
  listSpotExport = () => {
    // const table = document.getElementById("exportListSpot");
    // var ws = XLSX.utils.table_to_sheet(table, { raw: true });
    const table = document.getElementById("exportListSpot");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];
    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
    ];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        wrapText: true,
        horizontal: "center",
      },
      // border: {
      //   top: { style: "thin" },
      //   bottom: { style: "thin" },
      //   left: { style: "thin" },
      //   right: { style: "thin" },
      // },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 30
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });
    colAlpha.map((alpha) => {
      ws[`${alpha}5`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}${this.state.listAd?.length + 6}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal:
            alpha == "D" || alpha == "H" || alpha == "I" || alpha == "J"
              ? "right"
              : "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });
    for (let i = 1; i <= this.state.listAd?.length + 6; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`]?.v == "Empty") {
          ws[`${alpha}${i}`].v = " ";
        }
      });
    }

    for (let i = 6; i < this.state.listAd?.length + 6; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v == "Empty") {
          ws[`${alpha}${i}`].v = " ";
        }
        if (alpha == "D" || alpha == "H" || alpha == "I" || alpha == "J") {
          ws[`${alpha}${i}`].z = "#,###,###,###";
          delete ws[`${alpha}${i}`].w;
          XLSX.utils.format_cell(ws[`${alpha}${i}`]);
        }

        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
          },
          alignment: {
            wrapText: false,
            vertical: "center",
            horizontal:
              alpha == "D" || alpha == "H" || alpha == "I" || alpha == "J"
                ? "right"
                : "left",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }

    ws["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 5 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws["!rows"] = [{ hpt: 30 }];

    ws[`D${this.state.listAd?.length + 6}`] = {
      f: `=SUM(D4:D${this.state.listAd?.length + 5})`,
    };
    ws[`D${this.state.listAd?.length + 6}`].z = "#,###,###,###";
    delete ws[`D${this.state.listAd?.length + 6}`].w;
    XLSX.utils.format_cell(ws[`D${this.state.listAd?.length + 6}`]);

    ws[`H${this.state.listAd?.length + 6}`] = {
      f: `=SUM(H4:H${this.state.listAd?.length + 5})`,
    };
    ws[`H${this.state.listAd?.length + 6}`].z = "#,###,###,###";
    delete ws[`H${this.state.listAd?.length + 6}`].w;
    XLSX.utils.format_cell(ws[`H${this.state.listAd?.length + 6}`]);

    ws[`I${this.state.listAd?.length + 6}`] = {
      f: `=SUM(I4:I${this.state.listAd?.length + 5})`,
    };
    ws[`I${this.state.listAd?.length + 6}`].z = "#,###,###,###";
    delete ws[`I${this.state.listAd?.length + 6}`].w;

    XLSX.utils.format_cell(ws[`I${this.state.listAd?.length + 6}`]);
    ws[`D${this.state.listAd?.length + 6}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[`H${this.state.listAd?.length + 6}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[`I${this.state.listAd?.length + 6}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws[`J${this.state.listAd?.length + 6}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    ws[`A2`].s = {
      font: { name: "Times New Roman", sz: 13 },
      alignment: { horizontal: "center", vertical: "top" },
    };

    const mergePositions = [
      { s: { c: 0, r: 0 }, e: { c: 12, r: 0 } },
      { s: { c: 0, r: 1 }, e: { c: 12, r: 1 } },
      { s: { c: 0, r: 3 }, e: { c: 3, r: 3 } },
      { s: { c: 4, r: 3 }, e: { c: 6, r: 3 } },
      { s: { c: 7, r: 3 }, e: { c: 12, r: 3 } },

      // {
      //   s: { c: 0, r: this.state.listAd?.length + 5 },
      //   e: { c: 2, r: this.state.listAd?.length + 5 },
      // },
      // {
      //   s: { c: 4, r: this.state.listAd?.length + 5 },
      //   e: { c: 6, r: this.state.listAd?.length + 5 },
      // },
      // {
      //   s: { c: 10, r: this.state.listAd?.length + 5 },
      //   e: { c: 12, r: this.state.listAd?.length + 5 },
      // },
    ];

    ws["!merges"] = mergePositions;

    return ws;
  };
  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "Empty"
      : element;
  };
  renderRoleList() {
    const renderSearchFilter = () => {
      return (
        <Row>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 6 }}>
            <Select
              defaultValue={this.state.listCustomer[0]}
              options={this.state.listCustomer}
              placeholder="Chọn khách hàng"
              value={this.state.CustomerItem}
              onChange={(choice) => {
                this.setState({
                  CustomerItem: choice,
                  ContractItem: { value: 0, label: "Chọn hợp đồng" },
                });
                this.getAllContract(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 5 }}>
            <Select
              defaultValue={this.state.listContract[0]}
              options={this.state.listContract}
              placeholder="Chọn hợp đồng"
              value={this.state.ContractItem}
              onChange={(choice) => {
                this.setState({ ContractItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 4 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listProduct}
              placeholder="Chọn sản phẩm"
              value={this.state.ProductItem}
              onChange={(choice) => {
                this.setState({
                  ProductItem: choice,
                  TapCodeItem: { value: 0, label: "Chọn mã băng" },
                });
                this.getAllTapeCode(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 3 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listTapCode}
              placeholder="Chọn mã băng"
              value={this.state.TapCodeItem}
              onChange={(choice) => {
                this.setState({ TapCodeItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 2 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listAdPrice}
              placeholder="Chọn block"
              value={this.state.AdPriceItem}
              onChange={(choice) => {
                this.setState({ AdPriceItem: choice });
              }}
            />
          </Col>

          <Col className="mb-2 col-md-3 col-1 col-sm-1">
            <Button
              variant="outline-secondary"
              className="mt-1"
              size="md"
              onClick={() => this.Search()}
            >
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {/* {new Check().permission(['63']) ? */}
          {/* <Button type="button" variant="success" size="sm" style={{ float: "right" }} onClick={() => this.setState({ modalRoleShow: true })} >
            <i className="fa-solid fa-plus"></i> Thêm
          </Button> */}
          {/* : null} */}
          <Card.Title className="d-md-flex align-items-center justify-content-between ">
            <div className="d-md-flex">
              <i className="fas fa-list me-1"></i> Chi tiết spot{" "}
              <div className="d-flex mt-md-0 mt-2 ms-1">
                <div style={{ zIndex: 9 }}>
                  <DatePicker
                    showWeekNumbers
                    calendarStartDay={1}
                    selected={this.state.startDate}
                    onChange={(date) =>
                      this.setState(
                        { startDate: new Date(date.setHours(0, 0, 0)) },
                        () =>
                          this.getAllAdPrice(
                            new Date(date.setHours(0, 0, 0)) / 1000,
                            new Date(this.state.endDate?.setHours(23, 59, 59)) /
                              1000
                          )
                      )
                    }
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>{" "}
                <div style={{ zIndex: 8 }} className=" ms-1">
                  <DatePicker
                    showWeekNumbers
                    calendarStartDay={1}
                    selected={this.state.endDate}
                    onChange={(date) =>
                      this.setState(
                        { endDate: new Date(date.setHours(23, 59, 59)) },
                        () => {
                          this.getAllAdPrice(
                            new Date(this.state.startDate?.setHours(0, 0, 0)) /
                              1000,

                            new Date(date.setHours(23, 59, 59)) / 1000
                          );
                        }
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                  />
                </div>
              </div>
            </div>

            <Button
              type="button"
              variant="success"
              size="sm"
              onClick={() => this.xport()}
              className="me-2 mt-md-0 mt-2"
              disabled={this.state.listAd?.length < 1}
            >
              <i className="fa-solid fa-file-export"></i> Xuất Chi tiết spot
            </Button>
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Quản trị</Breadcrumb.Item>
            <Breadcrumb.Item active>Chi tiết Spot</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                selected={this.state.start_}
                onChange={(update) => {
                  this.setState({ start_: update[0] });
                  this.setState({ end_: update[1] });
                }}
                startDate={this.state.start_}
                endDate={this.state.end_}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
                // this.getListMealstudent(this.state.startDate, this.state.endDate)
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={this.state.start_ === null || this.state.end_ === null}
              onClick={() => {
                this.setState({ show: false });
                this.setState({
                  startDate: new Date(this.state.start_.setHours(0, 0, 0)),
                });
                this.setState({
                  endDate: new Date(this.state.end_?.setHours(23, 59, 59)),
                });
                this.getAllAdPrice(
                  new Date(this.state.start_.setHours(0, 0, 0)) / 1000,
                  new Date(this.state.end_?.setHours(23, 59, 59)) / 1000
                );
                // this.getListMealstudent(this.state.start_, this.state.end_)
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SpotDetail;
