import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Tabs,
  Tab,
  InputGroup,
  Form,
  Modal,
  Accordion,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import ScheduleModal from "./ScheduleModal";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import RegisterBlockFormModal from "../registerBlock/RegisterBlockFormModal";
class registerBlockIndex extends Component {
  constructor(props) {
    super(props);
    this.updateItem = this.updateItem.bind(this);
    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        calendarCode: "",
        id: "",
        contractId: 0,
        tapeCodeId: 0,
        broadcastStartDate: "",
        broadcastEndDate: "",
      },
      selectedRows: [],
      checkload: true,
      listproduct: [],
      priceType: 0,
      listSchedule: [],
      show: false,
      start_: new Date(),
      startDate: "",
      // new Date(),
      endDate: "",
      startDateFilter: "",
      endDateFilter: "",
      end_: new Date(),
      keyword: "",
      showRegisterBlock: false,
      itemShedule: {},
      openItems: [],
      isSocial: false,
      contractType: {},
    };
  }

  componentDidMount() {
    this.setState(
      {
        startDateFilter: new Date(this.changeStartMonth(new Date())),

        endDateFilter: new Date(this.changeEndMonth(new Date())),
      },
      () => {
        document.title = "Đăng ký quảng cáo";
        this.getContractType();
        this.getRoles(this.state.startDateFilter, this.state.endDateFilter);
      }
    );

    //  this.getAllproduct()
  }
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  getRoles = (start, end) => {
    axios
      .get(
        `/schedule/?page=&limit&broadcastStartDate=${parseInt(
          start / 1000
        )}&broadcastEndDate=${
          end / 1000
        }&tapeCodeId=&contractView=2&customerId=&createdBy=&tapeCodeTypeId=&contractId=&keyword=&createdAt=`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          let filteredData = res.data.data;
          if (this.state.isSocial) {
            filteredData = res.data.data?.map((item) => {
              return {
                ...item,
                contractList: item?.contractList?.filter(
                  (e) => e.contractTypeId == this.state.contractType?.id
                ),
              };
            });
          }
          // else

          // {
          //   filteredData = res.data.data?.map((item) => {
          //     return {
          //       ...item,
          //       contractList: item?.contractList?.filter(
          //         (e) => e.contractTypeId != this.state.contractType?.id
          //       ),
          //     };
          //   });
          // }
          this.setState({
            listSchedule: filteredData,
            openItems: filteredData.map((item) => item.id),
          });
        }
      });
  };

  getRole = (item) => {
    if (item !== "null") {
      this.setState({
        role: item,
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  getContractType = () => {
    axios.get(`/contract-type`).then((res) => {
      if (res.data.status == 200) {
        this.setState({
          contractType: res.data.data?.filter(
            (e) => e.isNotDelete == true && e.id != 1
          )[0],
        });
        // this.setState({ checkload: false });
        // this.setState({ itemShedule: res.data.data });
        // this.setState({ showRegisterBlock: true });
      }
    });
  };
  getRegisterBlock = (item) => {
    if (item !== "null") {
      // this.setState({
      //   itemShedule: item
      // });
      axios
        .get(`/schedule/${item.id}?month=${item.broadcastStartDate}`)
        .then((res) => {
          if (res.data.status == 200) {
            this.setState({ checkload: false });
            this.setState({ itemShedule: res.data.data });
            this.setState({ showRegisterBlock: true });
          }
        });
    }
    // });
  };
  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa đăng ký quảng cáo?",
      text: `"${item.calendarCode}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/schedule/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles(
                this.state.startDateFilter,
                this.state.endDateFilter
              );
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    if (data) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear() +
        " "
      );
    } else return "";

    // ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    else return "";
  }
  dataTable(DataAd) {
    const columns = [
      {
        name: "Mã lịch",
        selector: (row) => row.calendarCode,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.calendarCode}
          </div>
        ),
      },
      {
        name: "Từ ngày",
        selector: (row) => row.broadcastStartDate,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDate(row.broadcastStartDate)}
          </div>
        ),
      },
      {
        name: "Đến ngày",
        selector: (row) => row.broadcastEndDate,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDate(row.broadcastEndDate)}
          </div>
        ),
      },
      {
        name: "Mã TVC",
        selector: (row) => row.tapecode,
        // sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.tapecode}
          </div>
        ),
      },
      // {
      //   name: "Tên TVC",
      //   selector: (row) => row.tapecodeName,
      //   sortable: true,
      //   cell: (row) =>
      //     <div style={{ width: "100%" }} className="text-wrap">
      //       {row.tapecodeName}</div>,
      // },
      {
        name: "TL",
        selector: (row) => row.duration,
        // sortable: true,
        right: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap text-end">
            {row.duration}
          </div>
        ),
      },

      {
        name: "Số Spot",
        selector: (row) => row.totalSpot,
        center: true,
      },
      {
        name: "Giá trị",
        selector: (row) => row.totalPrice,
        right: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap text-end">
            {this.formatNumber(
              parseInt(
                row.totalPrice == 0 ||
                  row.totalPrice == null ||
                  row.totalPrice == undefined
                  ? 0
                  : row.totalPrice
              )
            )}
          </div>
        ),
      },
      // {
      //   name: "Người nhập",
      //   selector: (row) => row.creator,

      //   cell: (row) =>
      //   <div style={{ width: "100%" }} className="text-wrap">
      //     {row.creator}</div>,
      // },{
      //   name: "Ngày tạo",
      //   selector: (row) => row.createdAt,
      //   right: true,
      //   cell: (row) =>
      //   <div style={{ width: "100%" }} className="text-wrap text-end">
      //     {this.getDate(row.createdAt)}</div>,
      // },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
        cell: (row) => (
          <div
            style={{ width: "100%", whiteSpace: "nowrap" }}
            // className="text-nowarp"
          >
            {row.setting}
          </div>
        ),
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    // if (this.state.roles.length > 0) {
    DataAd.map((item, i) => {
      // if (item.is_rank !== 1) {
      data.push({
        id: item.id,
        calendarCode: item.calendarCode,
        broadcastStartDate: item.broadcastStartDate,
        broadcastEndDate: item.broadcastEndDate,
        tapecodeName: item?.tapeCode?.name,
        tapecode: item?.tapeCode?.code,
        duration: item?.tapeCode?.duration,
        totalSpot: item?.totalSpot,
        totalPrice: item?.totalPrice,
        creator: item?.creator?.user?.fullName,
        createdAt: item?.createdAt,
        setting: (
          <>
            <Button
              size="sm"
              variant="success me-2"
              type="button"
              //  onClick={() => this.props.navHook("/admin/register-block")}
              onClick={() => this.getRegisterBlock(item)}
              title="Đăng ký phát sóng"
            >
              <i className="fa-solid fa-list-check"></i>
            </Button>
            {new Check().permission(["61"]) ? (
              <Button
                size="sm"
                variant="warning me-2"
                type="button"
                onClick={() => this.getRole(item)}
                title="Chi tiết đăng ký quảng cáo"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
            ) : null}
            {new Check().permission(["62"]) ? (
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.handleDelete(item)}
                title="Xóa đăng ký quảng cáo"
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : null}
          </>
        ),
      });
      // }

      return item;
    });
    // }

    const totalValue = data.reduce(
      (total, record) => total + record.totalPrice,
      0
    );
    const totalDuration = data.reduce(
      (total, record) => total + record.duration,
      0
    );
    const totalSpot = data.reduce(
      (total, record) => total + record.totalSpot,
      0
    );

    const totalRow = {
      id: "total-student",
      // calendarCode: "",
      // broadcastStartDate: "",
      // broadcastEndDate: "",
      tapecode: "",
      duration: totalDuration,
      totalSpot: totalSpot,
      totalPrice: totalValue,
      setting: "",
    };
    const temporaryData = [...data, totalRow];

    return (
      <DataTables
        data={temporaryData}
        columns={columns}
        selectedRows={selectedRows}
        pagination={false}
      />
    );
  }
  renderSchedule = () => {
    return (
      <Accordion
      // activeKey={this.state.openItems}
      >
        {this.state.listSchedule?.map((item, i) => {
          if (item.contractList?.length > 0)
            return (
              <Accordion.Item
                eventKey={item.id}
                key={item.id + i}
                on={() => {
                  this.setState((prevState) => {
                    if (prevState.openItems.includes(item.id)) {
                      return {
                        openItems: prevState.openItems.filter(
                          (id) => id !== item.id
                        ),
                      };
                    } else {
                      return {
                        openItems: [...prevState.openItems, item.id],
                      };
                    }
                  });
                }}
              >
                <Accordion.Header>{item.name}</Accordion.Header>
                <Accordion.Body className="pe-0">
                  <Accordion>
                    {item.contractList?.map((contract, index) => {
                      if (contract?.scheduleList?.length > 0)
                        return (
                          <Accordion.Item
                            eventKey={contract.id}
                            key={contract + index}
                          >
                            <Accordion.Header>
                              Số HĐ : {contract.contractCode}
                            </Accordion.Header>
                            <Accordion.Body className="pe-0">
                              {this.dataTable(contract?.scheduleList)}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            );
        })}
      </Accordion>
    );
  };
  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  Search() {
    axios
      .get(
        `/ad-price/search?keyword=${this.state.keyword?.trim()}&dayApply=${
          this.state.startDate / 1000
        }&dayEnd=${this.state.endDate / 1000}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ listSchedule: res.data.data });
        }
      });
  }
  updateItem(newState) {
    this.setState({ role: newState });
  }
  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, showRegisterBlock: false });
      //here
      this.getRoles(this.state.startDateFilter, this.state.endDateFilter);
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, showRegisterBlock: false });
      this.refreshRole();
      this.getRoles(this.state.startDateFilter, this.state.endDateFilter);
    };
    const renderSearchFilter = () => {
      return (
        <Row>
          <Col className="mb-2 col-md-3 col-12 col-sm-12">
            <InputGroup>
              <Form.Control
                // style={{ height: '39px' }}
                onChange={(event) =>
                  this.setState({ keyword: event.target.value })
                }
                value={this.state.keyword}
                placeholder="Nhập ký hiệu hoặc tên block"
              />
            </InputGroup>
          </Col>{" "}
          <Col
            className="mb-2 d-flex align-items-center mb-2 col-md-5 col-10 col-sm-10"
            style={{ zIndex: 2 }}
          >
            Từ ngày
            <DatePicker
              selected={this.state.startDate}
              onChange={(date) => this.setState({ startDate: date })}
              isClearable
              className="form-control"
              dateFormat={"dd/MM/yyyy"}
              placeholderText="dd/mm/yyyy"
            />
            -{" "}
            <DatePicker
              selected={this.state.endDate}
              onChange={(date) => this.setState({ endDate: date })}
              isClearable
              className="form-control"
              dateFormat={"dd/MM/yyyy"}
              placeholderText="dd/mm/yyyy"
            />
          </Col>
          <Col className="mb-2 col-md-3 col-1 col-sm-1">
            <Button
              variant="outline-secondary"
              className="mt-1"
              size="lg"
              onClick={() => this.Search()}
            >
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {new Check().permission(["60"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title className="d-md-flex align-items-center">
            <i className="fas fa-list me-1"></i> Danh sách đăng ký quảng cáo
            <div className="d-flex">
              <div style={{ marginLeft: "10px", zIndex: 3 }}>
                <DatePicker
                  selected={this.state.startDateFilter}
                  onChange={(date) =>
                    this.setState(
                      { startDateFilter: new Date(date.setHours(0, 0, 0)) },
                      () => {
                        this.getRoles(
                          new Date(this.state.startDateFilter),
                          new Date(this.state.endDateFilter)
                        );
                      }
                    )
                  }
                  selectsStart
                  startDate={this.state.startDateFilter}
                  endDate={this.state.endDateFilter}
                  dateFormat="dd/MM/yyyy"
                />
              </div>{" "}
              <div style={{ zIndex: 2, marginLeft: "5px" }}>
                <DatePicker
                  selected={this.state.endDateFilter}
                  onChange={(date) =>
                    this.setState(
                      { endDateFilter: new Date(date.setHours(23, 59, 59)) },
                      () => {
                        this.getRoles(
                          new Date(this.state.startDateFilter),
                          new Date(this.state.endDateFilter)
                        );
                      }
                    )
                  }
                  dateFormat="dd/MM/yyyy"
                  selectsEnd
                  startDate={this.state.startDateFilter}
                  endDate={this.state.endDateFilter}
                  minDate={this.state.startDateFilter}
                />
              </div>
            </div>
            {/* Chọn thoe hơp đồng mxh */}
            <Form.Check
              inline
              label={
                this.state.contractType
                  ? `Hợp đồng: ${this.state.contractType?.name}`
                  : ""
              }
              name="group1"
              style={{ whiteSpace: "nowrap" }}
              className={`m-1 mt-2 ${this.state.contractType ? `hidden` : ""}`}
              id={`inline-1`}
              onChange={() =>
                this.setState({ isSocial: !this.state.isSocial }, () =>
                  this.getRoles(
                    this.state.startDateFilter,
                    this.state.endDateFilter
                  )
                )
              }
              checked={this.state.isSocial}
            />
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {/* {renderSearchFilter()} */}
          {this.state.checkload ? this.loading() : this.renderSchedule()}
          {/* {this.dataTable(this.state.listSchedule)} */}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <ScheduleModal
              show={this.state.modalRoleShow}
              onHide={modalClose}
              modal={
                this.state.role.id == ""
                  ? {
                      title: "Đăng ký lịch quảng cáo",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết lịch quảng cáo",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
        {this.state.showRegisterBlock ? (
          <>
            <RegisterBlockFormModal
              show={this.state.showRegisterBlock}
              onHide={modalClose}
              modal={
                this.state.role.itemShedule == ""
                  ? {
                      title: "Đăng ký lịch",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Đăng ký lịch",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.itemShedule}
              submit={modalSubmit}
              onUpdateItem={this.updateItem}
              isSocial={this.state.isSocial}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Quảng cáo</Breadcrumb.Item>
            <Breadcrumb.Item active>Đăng ký quảng cáo123</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
function myParams(Component) {
  //Back link AND use "params"
  return (props) => (
    <Component navHook={useNavigate()} {...props} params={useParams()} />
  );
}

export default myParams(registerBlockIndex);
