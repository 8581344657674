import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Tabs,
  Tab,
  InputGroup,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
class MonthlySummaryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        name: "",
        timeCode: "",
        price: "",
        unit: "",
        priceType: 0,
        timeStart: "12:00",
        timeEnd: "",
        dayApply: "",
        dayEnd: "",
      },
      selectedRows: [],
      checkload: true,
      priceType: 0,
      listAd: [],
      show: false,
      start_: new Date(),
      startDate: new Date(),
      // new Date(),
      endDate: new Date(),
      end_: new Date(),
      keyword: "",
      show: false,
      listCustomer: [],
      CustomerItem: { value: 0, label: "Chọn khách hàng" },
      listProduct: [],
      ProductItem: { value: 0, label: "Chọn sản phẩm" },

      listContract: [],
      ContractItem: { value: 0, label: "Chọn hợp đồng" },
      listTapCode: [],
      TapCodeItem: { value: 0, label: "Chọn mã băng" },
      listAdPrice: [],
      AdPriceItem: { value: 0, label: "Chọn block" },
      listSupplier: [],
      SupplierItem: { value: 0, label: "Chọn nhà cung cấp" },
      listContractType: [],
      ContractTypeItem: { value: 0, label: "Chọn loại hợp đồng" },
      listUser: [],
      UserItem: { value: 0, label: "Chọn nhân viên" },
      isCashSelected: false,
      dataGroup: [
        { value: 1, label: "Loại khách hàng", name: "customerTypeName" },
        { value: 2, label: "Khách hàng", name: "customerName" },
        { value: 3, label: "Loại hợp đồng", name: "contractTypeName" },
        { value: 4, label: "Hợp đồng", name: "contractContractCode" },
        { value: 5, label: "Mã lịch", name: "scheduleCalendarCode" },
        { value: 6, label: "Loại Spot", name: "tapeCodeTypeName" },
        { value: 7, label: "Block QC (Bảng giá)", name: "adPriceName" },
        { value: 8, label: "Nhà cung cấp", name: "supplierName" },
        { value: 9, label: "Sản phẩm", name: "productName" },
        { value: 10, label: "Mã băng", name: "tapeCodeName" },
        { value: 11, label: "Ngày", name: "broadcastDate" },
        { value: 12, label: "Tuần", name: "week" },
        { value: 13, label: "Tháng", name: "month" },
        // { value: 14, label: "Quí", name: "quarter" },
        // { value: 15, label: "Năm", name: "year" },
      ],
      listGroup: [{ value: 2, label: "Khách hàng", name: "customerName" }],
      dataGroupReturn: [{ value: 2, label: "Tổng thu", name: "totalRevenue" }],
      dataG: [
        { value: 1, label: "Số kỳ", name: "totalPeriod" },
        { value: 2, label: "Tổng thu", name: "totalRevenue" },
        // { value: 3, label: "Giảm giá", name: "totalDiscount" },
        { value: 4, label: "Thời lượng", name: "totalDuration" },
        { value: 5, label: "Thực thu", name: "totalRevenue" },
      ],
      isSortCustomer: false,
      listKeySort: [],
    };
  }

  componentDidMount() {
    document.title = "Danh sách tổng kết tháng";
    this.setState(
      {
        startDate: this.changeStartMonth(new Date()),
        endDate: this.changeEndMonth(new Date()),
      },
      () => {
        this.getRoles();
        this.getAllCustomer();
        this.getAllContract(0);
        this.getAllProduct(0);
        this.getAllSupplier();
        this.getAllContractType();
        this.getAllTapeCode(0);
        this.getAllUser();
        this.getAllAdPrice(
          this.state.startDate / 1000,
          new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000
        );
      }
    );

    // this.setState({ startDate: new Date(new Date().setHours(0, 0, 0)) })
    // this.setState({ endDate: new Date(new Date().setHours(23, 59, 59)) })
  }
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  getAllCustomer = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn khách hàng" });
    axios
      .get(
        `/customer?page=1&limit=100&type=${this.state.isCashSelected ? 1 : 2}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listCustomer: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    else return 0;
  }
  getAllProduct = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn sản phẩm" });
    axios
      .get(`/product/search?name=&supplierId=${id}&page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.product?.name });
          });

          this.setState({
            listProduct: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllSupplier = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn nhà cung cấp" });
    axios
      .get(`/supplier?page=1&limit=100`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listSupplier: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContractType = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn loại hợp đồng" });
    axios
      .get(`/contract-type`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listContractType: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllUser = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn nhân viên" });
    axios
      .get(`/user`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.fullName });
          });
          this.setState({
            listUser: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContract = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn hợp đồng" });
    axios
      .get(
        `/contract/?customerId=${id}&contractView=${
          this.state.isCashSelected == true ? 1 : 2
        }`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.contractCode });
          });
          this.setState({
            listContract: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllTapeCode = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn mã băng" });
    axios
      .get(`/tape-code?productId=${id}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listTapCode: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllAdPrice = (start, end) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn block" });
    axios
      .get(`/ad-price/search?dayApply=${start}&dayEnd=${end}`)
      .then((res) => {
        if (res.data.status == 200) {
          if (this.state.isCashSelected == true)
            res.data.data?.notification?.map((item) => {
              arr.push({
                value: item?.id,
                label: item?.timeCode + " - " + item?.name,
              });
            });
          else {
            res.data.data?.ad?.map((item) => {
              arr.push({
                value: item?.id,
                label: item?.timeCode + " - " + item?.name,
              });
            });
            res.data.data?.social?.map((item) => {
              arr.push({
                value: item?.id,
                label: item?.timeCode + " - " + item?.name,
              });
            });
          }

          this.setState({
            listAdPrice: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getRoles = () => {
    axios.get(`/ad-price`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        // this.setState({ listAd: res.data.data });
      }
    });
  };

  getRole = (item) => {
    if (item !== "null") {
      this.setState({
        role: item,
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa bảng giá?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/ad-price/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    if (data != null) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
    //  + " " +
    //   ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  sumValue = () => {
    let sum = 0;
    this.state.listAd?.map((item) => {
      sum += item?.adPrice?.realPrice;
    });
    return sum;
  };
  sumDura = () => {
    let sum = 0;
    this.state.listAd?.map((item) => {
      sum += item?.tapeCode?.duration;
    });
    return sum;
  };
  sumElement = (element, index) => {
    let sum = 0;

    this.state.listAd?.map((item) => {
      sum += item?.[`${element}`];
    });
    return sum;
  };
  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };
  Sort(key) {
    let arr = [...this.state.listAd];

    if (key === "customerName") {
      this.setState({ isSortCustomer: true }, () => {
        arr.sort((a, b) => {
          const nameA = a["customerName"] || ""; // Sử dụng giá trị mặc định nếu là null
          const nameB = b["customerName"] || "";
          return nameA.localeCompare(nameB) || this.secondarySort(a, b, key);
        });

        this.setState({ listAd: arr });
      });
    } else {
      if (this.state.isSortCustomer) {
        arr.sort((a, b) => {
          if (a.customerName === b.customerName) {
            const nameA = a["customerName"] || "";
            const nameB = b["customerName"] || "";
            return nameA.localeCompare(nameB) || this.secondarySort(a, b, key);
          }
        });
      } else {
        arr.sort((a, b) => this.secondarySort(a, b, key));
      }

      this.setState({ listAd: arr });
    }
  }

  secondarySort(a, b, key) {
    if (a[key] === null || a[key] === undefined) return 1;
    if (b[key] === null || b[key] === undefined) return -1;

    if (
      key === "broadcastDate" ||
      key === "week" ||
      key === "quarter" ||
      key === "month" ||
      key === "year"
    ) {
      return a[key] - b[key];
    } else {
      return a[key]?.localeCompare(b[key]) || 0;
    }
  }

  SortReverse(key) {
    let arr = [...this.state.listAd];

    if (key === "customerName") {
      this.setState({ isSortCustomer: true }, () => {
        arr.sort((a, b) => {
          const nameA = b["customerName"] || "";
          const nameB = a["customerName"] || "";
          return (
            nameA.localeCompare(nameB) || this.secondarySortReverse(a, b, key)
          );
        });

        this.setState({ listAd: arr });
      });
    } else {
      if (this.state.isSortCustomer === true) {
        arr.sort((a, b) => {
          if (a.customerName === b.customerName) {
            const nameA = b["customerName"] || "";
            const nameB = a["customerName"] || "";
            return (
              nameA.localeCompare(nameB) || this.secondarySortReverse(a, b, key)
            );
          }
        });
      } else {
        console.log(">>>>run");
        arr.sort((a, b) => this.secondarySortReverse(a, b, key));
      }

      this.setState({ listAd: arr });
    }
  }

  secondarySortReverse(a, b, key) {
    if (a[key] === null || a[key] === undefined) return -1;
    if (b[key] === null || b[key] === undefined) return 1;

    if (
      key === "broadcastDate" ||
      key === "week" ||
      key === "quarter" ||
      key === "month" ||
      key === "year"
    ) {
      return b[key] - a[key];
    } else {
      return b[key]?.localeCompare(a[key]) || 0;
    }
  }
  dataTable() {
    return (
      <div style={{ overflow: "auto" }}>
        <Table
          striped
          bordered
          style={{ border: "1px solid black" }}
          id="exportListMonth"
          className="d-none"
        >
          <thead>
            <tr>
              <th
                colSpan={
                  this.state.listGroup?.length +
                  this.state.dataGroupReturn?.length
                }
              >
                {" "}
                BẢNG TK THÁNG{" "}
              </th>
            </tr>
            <tr>
              <th
                colSpan={
                  this.state.listGroup?.length +
                  this.state.dataGroupReturn?.length
                }
              >
                {" "}
                Từ tháng {this.state.startDate?.getMonth() + 1}/
                {this.state.startDate?.getFullYear()} đến tháng{" "}
                {this.state.endDate?.getMonth() + 1}/
                {this.state.endDate?.getFullYear()}
              </th>
            </tr>
            <tr style={{ whiteSpace: "nowrap" }}>
              {this.state.listGroup?.map((item) => {
                if (item.name == "adPriceName")
                  return (
                    <>
                      <th>{item.label}</th>
                      <th>Mã giờ</th>
                    </>
                  );
                else return <th>{item.label}</th>;
              })}
              {this.state.dataGroupReturn?.map((e) => {
                return <th>{e.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.listAd?.map((item) => {
              return (
                <tr>
                  {this.state.listGroup?.map((group) => {
                    if (group.name != "adPriceName")
                      return (
                        <td>
                          {this.changeEmpty(
                            group.name == "broadcastDate"
                              ? `xyp${this.getDate(item[group.name])}`
                              : group.name == "month"
                              ? this.getDate_M(item[group.name])
                              : item[group.name]
                          )}
                        </td>
                      );
                    else
                      return (
                        <>
                          <td>
                            {this.changeEmpty(
                              group.name == "broadcastDate"
                                ? `xpy${this.getDate(item[group.name])}`
                                : group.name == "month"
                                ? this.getDate_M(item[group.name])
                                : item[group.name]
                            )}
                          </td>
                          <td>{this.changeEmpty(item["adPriceTimeCode"])}</td>
                        </>
                      );
                  })}
                  {this.state.dataGroupReturn?.map((group) => {
                    return (
                      <td className="text-end">
                        {this.changeEmpty(parseInt(item[group.name]))}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {this.renderEmpty(
                this.state.listGroup?.length +
                  this.state.dataGroupReturn?.length
              )}
            </tr>
          </tbody>
        </Table>
        <div style={{}}>
          <Table
            striped
            bordered
            style={{ marginTop: "10px", border: "1px solid #ccc" }}
          >
            <thead>
              <tr style={{ whiteSpace: "nowrap" }}>
                {this.state.listGroup?.map((item) => {
                  if (item.name == "adPriceName")
                    return (
                      <>
                        <th>
                          {item.label}
                          <i
                            className="fa-solid fa-right-left ms-2 "
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter(
                                    (key) => key != item.name
                                  ),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [
                                    ...this.state.listKeySort,
                                    item.name,
                                  ],
                                });
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              transform: "rotate(90deg)",
                            }}
                          ></i>{" "}
                        </th>
                        <th>
                          Mã giờ
                          <i
                            className="fa-solid fa-right-left ms-2 "
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter(
                                    (key) => key != item.name
                                  ),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [
                                    ...this.state.listKeySort,
                                    item.name,
                                  ],
                                });
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              transform: "rotate(90deg)",
                            }}
                          ></i>
                        </th>
                      </>
                    );
                  else
                    return (
                      <th>
                        {item.label}{" "}
                        {item.label == "Khách hàng" ? (
                          <i
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter(
                                    (key) => key != item.name
                                  ),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [
                                    ...this.state.listKeySort,
                                    item.name,
                                  ],
                                });
                              }
                            }}
                            className="fa-solid fa-thumbtack ms-2 "
                            style={{ cursor: "pointer" }}
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              if (this.state.listKeySort.includes(item.name)) {
                                this.SortReverse(item.name);
                                this.setState({
                                  listKeySort: this.state.listKeySort.filter(
                                    (key) => key != item.name
                                  ),
                                });
                              } else {
                                this.Sort(item.name);
                                this.setState({
                                  listKeySort: [
                                    ...this.state.listKeySort,
                                    item.name,
                                  ],
                                });
                              }
                            }}
                            className="fa-solid fa-right-left ms-2 "
                            style={{
                              cursor: "pointer",
                              transform: "rotate(90deg)",
                            }}
                          ></i>
                        )}
                      </th>
                    );
                })}
                {this.state.dataGroupReturn?.map((e) => {
                  return <th>{e.label}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.listAd?.map((item) => {
                return (
                  <tr>
                    {this.state.listGroup?.map((group) => {
                      if (group.name != "adPriceName")
                        return (
                          <td>
                            {group.name == "broadcastDate"
                              ? this.getDate(item[group.name])
                              : group.name == "month"
                              ? this.getDate_M(item[group.name])
                              : item[group.name]}
                          </td>
                        );
                      else
                        return (
                          <>
                            <td>
                              {group.name == "broadcastDate"
                                ? this.getDate(item[group.name])
                                : group.name == "month"
                                ? this.getDate_M(item[group.name])
                                : item[group.name]}
                            </td>
                            <td>{item["adPriceTimeCode"]}</td>
                          </>
                        );
                    })}
                    {this.state.dataGroupReturn?.map((group) => {
                      return (
                        <td className="text-end">
                          {this.formatNumber(parseInt(item[group.name]))}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                {this.state.listGroup?.length > 0 ? (
                  <th
                    colSpan={
                      this.state.listGroup?.length +
                      this.state.listGroup?.filter(
                        (i) => i.name == "adPriceName"
                      )?.length
                    }
                  >
                    Tổng
                  </th>
                ) : null}
                {this.state.dataGroupReturn?.map((group, index) => {
                  return (
                    <th className="text-end">
                      {this.formatNumber(
                        parseInt(this.sumElement(group.name, index))
                      )}
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
  renderEmpty(sum) {
    let emptyCells = [];

    for (let i = 0; i < sum; i++) {
      emptyCells.push(<td key={i}>empty</td>);
    }

    return emptyCells;
  }
  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  Search() {
    let arrGroup = this.state.listGroup?.map((e) => e.value);
    this.setState({ checkload: true });

    axios
      .get(
        `/summary/monthly-summary-list?customerId=${
          this.state.CustomerItem?.value
        }&startDay=${parseInt(this.state.startDate / 1000)}&endDay=${parseInt(
          this.state.endDate / 1000
        )}&adPriceId=${this.state.AdPriceItem?.value}&contractId=${
          this.state.ContractItem?.value
        }&contractTypeId=${this.state.ContractTypeItem?.value}&tapeCodeId=${
          this.state.TapCodeItem?.value
        }&productId=${this.state.ProductItem?.value}&supplierId=${
          this.state.SupplierItem?.value
        }&viewType=${this.state.isCashSelected == true ? 1 : 2}&createdBy=${
          this.state.UserItem?.value
        }&groupIds=${JSON.stringify(arrGroup)}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ listAd: res.data.data });
        }
      });
  }
  getDate_ = (data) => {
    if (data) {
      let date = data;
      let result = `${date?.getDate()}/${
        date?.getMonth() + 1
      }/${date?.getFullYear()}`;
      return result;
    } else return "";
  };
  getDate_M = (data) => {
    if (data) {
      let date = new Date(data * 1000);
      let result = `${date?.getMonth() + 1}/${date?.getFullYear()}`;
      return result;
    } else return "";
  };
  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (
        data?.hasOwnProperty(cellRef) &&
        data[cellRef].t === "s" &&
        data[cellRef].v == targetValue
      ) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };
  extractLetters = (inputString) => {
    const lettersPart = inputString?.replace(/[^\D]/g, "");
    return lettersPart;
  };
  xport = () => {
    // swal({
    //   title: "Xác nhận xuất Danh sách tổng kết tháng",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(
      wb,
      this.listCustomerExport(),
      "Danh sách tổng kết tháng"
    );

    XLSX.writeFile(wb, "DS tong ket thang.xlsx");
    //   }
    // });
  };

  listCustomerExport = () => {
    const table = document.getElementById("exportListMonth");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    const colA = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
    ];
    const colAlpha = colA.slice(
      0,
      this.state.listGroup?.length +
        this.state.dataGroupReturn?.length +
        Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
    );
    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A2`].s = {
      font: {
        name: "Times New Roman",
        sz: 13,
        // bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });
    if (ws[`${this.extractLetters(this.findCellByValue(ws, "Ngày"))}${2}`])
      for (let i = 1; i < this.state.listAd?.length + 1; i++) {
        ws[
          `${this.extractLetters(this.findCellByValue(ws, "Ngày"))}${i + 2}`
        ].v =
          ws[
            `${this.extractLetters(this.findCellByValue(ws, "Ngày"))}${i + 2}`
          ].v?.substring(3);
      }
    for (let i = 1; i < this.state.listAd?.length + 2; i++) {
      colA
        .slice(
          0,
          this.state.listGroup?.length +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
        )
        ?.map((alpha) => {
          if (ws[`${alpha}${3 + i}`].v == "empty") {
            ws[`${alpha}${3 + i}`].v = " ";
          }

          ws[`${alpha}${3 + i}`].s = {
            font: {
              name: "Times New Roman",
              // sz: 11
            },
            alignment: {
              wrapText: false,
              vertical: "center",
              horizontal: "left",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        });

      if (this.findCellByValue(ws, "Ngày"))
        ws[
          `${this.extractLetters(this.findCellByValue(ws, "Ngày"))}${i + 3}`
        ].v =
          ws[
            `${this.extractLetters(this.findCellByValue(ws, "Ngày"))}${i + 3}`
          ]?.v?.substring(3);

      colA
        .slice(
          this.state.listGroup?.length +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
          this.state.dataGroupReturn?.length +
            this.state.listGroup?.length +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
        )
        ?.map((alpha) => {
          if (ws[`${alpha}${2 + i}`].v == "empty") {
            ws[`${alpha}${2 + i}`].v = " ";
          }
          ws[`${alpha}${i + 2}`].z = "#,###,###,###";
          delete ws[`${alpha}${i + 2}`].w;
          XLSX.utils.format_cell(ws[`${alpha}${i + 2}`]);
          ws[`${alpha}${2 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: i == 1,
            },
            alignment: {
              wrapText: false,
              vertical: "center",
              horizontal: "right",
              // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        });
    }

    ws["!cols"] = [
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    colA
      .slice(
        this.state.listGroup?.length +
          Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
        this.state.dataGroupReturn?.length +
          this.state.listGroup?.length +
          Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
      )
      ?.map((alpha) => {
        ws[`${alpha}${this.state.listAd?.length + 4}`] = {
          f: `=SUM(${alpha}4:${alpha}${this.state.listAd.length + 3})`,
        };
        ws[`${alpha}${this.state.listAd?.length + 3}`].z = "#,###,###,###";
        delete ws[`${alpha}${this.state.listAd?.length + 3}`].w;
        XLSX.utils.format_cell(ws[`${alpha}${this.state.listAd?.length + 3}`]);
      });
    ws["!rows"] = [];
    const mergePositions = [
      {
        s: { c: 0, r: 0 },
        e: {
          c:
            this.state.listGroup?.length +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1) +
            this.state.dataGroupReturn?.length -
            1,
          r: 0,
        },
      },

      {
        s: { c: 0, r: 1 },
        e: {
          c:
            this.state.listGroup?.length +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1) +
            this.state.dataGroupReturn?.length -
            1,
          r: 1,
        },
      },
      {
        s: { c: 0, r: this.state.listAd?.length + 3 },
        e: {
          c:
            this.state.listGroup?.length -
            1 +
            Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1),
          r: this.state.listAd?.length + 3,
        },
      },
    ];
    ws["!merges"] = mergePositions;
    ws[`A${this.state.listAd?.length + 4}`].v = "Tổng";
    colA
      .slice(
        0,
        this.state.dataGroupReturn?.length +
          this.state.listGroup?.length +
          Number(!this.findCellByValue(ws, "Mã giờ") ? 0 : 1)
      )
      ?.map((alpha) => {
        ws[`${alpha}${this.state.listAd?.length + 4}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        ws[`${alpha}${this.state.listAd?.length + 4}`].z = "#,###,###,###";
        delete ws[`${alpha}${this.state.listAd?.length + 4}`].w;
      });

    ws[`A2`].s = {
      font: { name: "Times New Roman", sz: 13 },
      alignment: { horizontal: "center", vertical: "top" },
    };

    ws["!rows"][1] = { hpt: 25 };

    return ws;
  };
  renderRoleList() {
    const renderSearchFilter = () => {
      return (
        <Row>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 9 }}>
            <Select
              defaultValue={this.state.listContractType[0]}
              options={this.state.listContractType}
              placeholder="Chọn loại hợp đồng"
              value={this.state.ContractTypeItem}
              onChange={(choice) => {
                this.setState({ ContractTypeItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 8 }}>
            <Select
              defaultValue={this.state.listCustomer[0]}
              options={this.state.listCustomer}
              placeholder="Chọn khách hàng"
              value={this.state.CustomerItem}
              onChange={(choice) => {
                this.setState({
                  CustomerItem: choice,
                  ContractItem: { value: 0, label: "Chọn hợp đồng" },
                });
                this.getAllContract(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 7 }}>
            <Select
              defaultValue={this.state.listContract[0]}
              options={this.state.listContract}
              placeholder="Chọn hợp đồng"
              value={this.state.ContractItem}
              onChange={(choice) => {
                this.setState({ ContractItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 6 }}>
            <Select
              // defaultValue={this.state.listSupplier[0]}
              options={this.state.listSupplier}
              placeholder="Chọn nhà cung cấp"
              value={this.state.SupplierItem}
              onChange={(choice) => {
                this.setState({
                  SupplierItem: choice,
                  ProductItem: { value: 0, label: "Chọn sản phẩm" },
                  TapCodeItem: { value: 0, label: "Chọn mã băng" },
                });
                this.getAllProduct(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 5 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listProduct}
              placeholder="Chọn sản phẩm"
              value={this.state.ProductItem}
              onChange={(choice) => {
                this.setState({
                  ProductItem: choice,
                  TapCodeItem: { value: 0, label: "Chọn mã băng" },
                });

                this.getAllTapeCode(choice.value);
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 4 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listTapCode}
              placeholder="Chọn mã băng"
              value={this.state.TapCodeItem}
              onChange={(choice) => {
                this.setState({ TapCodeItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 3 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listAdPrice}
              placeholder="Chọn block"
              value={this.state.AdPriceItem}
              onChange={(choice) => {
                this.setState({ AdPriceItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 2 }}>
            <Select
              // defaultValue={this.state.listProduct[0]}
              options={this.state.listUser}
              placeholder="Chọn nhân viên"
              value={this.state.UserItem}
              onChange={(choice) => {
                this.setState({ UserItem: choice });
              }}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-12 col-sm-12 d-flex align-items-center  ">
            <label>Thông báo</label>
            <Form.Check
              type="switch"
              id="custom-switch-cash"
              className="ms-2"
              checked={this.state.isCashSelected}
              onChange={() =>
                this.setState(
                  {
                    isCashSelected: true,
                  },
                  () => {
                    this.getAllCustomer();
                    this.getAllAdPrice(
                      this.state.startDate / 1000,
                      this.state.endDate / 1000
                    );
                    this.getAllContract(this.state.CustomerItem?.value);
                  }
                )
              }
            />

            <label className="ms-3">Quảng cáo</label>
            <Form.Check
              type="switch"
              id="custom-switch-transfer"
              className="ms-2"
              checked={!this.state.isCashSelected}
              onChange={() =>
                this.setState(
                  {
                    isCashSelected: false,
                  },
                  () => {
                    this.getAllCustomer();
                    this.getAllContract(this.state.CustomerItem?.value);
                  }
                )
              }
            />
          </Col>
          <Col
            className="mb-2 col-md-3 col-12 col-sm-12 "
            style={{ zIndex: 2, display: "contents" }}
          >
            <span className="mt-2 me-2 ms-2">Dữ liệu phân tích</span>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(select) =>
                this.setState({
                  listGroup: select,
                  // ?.slice().sort((a, b) => a.value - b.value)
                })
              }
              defaultValue={[this.state.dataGroup[1]]}
              isMulti
              placeholder="Dữ liệu phân tích"
              options={this.state.dataGroup}
              className="mb-2"
            />
          </Col>
          <Col
            className="mb-2 col-md-3 col-12 col-sm-12 "
            style={{ zIndex: 2, display: "contents" }}
          >
            <span className="mt-2 me-2 ms-2">Dữ liệu tổng hợp</span>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(select) =>
                this.setState({
                  dataGroupReturn: select,
                  // ?.slice().sort((a, b) => a.value - b.value)
                })
              }
              defaultValue={[this.state.dataG[1]]}
              isMulti
              placeholder="Dữ liệu tổng hợp"
              options={this.state.dataG}
            />
          </Col>
          <Col className="mb-2 col-md-3 col-1 col-sm-1">
            <Button
              variant="outline-secondary"
              className="mt-1"
              size="md"
              onClick={() => this.Search()}
            >
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {/* {new Check().permission(['63']) ? */}
          {/* <Button type="button" variant="success" size="sm" style={{ float: "right" }} onClick={() => this.setState({ modalRoleShow: true })} >
            <i className="fa-solid fa-plus"></i> Thêm
          </Button> */}
          {/* : null} */}
          <Card.Title className="d-md-flex align-items-center justify-content-between ">
            <div className="d-md-flex">
              <i className="fas fa-list me-1"></i> Danh sách tổng kết tháng{" "}
              <div className="d-flex mt-2 mt-md-0">
                <div className="ms-1" style={{ zIndex: 10 }}>
                  <DatePicker
                    showWeekNumbers
                    calendarStartDay={1}
                    selected={this.state.startDate}
                    onChange={(date) =>
                      this.setState(
                        { startDate: new Date(date.setHours(0, 0, 0)) },
                        () =>
                          this.getAllAdPrice(
                            new Date(date.setHours(0, 0, 0)) / 1000,
                            new Date(this.state.endDate?.setHours(23, 59, 59)) /
                              1000
                          )
                      )
                    }
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>{" "}
                <div className="ms-1" style={{ zIndex: 10 }}>
                  <DatePicker
                    showWeekNumbers
                    calendarStartDay={1}
                    selected={this.state.endDate}
                    onChange={(date) =>
                      this.setState(
                        { endDate: new Date(date.setHours(23, 59, 59)) },
                        () => {
                          this.getAllAdPrice(
                            new Date(this.state.startDate?.setHours(0, 0, 0)) /
                              1000,

                            new Date(date.setHours(23, 59, 59)) / 1000
                          );
                        }
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                  />
                </div>
              </div>
            </div>
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{}}
              onClick={() => this.xport()}
              className="me-2 mt-2 mt-md-0"
              disabled={this.state.listAd?.length < 1}
              // hidden
            >
              <i className="fa-solid fa-file-export"></i> Xuất Danh sách tổng
              kết tháng
            </Button>
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Quản trị</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh sách tổng kết tháng</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                selected={this.state.start_}
                onChange={(update) => {
                  this.setState({ start_: update[0] });
                  this.setState({ end_: update[1] });
                }}
                startDate={this.state.start_}
                endDate={this.state.end_}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
                // this.getListMealstudent(this.state.startDate, this.state.endDate)
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={this.state.start_ === null || this.state.end_ === null}
              onClick={() => {
                this.setState({ show: false });
                this.setState({
                  startDate: new Date(this.state.start_.setHours(0, 0, 0)),
                });
                this.setState({
                  endDate: new Date(this.state.end_?.setHours(23, 59, 59)),
                });
                this.getAllAdPrice(
                  new Date(this.state.start_.setHours(0, 0, 0)) / 1000,
                  new Date(this.state.end_?.setHours(23, 59, 59)) / 1000
                );
                // this.getListMealstudent(this.state.start_, this.state.end_)
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MonthlySummaryList;
